import base64 from 'base-64';
import _ from 'lodash';
import { ConnectionHandler } from 'relay-runtime';

// eslint-disable-next-line import/prefer-default-export
export function trimEdgesAndNodes(jsonDic) {
  return jsonDic && jsonDic.edges ? jsonDic.edges.map((edge) => edge.node) : [];
}

export const encodeBusinessId = (businessId, addPrefix = true) =>
  base64.encode(addPrefix ? `BusinessNode:${businessId}` : businessId);

export const decodeGlobalId = (id) => {
  const decodedValues = base64.decode(id).split(':');
  return {
    nodeType: decodedValues[0],
    id: decodedValues[1],
  };
};

export const decodeBase64Id = (encodedId) => {
  const uidWithPrefix = base64.decode(encodedId);
  return uidWithPrefix.split(':')[1];
};

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const createRelayResponse = (errors, data) => {
  return {
    ok: true,
    status: 200,
    data,
    errors,
  };
};

export const isNodeExistInConnection = (destinationConnection, payloadNodeId) => {
  const edges = destinationConnection.getLinkedRecords('edges');
  return _.some(edges, (edge) => edge.getLinkedRecord('node')?.getValue('id') === payloadNodeId);
};

export const ConnectionOperation = {
  inserted: 'inserted',
  deleted: 'deleted',
  none: 'none',
};

export const addOrDeleteNodeInConnection = (store, destinationConnection, payloadNode, edgeType, shouldAdd = true) => {
  const payloadNodeId = payloadNode.getValue('id');

  const isNodeExist = isNodeExistInConnection(destinationConnection, payloadNodeId);
  if (!isNodeExist && shouldAdd) {
    const newEdge = ConnectionHandler.createEdge(store, destinationConnection, payloadNode, edgeType);
    ConnectionHandler.insertEdgeBefore(destinationConnection, newEdge);
    return ConnectionOperation.inserted;
  }
  if (isNodeExist && !shouldAdd) {
    ConnectionHandler.deleteNode(destinationConnection, payloadNodeId);
    return ConnectionOperation.deleted;
  }
  return ConnectionOperation.none;
};
