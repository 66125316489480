import React from 'react';
import PropTypes from 'prop-types';
import ExperimentsRootQuery from '../root_query/ExperimentsRootQuery';
import { useGlobalState } from '../state';
import { useAuth0 } from '../auth0/react-auth0-wrapper';

export const Experiments = React.createContext();

const ExperimentRoot = ({ business, children }) => {
  const activeExperiments = business && business.activeExperiments ? business.activeExperiments : [];
  return <Experiments.Provider value={activeExperiments}>{children}</Experiments.Provider>;
};

ExperimentRoot.defaultProps = {
  business: {},
};

ExperimentRoot.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string,
    activeExperiments: PropTypes.arrayOf(PropTypes.string),
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.array]).isRequired,
};

const ExperimentRootComponent = (props) => {
  const [business] = useGlobalState('business');
  const { loading, isAuthenticated } = useAuth0();

  if (!loading && isAuthenticated && business && business.id) {
    return (
      <ExperimentsRootQuery businessId={business.id}>
        <ExperimentRoot {...props} />
      </ExperimentsRootQuery>
    );
  }

  return <ExperimentRoot {...props} />;
};

export default ExperimentRootComponent;
