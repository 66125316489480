// @flow
// TODO: Upgrade to use mutation mock + demo mode from mobile app

import _ from 'lodash';
import { getAuth0CustomerSuccess } from '../auth0/react-auth0-wrapper';
import { CustomerSuccessEnum } from '../utils/enums';
import { isDev } from '../utils/utils';
import { createRelayResponse } from '../utils/relay';

const endpointUrl = process.env.REACT_APP_GRAPHQL_GATEWAY;
const isProductionEndpoint = endpointUrl.includes('https://');

function blockOpsMutationMiddleware() {
  return (next) => async (req) => {
    if (!req.isMutation()) {
      return next(req);
    }

    const customerSuccess = await getAuth0CustomerSuccess();
    const opsTypes = [CustomerSuccessEnum.read_only_agent, CustomerSuccessEnum.full_access_agent];
    const isInternalUser = isDev() || _.includes(opsTypes, customerSuccess);
    const shouldBlockMutations = isInternalUser && isProductionEndpoint;
    // const shouldBlockMutations = false;
    if (!shouldBlockMutations) {
      return next(req);
    }

    console.info('** Blocked mutation **');
    return createRelayResponse(['error'], null);
  };
}

export default blockOpsMutationMiddleware;
