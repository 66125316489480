// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {|
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  DateTime: any,
  UUID: any,
  JSONString: any,
  Time: any,
|};

export type AccountBalanceNode = {|
  ...Node,
  ...{|
    __typename?: 'AccountBalanceNode',
    id: $ElementType<Scalars, 'ID'>,
    balance?: ?$ElementType<Scalars, 'String'>,
    date?: ?$ElementType<Scalars, 'String'>,
    isEstimated?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type AccountingControlCenterNode = {|
  __typename?: 'AccountingControlCenterNode',
  accountingMonitor?: ?BusinessAccountingMonitor,
  accountingMonitorsSummary?: ?Array<?BusinessAccountingMonitorsSummary>,
|};

export type AccountingControlCenterNodeAccountingMonitorArgs = {|
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type ActionOptionConnection = {|
  __typename?: 'ActionOptionConnection',
  pageInfo: PageInfo,
  edges: Array<?ActionOptionEdge>,
|};

export type ActionOptionEdge = {|
  __typename?: 'ActionOptionEdge',
  node?: ?ActionOptionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ActionOptionNode = {|
  ...Node,
  ...{|
    __typename?: 'ActionOptionNode',
    id: $ElementType<Scalars, 'ID'>,
    key?: ?$ElementType<Scalars, 'String'>,
    type?: ?$ElementType<Scalars, 'String'>,
    actionName?: ?$ElementType<Scalars, 'String'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    passThroughData?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type ActionsGraphNode = {|
  ...Node,
  ...{|
    __typename?: 'ActionsGraphNode',
    id: $ElementType<Scalars, 'ID'>,
    actionOptions?: ?ActionOptionConnection,
    questions?: ?QuestionConnection,
  |},
|};

export type ActionsGraphNodeActionOptionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type ActionsGraphNodeQuestionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddFigoAuthorizationInput = {|
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  bankCredentials?: ?$ElementType<Scalars, 'JSONString'>,
  taskId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type AddFigoAuthorizationPayload = {|
  __typename?: 'AddFigoAuthorizationPayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  business?: ?BusinessNode,
  errorCode?: ?$ElementType<Scalars, 'String'>,
  updatedTaskId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type AddressNode = {|
  ...Node,
  ...{|
    __typename?: 'AddressNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    streetName: $ElementType<Scalars, 'String'>,
    houseNumber: $ElementType<Scalars, 'String'>,
    postalCode: $ElementType<Scalars, 'String'>,
    city: $ElementType<Scalars, 'String'>,
    country?: ?$ElementType<Scalars, 'String'>,
    comment?: ?$ElementType<Scalars, 'String'>,
    taxadviserSet: TaxAdviserNodeConnection,
    businessSet: BusinessNodeConnection,
    pickupAddress: BusinessDigitalizationNodeConnection,
    returnAddress: BusinessDigitalizationNodeConnection,
    businessemployeeSet: BusinessEmployeeNodeConnection,
    stateCode?: ?$ElementType<Scalars, 'String'>,
    countryCode?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type AddressNodeTaxadviserSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddressNodeBusinessSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddressNodePickupAddressArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddressNodeReturnAddressArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddressNodeBusinessemployeeSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type AddressNodeConnection = {|
  __typename?: 'AddressNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?AddressNodeEdge>,
|};

export type AddressNodeEdge = {|
  __typename?: 'AddressNodeEdge',
  node?: ?AddressNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type AppUserNode = {|
  ...Node,
  ...{|
    __typename?: 'AppUserNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    businesses: BusinessNodeConnection,
    displayName?: ?$ElementType<Scalars, 'String'>,
    email: $ElementType<Scalars, 'String'>,
    appUserToBusiness?: ?AppUserToBusinessNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    businessIds?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type AppUserNodeBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AppUserNodeAppUserToBusinessArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AppUserNodeConnection = {|
  __typename?: 'AppUserNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?AppUserNodeEdge>,
|};

export type AppUserNodeEdge = {|
  __typename?: 'AppUserNodeEdge',
  node?: ?AppUserNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const AppUserToBusinessAuthorizationScopeInAppValues = Object.freeze({
  All: 'ALL',
  Cep: 'CEP',
  Scan: 'SCAN',
});

export type AppUserToBusinessAuthorizationScopeInApp = $Values<typeof AppUserToBusinessAuthorizationScopeInAppValues>;

export type AppUserToBusinessNode = {|
  ...Node,
  ...{|
    __typename?: 'AppUserToBusinessNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    appUser: AppUserNode,
    business: BusinessNode,
    authorizationScopeInApp: AppUserToBusinessAuthorizationScopeInApp,
  |},
|};

export type AppUserToBusinessNodeConnection = {|
  __typename?: 'AppUserToBusinessNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?AppUserToBusinessNodeEdge>,
|};

export type AppUserToBusinessNodeEdge = {|
  __typename?: 'AppUserToBusinessNodeEdge',
  node?: ?AppUserToBusinessNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BalanceHistoryBalanceCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type BalanceHistoryBalanceCurrency = $Values<typeof BalanceHistoryBalanceCurrencyValues>;

export const BalanceHistoryBalanceSourceValues = Object.freeze({
  BankAccountStatementEndBalance: 'BANK_ACCOUNT_STATEMENT_END_BALANCE',
  BankAccountStatementStartBalance: 'BANK_ACCOUNT_STATEMENT_START_BALANCE',
  CashJournal: 'CASH_JOURNAL',
  Finapi: 'FINAPI',
  Manual: 'MANUAL',
  Unknown: 'UNKNOWN',
});

export type BalanceHistoryBalanceSource = $Values<typeof BalanceHistoryBalanceSourceValues>;

export type BalanceHistoryNode = {|
  ...Node,
  ...{|
    __typename?: 'BalanceHistoryNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    merchantPaymentAccount: MerchantPaymentAccountNode,
    balanceDate: $ElementType<Scalars, 'DateTime'>,
    balanceCurrency: BalanceHistoryBalanceCurrency,
    balance: $ElementType<Scalars, 'Float'>,
    record?: ?RecordNode,
    balanceSource: BalanceHistoryBalanceSource,
  |},
|};

export type BalanceHistoryNodeConnection = {|
  __typename?: 'BalanceHistoryNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BalanceHistoryNodeEdge>,
|};

export type BalanceHistoryNodeEdge = {|
  __typename?: 'BalanceHistoryNodeEdge',
  node?: ?BalanceHistoryNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BankStatementNode = {|
  ...Node,
  ...{|
    __typename?: 'BankStatementNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    document: DocumentNode,
    counterParty?: ?CounterPartyNode,
    recordType: $ElementType<Scalars, 'String'>,
    business: BusinessNode,
    data: $ElementType<Scalars, 'JSONString'>,
    validatedFields: $ElementType<Scalars, 'JSONString'>,
    occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
    startDate?: ?$ElementType<Scalars, 'String'>,
    endDate?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BookingAccountCaptionEdge = {|
  __typename?: 'BookingAccountCaptionEdge',
  node?: ?BookingAccountCaptionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountCaptionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountCaptionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    specification: BookingAccountCaptionSpecification,
    account: $ElementType<Scalars, 'Int'>,
    datevAccountName: $ElementType<Scalars, 'String'>,
    taxAdvisor?: ?TaxAdviserNode,
    business?: ?BusinessNode,
    bookingRuleActions: BookingRuleActionNodeConnection,
  |},
|};

export type BookingAccountCaptionNodeBookingRuleActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingAccountCaptionNodeConnection = {|
  __typename?: 'BookingAccountCaptionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingAccountCaptionNodeEdge>,
|};

export type BookingAccountCaptionNodeEdge = {|
  __typename?: 'BookingAccountCaptionNodeEdge',
  node?: ?BookingAccountCaptionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingAccountCaptionSpecificationValues = Object.freeze({
  Skr03: 'SKR03',
  Skr04: 'SKR04',
});

export type BookingAccountCaptionSpecification = $Values<typeof BookingAccountCaptionSpecificationValues>;

export type BookingAccountNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountNode',
    id: $ElementType<Scalars, 'ID'>,
    accountNumber?: ?$ElementType<Scalars, 'Int'>,
    caption?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BookingAccountNodeConnection = {|
  __typename?: 'BookingAccountNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingAccountNodeEdge>,
|};

export type BookingAccountNodeEdge = {|
  __typename?: 'BookingAccountNodeEdge',
  node?: ?BookingAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountsForDebtorsCreditorsNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountsForDebtorsCreditorsNode',
    id: $ElementType<Scalars, 'ID'>,
    bookingAccountRanges?: ?Array<?Array<?$ElementType<Scalars, 'Int'>>>,
    usedBookingAccounts?: ?BookingAccountNodeConnection,
  |},
|};

export type BookingAccountsForDebtorsCreditorsNodeUsedBookingAccountsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingAccountsForPaymentAccountsNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountsForPaymentAccountsNode',
    id: $ElementType<Scalars, 'ID'>,
    bookingAccountRanges?: ?Array<?Array<?$ElementType<Scalars, 'Int'>>>,
    usedBookingAccounts?: ?Array<?$ElementType<Scalars, 'Int'>>,
  |},
|};

export type BookingAccountsSchemeNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountsSchemeNode',
    id: $ElementType<Scalars, 'ID'>,
    paymentAccounts?: ?BookingAccountsForPaymentAccountsNode,
    debtorsCreditors?: ?BookingAccountsForDebtorsCreditorsNode,
  |},
|};

export type BookingAccountsSchemeNodePaymentAccountsArgs = {|
  accountType: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountsSchemeNodeDebtorsCreditorsArgs = {|
  category: $ElementType<Scalars, 'String'>,
|};

export type BookingInstructionConnection = {|
  __typename?: 'BookingInstructionConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingInstructionEdge>,
|};

export type BookingInstructionEdge = {|
  __typename?: 'BookingInstructionEdge',
  node?: ?BookingInstructionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingInstructionNode = {|
  __typename?: 'BookingInstructionNode',
  id: $ElementType<Scalars, 'ID'>,
  vatRate?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  scenario?: ?BookingScenarioNode,
  bookingsInfo?: ?BookingsInfoNode,
  accountCaption?: ?BookingAccountCaptionNode,
  offsetingAccountCaption?: ?BookingAccountCaptionNode,
|};

export type BookingRecommendationNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRecommendationNode',
    id: $ElementType<Scalars, 'ID'>,
    confidence?: ?$ElementType<Scalars, 'Float'>,
    scenarioBookings?: ?Array<?BookingScenarioConcreteBookingNode>,
  |},
|};

export type BookingRuleActionInput = {|
  offsettingAccountCaptionId?: ?$ElementType<Scalars, 'String'>,
  highlightForReview?: ?$ElementType<Scalars, 'Boolean'>,
  bookingText?: ?$ElementType<Scalars, 'String'>,
  belegfeld?: ?$ElementType<Scalars, 'String'>,
|};

export type BookingRuleActionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRuleActionNode',
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    bookingRule: BookingRuleNode,
    offsettingAccountCaption?: ?BookingAccountCaptionNode,
    highlightForReview: $ElementType<Scalars, 'Boolean'>,
    bookingText?: ?$ElementType<Scalars, 'String'>,
    belegfeld?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
  |},
|};

export type BookingRuleActionNodeConnection = {|
  __typename?: 'BookingRuleActionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingRuleActionNodeEdge>,
|};

export type BookingRuleActionNodeEdge = {|
  __typename?: 'BookingRuleActionNodeEdge',
  node?: ?BookingRuleActionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingRuleBookkeepingSpecificationValues = Object.freeze({
  Skr03: 'SKR03',
  Skr04: 'SKR04',
});

export type BookingRuleBookkeepingSpecification = $Values<typeof BookingRuleBookkeepingSpecificationValues>;

export type BookingRuleConditionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRuleConditionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    bookingRule: BookingRuleNode,
    parameter: $ElementType<Scalars, 'String'>,
    operator: $ElementType<Scalars, 'String'>,
    value: $ElementType<Scalars, 'String'>,
  |},
|};

export type BookingRuleConditionNodeConnection = {|
  __typename?: 'BookingRuleConditionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingRuleConditionNodeEdge>,
|};

export type BookingRuleConditionNodeEdge = {|
  __typename?: 'BookingRuleConditionNodeEdge',
  node?: ?BookingRuleConditionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingRuleConditionsInput = {|
  parameter?: ?$ElementType<Scalars, 'String'>,
  operator?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
|};

export type BookingRuleEdge = {|
  __typename?: 'BookingRuleEdge',
  node?: ?BookingRuleNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingRuleEntityDirectionValues = Object.freeze({
  Incoming: 'INCOMING',
  Outgoing: 'OUTGOING',
});

export type BookingRuleEntityDirection = $Values<typeof BookingRuleEntityDirectionValues>;

export const BookingRuleEntityTypeValues = Object.freeze({
  Payable: 'PAYABLE',
  Payment: 'PAYMENT',
});

export type BookingRuleEntityType = $Values<typeof BookingRuleEntityTypeValues>;

export type BookingRuleNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRuleNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    taxAdvisorBusinessGroup: TaxAdvisorBusinessGroupNode,
    name?: ?$ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    entityType: BookingRuleEntityType,
    entityDirection: BookingRuleEntityDirection,
    ruleType: BookingRuleRuleType,
    bookkeepingSpecification: BookingRuleBookkeepingSpecification,
    priority: $ElementType<Scalars, 'Int'>,
    bookings: BookkeepingNodeConnection,
    conditions: BookingRuleConditionNodeConnection,
    action?: ?BookingRuleActionNode,
    ruleDescription?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type BookingRuleNodeBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BookingRuleNodeConditionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingRuleNodeConnection = {|
  __typename?: 'BookingRuleNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingRuleNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingRuleNodeEdge = {|
  __typename?: 'BookingRuleNodeEdge',
  node?: ?BookingRuleNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingRuleRuleTypeValues = Object.freeze({
  Override: 'OVERRIDE',
  Refinement: 'REFINEMENT',
});

export type BookingRuleRuleType = $Values<typeof BookingRuleRuleTypeValues>;

export type BookingRuleSchemaNode = {|
  __typename?: 'BookingRuleSchemaNode',
  conditionsSchema?: ?$ElementType<Scalars, 'JSONString'>,
  actionsSchema?: ?$ElementType<Scalars, 'JSONString'>,
|};

export const BookingScenarioCategoryValues = Object.freeze({
  AutomobileCosts: 'AUTOMOBILE_COSTS',
  ConsultationCosts: 'CONSULTATION_COSTS',
  OfficeCosts: 'OFFICE_COSTS',
  OperatingCosts: 'OPERATING_COSTS',
  OtherCosts: 'OTHER_COSTS',
  PremisesCost: 'PREMISES_COST',
  SuppliesAndServicesCosts: 'SUPPLIES_AND_SERVICES_COSTS',
});

export type BookingScenarioCategory = $Values<typeof BookingScenarioCategoryValues>;

export type BookingScenarioConcreteBookingNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingScenarioConcreteBookingNode',
    id: $ElementType<Scalars, 'ID'>,
    amount?: ?$ElementType<Scalars, 'Float'>,
    vatRate?: ?$ElementType<Scalars, 'String'>,
    scenario?: ?BookingScenarioNode,
  |},
|};

export const BookingScenarioDomainValues = Object.freeze({
  Cash: 'CASH',
  Contracts: 'CONTRACTS',
  Employees: 'EMPLOYEES',
  Expenses: 'EXPENSES',
  InvoicesAndReceipts: 'INVOICES_AND_RECEIPTS',
  Other: 'OTHER',
  Payments: 'PAYMENTS',
  SocialInsurance: 'SOCIAL_INSURANCE',
  Statements: 'STATEMENTS',
});

export type BookingScenarioDomain = $Values<typeof BookingScenarioDomainValues>;

export const BookingScenarioExpenseTypeValues = Object.freeze({
  Goods: 'GOODS',
  Service: 'SERVICE',
  Unknown: 'UNKNOWN',
});

export type BookingScenarioExpenseType = $Values<typeof BookingScenarioExpenseTypeValues>;

export type BookingScenarioNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingScenarioNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    name: $ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    domain?: ?BookingScenarioDomain,
    category?: ?BookingScenarioCategory,
    subCategory?: ?BookingScenarioSubCategory,
    recordTypes: Array<$ElementType<Scalars, 'String'>>,
    logicalGroup?: ?$ElementType<Scalars, 'String'>,
    isVatKeyRelevant: $ElementType<Scalars, 'Boolean'>,
    isApplicableForBookingTaskSuggestions: $ElementType<Scalars, 'Boolean'>,
    isLowConfidence: $ElementType<Scalars, 'Boolean'>,
    datevAccountName?: ?$ElementType<Scalars, 'String'>,
    expenseType: BookingScenarioExpenseType,
    legalEntityTypes: Array<$ElementType<Scalars, 'String'>>,
    transactionPayableOrigins: Array<$ElementType<Scalars, 'String'>>,
    invoiceNotTaxCompliant?: ?$ElementType<Scalars, 'Boolean'>,
    isPayableAsPayment?: ?$ElementType<Scalars, 'Boolean'>,
    entityDirections: Array<$ElementType<Scalars, 'String'>>,
    vatRates: Array<$ElementType<Scalars, 'String'>>,
    businesspaymentproviderSet: BusinessPaymentProviderNodeConnection,
    bookkeepingSet: BookkeepingNodeConnection,
    contractBookingTemplates: ContractBookingTemplateNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BookingScenarioNodeBusinesspaymentproviderSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  isForCashRegister?: ?$ElementType<Scalars, 'Boolean'>,
  isUsedForRevenue?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BookingScenarioNodeBookkeepingSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BookingScenarioNodeContractBookingTemplatesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingScenarioRecommendationsNode = {|
  __typename?: 'BookingScenarioRecommendationsNode',
  scenario?: ?BookingScenarioNode,
  confidence?: ?$ElementType<Scalars, 'Float'>,
|};

export const BookingScenarioSubCategoryValues = Object.freeze({
  Accounting: 'ACCOUNTING',
  AccountingConsultation: 'ACCOUNTING_CONSULTATION',
  Cleaning: 'CLEANING',
  GeneralTravelExpenses: 'GENERAL_TRAVEL_EXPENSES',
  OperatingCosts: 'OPERATING_COSTS',
  OtherItems: 'OTHER_ITEMS',
  RawMaterials: 'RAW_MATERIALS',
});

export type BookingScenarioSubCategory = $Values<typeof BookingScenarioSubCategoryValues>;

export type BookingsInfoNode = {|
  __typename?: 'BookingsInfoNode',
  id: $ElementType<Scalars, 'ID'>,
  source?: ?$ElementType<Scalars, 'String'>,
  document?: ?DocumentNode,
  instructions?: ?BookingInstructionConnection,
|};

export type BookingsInfoNodeInstructionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingConnection = {|
  __typename?: 'BookkeepingConnection',
  pageInfo: PageInfo,
  edges: Array<?BookkeepingEdge>,
|};

export type BookkeepingDistributionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingDistributionNode',
    id: $ElementType<Scalars, 'ID'>,
    lowConfidenceBookings?: ?$ElementType<Scalars, 'Int'>,
    highConfidenceBookings?: ?$ElementType<Scalars, 'Int'>,
    unbookedDocuments?: ?$ElementType<Scalars, 'Int'>,
    toBeExaminedDocuments?: ?$ElementType<Scalars, 'Int'>,
    bookingUnrelatedDocuments?: ?$ElementType<Scalars, 'Int'>,
    incomingPayables?: ?$ElementType<Scalars, 'Int'>,
    outgoingPayables?: ?$ElementType<Scalars, 'Int'>,
    unbookedRecords?: ?Array<?RecordNode>,
  |},
|};

export type BookkeepingEdge = {|
  __typename?: 'BookkeepingEdge',
  node?: ?BookkeepingNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookkeepingExportBusinessFieldsMutationInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  vatTaxationStatus?: ?$ElementType<Scalars, 'String'>,
  profitAssessmentMethod?: ?$ElementType<Scalars, 'String'>,
  hasMonthlyVatDeclarationExtension?: ?$ElementType<Scalars, 'Boolean'>,
  vatDeclarationPeriod?: ?$ElementType<Scalars, 'String'>,
  bookkeepingSoftwareId?: ?$ElementType<Scalars, 'String'>,
  datevSubAdvisorNumber?: ?$ElementType<Scalars, 'String'>,
  bookkeepingDeadlineDayOfMonth?: ?$ElementType<Scalars, 'Int'>,
  bookkeepingDeadlineMonth?: ?$ElementType<Scalars, 'String'>,
  exportVatKeysPreference?: ?$ElementType<Scalars, 'String'>,
  bookingLogicType?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type BookkeepingExportBusinessFieldsMutationPayload = {|
  __typename?: 'BookkeepingExportBusinessFieldsMutationPayload',
  businessAccounting?: ?BusinessAccountingNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export const BookkeepingExportExportSourceValues = Object.freeze({
  BookkeepingTaskuiPage: 'BOOKKEEPING_TASKUI_PAGE',
  MonthEndClosing: 'MONTH_END_CLOSING',
  NedMonthEndClosing: 'NED_MONTH_END_CLOSING',
  AccountingOffboardingExport: 'ACCOUNTING_OFFBOARDING_EXPORT',
  YearEndStatement: 'YEAR_END_STATEMENT',
});

export type BookkeepingExportExportSource = $Values<typeof BookkeepingExportExportSourceValues>;

export type BookkeepingExportNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingExportNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    month?: ?$ElementType<Scalars, 'String'>,
    createdInMonth?: ?$ElementType<Scalars, 'String'>,
    isVatDeclarationRequired: $ElementType<Scalars, 'Boolean'>,
    business?: ?BusinessNode,
    exportSource: BookkeepingExportExportSource,
    bookkeeping: BookkeepingNodeConnection,
  |},
|};

export type BookkeepingExportNodeBookkeepingArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BookkeepingExportNodeConnection = {|
  __typename?: 'BookkeepingExportNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookkeepingExportNodeEdge>,
|};

export type BookkeepingExportNodeEdge = {|
  __typename?: 'BookkeepingExportNodeEdge',
  node?: ?BookkeepingExportNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookkeepingExportPreviewNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingExportPreviewNode',
    id: $ElementType<Scalars, 'ID'>,
    month?: ?$ElementType<Scalars, 'String'>,
    bookingsCount?: ?$ElementType<Scalars, 'Int'>,
    isExported?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type BookkeepingNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    amount: $ElementType<Scalars, 'Float'>,
    bookkeepingType?: ?$ElementType<Scalars, 'String'>,
    isNegativeAmountInConcreteScenario: $ElementType<Scalars, 'Boolean'>,
    account: $ElementType<Scalars, 'Int'>,
    offsetingAccount: $ElementType<Scalars, 'Int'>,
    text: $ElementType<Scalars, 'String'>,
    vatKey?: ?$ElementType<Scalars, 'Int'>,
    business: BusinessNode,
    export?: ?BookkeepingExportNode,
    sourceId?: ?$ElementType<Scalars, 'UUID'>,
    sourceDocumentId: $ElementType<Scalars, 'UUID'>,
    sourceRecordId: $ElementType<Scalars, 'UUID'>,
    scenario?: ?BookingScenarioNode,
    vatRate?: ?$ElementType<Scalars, 'String'>,
    isReversed: $ElementType<Scalars, 'Boolean'>,
    reconciliationId?: ?$ElementType<Scalars, 'String'>,
    bookingDate: $ElementType<Scalars, 'Date'>,
    solver?: ?BookkeepingSolver,
    isStringent?: ?$ElementType<Scalars, 'Boolean'>,
    isLowConfidence: $ElementType<Scalars, 'Boolean'>,
    reverseChargeCategory?: ?BookkeepingReverseChargeCategory,
    rule?: ?BookingRuleNode,
    record?: ?RecordNode,
    transactionIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
    payments?: ?PaymentNodeConnection,
    payables?: ?PayableNodeConnection,
    creditAmount?: ?$ElementType<Scalars, 'Float'>,
    debitAmount?: ?$ElementType<Scalars, 'Float'>,
    directionalAmount?: ?$ElementType<Scalars, 'Float'>,
    isPotentialDoubleBooking?: ?$ElementType<Scalars, 'Boolean'>,
    relevantBusinessTransaction?: ?TransactionNode,
    verificationSource?: ?$ElementType<Scalars, 'String'>,
    reconciliationIdentifier?: ?$ElementType<Scalars, 'String'>,
    uid?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BookkeepingNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingNodeConnection = {|
  __typename?: 'BookkeepingNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookkeepingNodeEdge>,
|};

export type BookkeepingNodeEdge = {|
  __typename?: 'BookkeepingNodeEdge',
  node?: ?BookkeepingNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookkeepingProgressNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingProgressNode',
    id: $ElementType<Scalars, 'ID'>,
    reminderSent?: ?$ElementType<Scalars, 'Boolean'>,
    exportDownloaded?: ?$ElementType<Scalars, 'Boolean'>,
    revenues?: ?LinearProgressItemNode,
    expenses?: ?LinearProgressItemNode,
    cashJournals?: ?Array<?LinearProgressItemNode>,
    paymentAccounts?: ?Array<?StatusProgressItemNode>,
    documentPackages?: ?Array<?DocumentPackageItemNode>,
    documentPackagesByInputChannelName?: ?Array<?DocumentPackageItemNode>,
    documentProcessErrorCount?: ?$ElementType<Scalars, 'Int'>,
  |},
|};

export type BookkeepingRecommendationNode = {|
  __typename?: 'BookkeepingRecommendationNode',
  amount?: ?$ElementType<Scalars, 'String'>,
  vatRate?: ?$ElementType<Scalars, 'String'>,
  scenarioRecommendations?: ?Array<?BookingScenarioRecommendationsNode>,
|};

export const BookkeepingReverseChargeCategoryValues = Object.freeze({
  ReceiveServiceFromForeignInvoiceFromEuSupplier: 'RECEIVE_SERVICE_FROM_FOREIGN_INVOICE_FROM_EU_SUPPLIER',
  ReceiveServiceFromForeignInvoiceFromNonEuSupplieR: 'RECEIVE_SERVICE_FROM_FOREIGN_INVOICE_FROM_NON_EU_SUPPLIE_R',
});

export type BookkeepingReverseChargeCategory = $Values<typeof BookkeepingReverseChargeCategoryValues>;

export const BookkeepingSolverValues = Object.freeze({
  Automation: 'AUTOMATION',
  AutomationAligner: 'AUTOMATION_ALIGNER',
  AutomationCustomerMissingPaymentOposClearing: 'AUTOMATION_CUSTOMER_MISSING_PAYMENT_OPOS_CLEARING',
  AutomationDefault: 'AUTOMATION_DEFAULT',
  AutomationMl: 'AUTOMATION_ML',
  AutomationStrategyPrevSupplierBookings: 'AUTOMATION_STRATEGY_PREV_SUPPLIER_BOOKINGS',
  AutomationTipOrDiscount: 'AUTOMATION_TIP_OR_DISCOUNT',
  BookkeeperBookingRule: 'BOOKKEEPER_BOOKING_RULE',
  BookkeeperManual: 'BOOKKEEPER_MANUAL',
  EntityEdit: 'ENTITY_EDIT',
  EntityInvalidation: 'ENTITY_INVALIDATION',
  Manual: 'MANUAL',
  PaymentRemnant: 'PAYMENT_REMNANT',
});

export type BookkeepingSolver = $Values<typeof BookkeepingSolverValues>;

export const BusinessAccountingBookingIndustryValues = Object.freeze({
  Agency: 'AGENCY',
  Agriculture: 'AGRICULTURE',
  Catering: 'CATERING',
  Consulting: 'CONSULTING',
  Craftsmen: 'CRAFTSMEN',
  DoctorsAndDentists: 'DOCTORS_AND_DENTISTS',
  ECommerce: 'E_COMMERCE',
  Gastronomy: 'GASTRONOMY',
  HotelIndustry: 'HOTEL_INDUSTRY',
  ManufacturingIndustry: 'MANUFACTURING_INDUSTRY',
  MobilityServices: 'MOBILITY_SERVICES',
  OtherServices: 'OTHER_SERVICES',
  Pharmacy: 'PHARMACY',
  Retail: 'RETAIL',
  TravelAgency: 'TRAVEL_AGENCY',
  Wholesale: 'WHOLESALE',
});

export type BusinessAccountingBookingIndustry = $Values<typeof BusinessAccountingBookingIndustryValues>;

export const BusinessAccountingBookingLogicTypeValues = Object.freeze({
  AlphabeticalAccounts: 'ALPHABETICAL_ACCOUNTS',
  GeneralAccounts: 'GENERAL_ACCOUNTS',
  IndividualAccounts: 'INDIVIDUAL_ACCOUNTS',
});

export type BusinessAccountingBookingLogicType = $Values<typeof BusinessAccountingBookingLogicTypeValues>;

export const BusinessAccountingBookkeepingDeadlineMonthValues = Object.freeze({
  MonthAfterBookkeepingMonth: 'MONTH_AFTER_BOOKKEEPING_MONTH',
  SecondMonthAfterBookkeepingMonth: 'SECOND_MONTH_AFTER_BOOKKEEPING_MONTH',
});

export type BusinessAccountingBookkeepingDeadlineMonth = $Values<
  typeof BusinessAccountingBookkeepingDeadlineMonthValues,
>;

export const BusinessAccountingBookkeepingSpecificationValues = Object.freeze({
  Skr03: 'SKR03',
  Skr04: 'SKR04',
});

export type BusinessAccountingBookkeepingSpecification = $Values<
  typeof BusinessAccountingBookkeepingSpecificationValues,
>;

export const BusinessAccountingExportVatKeysPreferenceValues = Object.freeze({
  ExcludeFromExport: 'EXCLUDE_FROM_EXPORT',
  IncludeInExport: 'INCLUDE_IN_EXPORT',
});

export type BusinessAccountingExportVatKeysPreference = $Values<typeof BusinessAccountingExportVatKeysPreferenceValues>;

export type BusinessAccountingMonitor = {|
  __typename?: 'BusinessAccountingMonitor',
  category?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  fullDescription?: ?$ElementType<Scalars, 'String'>,
  shortDescription?: ?$ElementType<Scalars, 'String'>,
  state?: ?$ElementType<Scalars, 'String'>,
  viewDefinition?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type BusinessAccountingMonitorsSummary = {|
  __typename?: 'BusinessAccountingMonitorsSummary',
  category?: ?$ElementType<Scalars, 'String'>,
  monitors?: ?Array<?BusinessAccountingMonitor>,
|};

export type BusinessAccountingNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessAccountingNode',
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    accountingOnboardingDate: $ElementType<Scalars, 'Date'>,
    accountingOffboardingDate: $ElementType<Scalars, 'Date'>,
    bookkeepingSpecification?: ?BusinessAccountingBookkeepingSpecification,
    bookkeepingSoftwareId?: ?$ElementType<Scalars, 'String'>,
    datevSubAdvisorNumber?: ?$ElementType<Scalars, 'String'>,
    outgoingDocumentIndicators?: ?$ElementType<Scalars, 'JSONString'>,
    vatRegistrationNumber?: ?$ElementType<Scalars, 'String'>,
    taxNumber?: ?$ElementType<Scalars, 'String'>,
    registerNumber?: ?$ElementType<Scalars, 'String'>,
    registrationIssuer?: ?$ElementType<Scalars, 'String'>,
    foundingDate?: ?$ElementType<Scalars, 'Date'>,
    vatTaxationStatus?: ?BusinessAccountingVatTaxationStatus,
    hasMonthlyVatDeclarationExtension?: ?$ElementType<Scalars, 'Boolean'>,
    bookingIndustry?: ?BusinessAccountingBookingIndustry,
    vatDeclarationPeriod?: ?BusinessAccountingVatDeclarationPeriod,
    zmDeclarationPeriod?: ?BusinessAccountingZmDeclarationPeriod,
    profitAssessmentMethod?: ?BusinessAccountingProfitAssessmentMethod,
    taxPaymentMethod?: ?BusinessAccountingTaxPaymentMethod,
    isMemberOfTaxableEntity?: ?$ElementType<Scalars, 'Boolean'>,
    isFiscalYearCalendarYear?: ?$ElementType<Scalars, 'Boolean'>,
    exportVatKeysPreference?: ?BusinessAccountingExportVatKeysPreference,
    parentCompany?: ?$ElementType<Scalars, 'String'>,
    rigorousMonitoring: $ElementType<Scalars, 'Boolean'>,
    mecMerchantActivationSalesforceCaseId?: ?$ElementType<Scalars, 'String'>,
    yearEndStatementWorkflowActivationStatus: BusinessAccountingYearEndStatementWorkflowActivationStatus,
    stringentBookkeepingForSupplierMissingPayableStatus: BusinessAccountingStringentBookkeepingForSupplierMissingPayableStatus,
    stringentBookkeepingForSupplierMissingPaymentStatus: BusinessAccountingStringentBookkeepingForSupplierMissingPaymentStatus,
    stringentBookkeepingForCustomerMissingPaymentStatus: BusinessAccountingStringentBookkeepingForCustomerMissingPaymentStatus,
    stringentBookkeepingForOverUnderPaidTransactionsStatus: BusinessAccountingStringentBookkeepingForOverUnderPaidTransactionsStatus,
    taxAdviser?: ?TaxAdviserNode,
    bookkeepingDeadlineDayOfMonth?: ?$ElementType<Scalars, 'Int'>,
    bookkeepingDeadlineMonth?: ?BusinessAccountingBookkeepingDeadlineMonth,
    bookingLogicType: BusinessAccountingBookingLogicType,
    lastExportPath?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
    businessOwners?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    hasOpenNedMecs?: ?$ElementType<Scalars, 'Boolean'>,
    bookingAccountsScheme?: ?BookingAccountsSchemeNode,
  |},
|};

export type BusinessAccountingNodeConnection = {|
  __typename?: 'BusinessAccountingNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessAccountingNodeEdge>,
|};

export type BusinessAccountingNodeEdge = {|
  __typename?: 'BusinessAccountingNodeEdge',
  node?: ?BusinessAccountingNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessAccountingProfitAssessmentMethodValues = Object.freeze({
  BalanceSheet: 'BALANCE_SHEET',
  NetIncome: 'NET_INCOME',
});

export type BusinessAccountingProfitAssessmentMethod = $Values<typeof BusinessAccountingProfitAssessmentMethodValues>;

export const BusinessAccountingStringentBookkeepingForCustomerMissingPaymentStatusValues = Object.freeze({
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
  Requested: 'REQUESTED',
});

export type BusinessAccountingStringentBookkeepingForCustomerMissingPaymentStatus = $Values<
  typeof BusinessAccountingStringentBookkeepingForCustomerMissingPaymentStatusValues,
>;

export const BusinessAccountingStringentBookkeepingForOverUnderPaidTransactionsStatusValues = Object.freeze({
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
  Requested: 'REQUESTED',
});

export type BusinessAccountingStringentBookkeepingForOverUnderPaidTransactionsStatus = $Values<
  typeof BusinessAccountingStringentBookkeepingForOverUnderPaidTransactionsStatusValues,
>;

export const BusinessAccountingStringentBookkeepingForSupplierMissingPayableStatusValues = Object.freeze({
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
  Requested: 'REQUESTED',
});

export type BusinessAccountingStringentBookkeepingForSupplierMissingPayableStatus = $Values<
  typeof BusinessAccountingStringentBookkeepingForSupplierMissingPayableStatusValues,
>;

export const BusinessAccountingStringentBookkeepingForSupplierMissingPaymentStatusValues = Object.freeze({
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
  Requested: 'REQUESTED',
});

export type BusinessAccountingStringentBookkeepingForSupplierMissingPaymentStatus = $Values<
  typeof BusinessAccountingStringentBookkeepingForSupplierMissingPaymentStatusValues,
>;

export const BusinessAccountingTaxPaymentMethodValues = Object.freeze({
  DirectDebit: 'DIRECT_DEBIT',
  CreditTransfer: 'CREDIT_TRANSFER',
});

export type BusinessAccountingTaxPaymentMethod = $Values<typeof BusinessAccountingTaxPaymentMethodValues>;

export const BusinessAccountingVatDeclarationPeriodValues = Object.freeze({
  Yearly: 'YEARLY',
  Quarterly: 'QUARTERLY',
  Monthly: 'MONTHLY',
});

export type BusinessAccountingVatDeclarationPeriod = $Values<typeof BusinessAccountingVatDeclarationPeriodValues>;

export const BusinessAccountingVatTaxationStatusValues = Object.freeze({
  Actual: 'ACTUAL',
  Target: 'TARGET',
});

export type BusinessAccountingVatTaxationStatus = $Values<typeof BusinessAccountingVatTaxationStatusValues>;

export const BusinessAccountingYearEndStatementWorkflowActivationStatusValues = Object.freeze({
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
  Requested: 'REQUESTED',
});

export type BusinessAccountingYearEndStatementWorkflowActivationStatus = $Values<
  typeof BusinessAccountingYearEndStatementWorkflowActivationStatusValues,
>;

export const BusinessAccountingZmDeclarationPeriodValues = Object.freeze({
  Never: 'NEVER',
  Quarterly: 'QUARTERLY',
  Monthly: 'MONTHLY',
  Yearly: 'YEARLY',
});

export type BusinessAccountingZmDeclarationPeriod = $Values<typeof BusinessAccountingZmDeclarationPeriodValues>;

export type BusinessCustomerNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessCustomerNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    counterpartyPtr: CounterPartyNode,
    legalName: $ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    customerNumber?: ?$ElementType<Scalars, 'String'>,
    counterParty?: ?CounterPartyNode,
  |},
|};

export type BusinessCustomerNodeConnection = {|
  __typename?: 'BusinessCustomerNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessCustomerNodeEdge>,
|};

export type BusinessCustomerNodeEdge = {|
  __typename?: 'BusinessCustomerNodeEdge',
  node?: ?BusinessCustomerNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessDigitalizationDeliveryTypeValues = Object.freeze({
  DropOff: 'DROP_OFF',
  PickUp: 'PICK_UP',
  SelfScan: 'SELF_SCAN',
  Stop: 'STOP',
  Undefined: 'UNDEFINED',
});

export type BusinessDigitalizationDeliveryType = $Values<typeof BusinessDigitalizationDeliveryTypeValues>;

export const BusinessDigitalizationDocumentPickupDayValues = Object.freeze({
  A_1: 'A_1',
  A_2: 'A_2',
  A_3: 'A_3',
  A_4: 'A_4',
  A_5: 'A_5',
  A_6: 'A_6',
  A_7: 'A_7',
});

export type BusinessDigitalizationDocumentPickupDay = $Values<typeof BusinessDigitalizationDocumentPickupDayValues>;

export const BusinessDigitalizationLogisticsPartnerValues = Object.freeze({
  DhlExpress: 'DHL_EXPRESS',
});

export type BusinessDigitalizationLogisticsPartner = $Values<typeof BusinessDigitalizationLogisticsPartnerValues>;

export type BusinessDigitalizationNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessDigitalizationNode',
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    documentPickupDay?: ?BusinessDigitalizationDocumentPickupDay,
    documentPickupFrameStartTime?: ?$ElementType<Scalars, 'Time'>,
    documentPickupFrameEndTime?: ?$ElementType<Scalars, 'Time'>,
    nameForEmailBox?: ?$ElementType<Scalars, 'String'>,
    pickupAddress?: ?AddressNode,
    pickupAddressContactName?: ?$ElementType<Scalars, 'String'>,
    pickupAddressComment?: ?$ElementType<Scalars, 'String'>,
    pickupAddressPhoneNumber?: ?$ElementType<Scalars, 'String'>,
    returnAddress?: ?AddressNode,
    returnAddressContactName?: ?$ElementType<Scalars, 'String'>,
    returnAddressComment?: ?$ElementType<Scalars, 'String'>,
    returnAddressPhoneNumber?: ?$ElementType<Scalars, 'String'>,
    logisticsPartner?: ?BusinessDigitalizationLogisticsPartner,
    deliveryType: BusinessDigitalizationDeliveryType,
    pickupPattern: BusinessDigitalizationPickupPattern,
    shouldRemindMerchant: $ElementType<Scalars, 'Boolean'>,
    lastExportPath?: ?$ElementType<Scalars, 'String'>,
    lastPioExportPath?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
  |},
|};

export type BusinessDigitalizationNodeConnection = {|
  __typename?: 'BusinessDigitalizationNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessDigitalizationNodeEdge>,
|};

export type BusinessDigitalizationNodeEdge = {|
  __typename?: 'BusinessDigitalizationNodeEdge',
  node?: ?BusinessDigitalizationNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessDigitalizationPickupPatternValues = Object.freeze({
  Undefined: 'UNDEFINED',
  WeekA: 'WEEK_A',
  WeekB: 'WEEK_B',
});

export type BusinessDigitalizationPickupPattern = $Values<typeof BusinessDigitalizationPickupPatternValues>;

export type BusinessEmployeeEdge = {|
  __typename?: 'BusinessEmployeeEdge',
  node?: ?BusinessEmployeeNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessEmployeeEmploymentStatusValues = Object.freeze({
  Apprentice: 'APPRENTICE',
  Employed: 'EMPLOYED',
  Freelancer: 'FREELANCER',
  Housework: 'HOUSEWORK',
  Management: 'MANAGEMENT',
  MilitaryOrCommunityService: 'MILITARY_OR_COMMUNITY_SERVICE',
  ProfessionalSoldier: 'PROFESSIONAL_SOLDIER',
  PublicSectorEmployee: 'PUBLIC_SECTOR_EMPLOYEE',
  Retired: 'RETIRED',
  SelfEmployed: 'SELF_EMPLOYED',
  Student: 'STUDENT',
  Unemployed: 'UNEMPLOYED',
});

export type BusinessEmployeeEmploymentStatus = $Values<typeof BusinessEmployeeEmploymentStatusValues>;

export const BusinessEmployeeEndOfEmploymentReasonValues = Object.freeze({
  Contractcancellationagreement: 'CONTRACTCANCELLATIONAGREEMENT',
  ContractExpired: 'CONTRACT_EXPIRED',
  Other: 'OTHER',
  TerminationByEmployee: 'TERMINATION_BY_EMPLOYEE',
  TerminationByEmployer: 'TERMINATION_BY_EMPLOYER',
});

export type BusinessEmployeeEndOfEmploymentReason = $Values<typeof BusinessEmployeeEndOfEmploymentReasonValues>;

export const BusinessEmployeeExportStatusValues = Object.freeze({
  Exported: 'EXPORTED',
  NotExported: 'NOT_EXPORTED',
  Pending: 'PENDING',
});

export type BusinessEmployeeExportStatus = $Values<typeof BusinessEmployeeExportStatusValues>;

export type BusinessEmployeeNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessEmployeeNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    counterpartyPtr: CounterPartyNode,
    firstName: $ElementType<Scalars, 'String'>,
    lastName: $ElementType<Scalars, 'String'>,
    phoneNumber?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    employeeNumber?: ?$ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    isActive: $ElementType<Scalars, 'Boolean'>,
    isOwner: $ElementType<Scalars, 'Boolean'>,
    dateOfBirth?: ?$ElementType<Scalars, 'Date'>,
    placeOfBirth?: ?$ElementType<Scalars, 'String'>,
    birthCountry?: ?$ElementType<Scalars, 'String'>,
    nationality?: ?$ElementType<Scalars, 'String'>,
    address?: ?AddressNode,
    taxIdentificationNumber?: ?$ElementType<Scalars, 'String'>,
    employmentStartingDate?: ?$ElementType<Scalars, 'Date'>,
    employmentEndDate?: ?$ElementType<Scalars, 'Date'>,
    endOfEmploymentReason?: ?BusinessEmployeeEndOfEmploymentReason,
    employmentStatus?: ?BusinessEmployeeEmploymentStatus,
    legalRepresentative: $ElementType<Scalars, 'Boolean'>,
    ownershipPercentage?: ?$ElementType<Scalars, 'Float'>,
    ownerBookingAccount?: ?$ElementType<Scalars, 'Int'>,
    datevOwnerId?: ?$ElementType<Scalars, 'Int'>,
    salutation?: ?BusinessEmployeeSalutation,
    taxCountry?: ?$ElementType<Scalars, 'String'>,
    onboardingStatus: BusinessEmployeeOnboardingStatus,
    minAppliedDateForEmployeeChanges?: ?$ElementType<Scalars, 'Date'>,
    fixBenefits?: ?$ElementType<Scalars, 'JSONString'>,
    nonTimeDependentAdditionalData?: ?$ElementType<Scalars, 'JSONString'>,
    questionnaireRaw?: ?$ElementType<Scalars, 'JSONString'>,
    questionnaireReceiveDate?: ?$ElementType<Scalars, 'Date'>,
    immediateRegistrationForm?: ?$ElementType<Scalars, 'JSONString'>,
    isRegisteredToDailyPaymentViaEmail: $ElementType<Scalars, 'Boolean'>,
    nonRequiredDocuments?: ?Array<?$ElementType<Scalars, 'String'>>,
    exportStatus: BusinessEmployeeExportStatus,
    publicHealthInsuranceProvider?: ?SocialInsuranceNode,
    allTimeDependentData?: ?BusinessEmployeeTimeDependentDataNodeConnection,
    ownerRelatedPayments: PaymentNodeConnection,
    expenseStatements: ExpenseStatementNodeConnection,
    employeeMonthlyUpdates?: ?EmployeeMonthlyUpdateNodeConnection,
    paymentidentificationruleSet: PaymentIdentificationRuleNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    ultimateBeneficialOwner?: ?$ElementType<Scalars, 'Boolean'>,
    isAuthorizedForApp?: ?$ElementType<Scalars, 'Boolean'>,
    taxCountryCode?: ?$ElementType<Scalars, 'String'>,
    birthCountryCode?: ?$ElementType<Scalars, 'String'>,
    nationalityCode?: ?$ElementType<Scalars, 'String'>,
    additionalData?: ?$ElementType<Scalars, 'JSONString'>,
    employmentType?: ?$ElementType<Scalars, 'String'>,
    futureTimeDependentData?: ?BusinessEmployeeTimeDependentDataNodeConnection,
    currentTimeDependentData?: ?BusinessEmployeeTimeDependentDataNode,
    socialInsuranceBusinessSupplier?: ?BusinessSupplierNode,
    records?: ?RecordNodeConnection,
    merchantTasks?: ?TaskManagerTaskConnection,
    preliminaryPayslipRecommendation?: ?PreliminaryPayslipRecommendationNode,
  |},
|};

export type BusinessEmployeeNodeAllTimeDependentDataArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  frameStart?: ?$ElementType<Scalars, 'Date'>,
  frameStart_Gt?: ?$ElementType<Scalars, 'Date'>,
  frameStart_Gte?: ?$ElementType<Scalars, 'Date'>,
  frameStart_Lt?: ?$ElementType<Scalars, 'Date'>,
  frameStart_Lte?: ?$ElementType<Scalars, 'Date'>,
  frameEnd?: ?$ElementType<Scalars, 'Date'>,
  frameEnd_Gt?: ?$ElementType<Scalars, 'Date'>,
  frameEnd_Gte?: ?$ElementType<Scalars, 'Date'>,
  frameEnd_Lt?: ?$ElementType<Scalars, 'Date'>,
  frameEnd_Lte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessEmployeeNodeOwnerRelatedPaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessEmployeeNodeExpenseStatementsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessEmployeeNodeEmployeeMonthlyUpdatesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  month?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessEmployeeNodePaymentidentificationruleSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessEmployeeNodeFutureTimeDependentDataArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessEmployeeNodeRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesscustomer_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_FirstName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_LastName?: ?$ElementType<Scalars, 'String'>,
  payables_Status?: ?$ElementType<Scalars, 'String'>,
  counterPartyId?: ?$ElementType<Scalars, 'ID'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  disjunctiveSearch?: ?$ElementType<Scalars, 'String'>,
  possibleWronglyClassifiedRecordTypes?: ?$ElementType<Scalars, 'Boolean'>,
  data?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  excludeUnchangedRecords?: ?$ElementType<Scalars, 'Boolean'>,
  excludeMultiRecordDocuments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeRecordTypes?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessEmployeeNodeMerchantTasksArgs = {|
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessEmployeeNodePreliminaryPayslipRecommendationArgs = {|
  payrollDate?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessEmployeeNodeConnection = {|
  __typename?: 'BusinessEmployeeNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessEmployeeNodeEdge>,
|};

export type BusinessEmployeeNodeEdge = {|
  __typename?: 'BusinessEmployeeNodeEdge',
  node?: ?BusinessEmployeeNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessEmployeeOnboardingStatusValues = Object.freeze({
  NeverOnboarded: 'NEVER_ONBOARDED',
  PendingConfirmationOfEmployeesList: 'PENDING_CONFIRMATION_OF_EMPLOYEES_LIST',
  PendingImmediateRegistrationForm: 'PENDING_IMMEDIATE_REGISTRATION_FORM',
  PendingQuestionnaire: 'PENDING_QUESTIONNAIRE',
  PendingQuestionnaireOwnerVerification: 'PENDING_QUESTIONNAIRE_OWNER_VERIFICATION',
  Verified: 'VERIFIED',
});

export type BusinessEmployeeOnboardingStatus = $Values<typeof BusinessEmployeeOnboardingStatusValues>;

export const BusinessEmployeeSalutationValues = Object.freeze({
  Mr: 'MR',
  Ms: 'MS',
});

export type BusinessEmployeeSalutation = $Values<typeof BusinessEmployeeSalutationValues>;

export const BusinessEmployeeTimeDependentDataEmploymentTypeValues = Object.freeze({
  Hourly: 'HOURLY',
  Monthly: 'MONTHLY',
});

export type BusinessEmployeeTimeDependentDataEmploymentType = $Values<
  typeof BusinessEmployeeTimeDependentDataEmploymentTypeValues,
>;

export type BusinessEmployeeTimeDependentDataNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessEmployeeTimeDependentDataNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    employmentType?: ?BusinessEmployeeTimeDependentDataEmploymentType,
    timeDependentAdditionalData: $ElementType<Scalars, 'JSONString'>,
    frameStart: $ElementType<Scalars, 'Date'>,
    frameEnd: $ElementType<Scalars, 'Date'>,
    employee: BusinessEmployeeNode,
    uid?: ?$ElementType<Scalars, 'String'>,
    additionalData?: ?$ElementType<Scalars, 'JSONString'>,
    monthlySalaryEstimation?: ?$ElementType<Scalars, 'Float'>,
    employmentTypeCode?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BusinessEmployeeTimeDependentDataNodeConnection = {|
  __typename?: 'BusinessEmployeeTimeDependentDataNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessEmployeeTimeDependentDataNodeEdge>,
|};

export type BusinessEmployeeTimeDependentDataNodeEdge = {|
  __typename?: 'BusinessEmployeeTimeDependentDataNodeEdge',
  node?: ?BusinessEmployeeTimeDependentDataNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessEstimatedChurnRiskValues = Object.freeze({
  High: 'HIGH',
  Low: 'LOW',
  Medium: 'MEDIUM',
});

export type BusinessEstimatedChurnRisk = $Values<typeof BusinessEstimatedChurnRiskValues>;

export const BusinessLegalEntityTypeValues = Object.freeze({
  Ag: 'AG',
  Ek: 'EK',
  Freiberufler: 'FREIBERUFLER',
  Gbr: 'GBR',
  Gmbh: 'GMBH',
  GmbhCoKg: 'GMBH_CO_KG',
  Kg: 'KG',
  Ohg: 'OHG',
  Einzelunternehmen: 'EINZELUNTERNEHMEN',
  Ug: 'UG',
  UgCoKg: 'UG_CO_KG',
  Unknown: 'UNKNOWN',
});

export type BusinessLegalEntityType = $Values<typeof BusinessLegalEntityTypeValues>;

export const BusinessMigrationWaveValues = Object.freeze({
  A_0: 'A_0',
  A_1: 'A_1',
  A_2: 'A_2',
  A_3: 'A_3',
  A_4: 'A_4',
  A_5: 'A_5',
  A_6: 'A_6',
});

export type BusinessMigrationWave = $Values<typeof BusinessMigrationWaveValues>;

export type BusinessNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    name: $ElementType<Scalars, 'String'>,
    legalName?: ?$ElementType<Scalars, 'String'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    recipientName?: ?$ElementType<Scalars, 'String'>,
    isActive: $ElementType<Scalars, 'Boolean'>,
    isTestOrDemo: $ElementType<Scalars, 'Boolean'>,
    legalEntityType: BusinessLegalEntityType,
    address?: ?AddressNode,
    secondaryCompliantAddresses: Array<$ElementType<Scalars, 'String'>>,
    phoneNumber?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    purpose: $ElementType<Scalars, 'String'>,
    locale?: ?$ElementType<Scalars, 'String'>,
    mainBankAccount?: ?MerchantPaymentAccountNode,
    paymentOnboardingDate: $ElementType<Scalars, 'Date'>,
    subscriptionStartDate: $ElementType<Scalars, 'Date'>,
    firstDateOfPickup?: ?$ElementType<Scalars, 'Date'>,
    offboardingDate: $ElementType<Scalars, 'Date'>,
    contractTerminationDate: $ElementType<Scalars, 'Date'>,
    estimatedChurnRisk: BusinessEstimatedChurnRisk,
    newlyFoundedBusiness: $ElementType<Scalars, 'Boolean'>,
    migrationWave?: ?BusinessMigrationWave,
    migrationStartTime?: ?$ElementType<Scalars, 'DateTime'>,
    migrationEndTime?: ?$ElementType<Scalars, 'DateTime'>,
    migrationLastFinishedStep?: ?$ElementType<Scalars, 'String'>,
    issues?: ?IssueNodeConnection,
    taskActions: TaskActionNodeConnection,
    taxAdvisorBusinessGroups: TaxAdvisorBusinessGroupNodeConnection,
    businessDigitalization?: ?BusinessDigitalizationNode,
    businessAccounting?: ?BusinessAccountingNode,
    businessPayroll?: ?BusinessPayrollNode,
    chatmessageSet: ChatMessageNodeConnection,
    notes?: ?EntityNoteNodeConnection,
    documents?: ?DocumentNodeConnection,
    counterParties?: ?CounterPartyNodeConnection,
    paymentAccounts?: ?MerchantPaymentAccountNodeConnection,
    records?: ?RecordNodeConnection,
    businessTransactions?: ?TransactionNodeConnection,
    contracts: ContractNodeConnection,
    expenseStatements: ExpenseStatementNodeConnection,
    appUsers: AppUserNodeConnection,
    appusertobusinessSet: AppUserToBusinessNodeConnection,
    feedLogs?: ?FeedLogNodeConnection,
    bookingExports: BookkeepingExportNodeConnection,
    bookkeeping: BookkeepingNodeConnection,
    bookingaccountcaptionSet: BookingAccountCaptionNodeConnection,
    suppliers?: ?SupplierNodeConnection,
    paymentIdentificationRules: PaymentIdentificationRuleNodeConnection,
    todoListItems?: ?TodoListItemNodeConnection,
    businessOnboarding?: ?BusinessOnboardingNode,
    uid?: ?$ElementType<Scalars, 'String'>,
    event?: ?EventNode,
    events?: ?EventNodeConnection,
    cashJournalsProgress?: ?Array<?LinearProgressItemNode>,
    tasks?: ?TaskManagerTaskConnection,
    merchantOnboardingTasks?: ?TaskManagerTaskConnection,
    merchantTasks?: ?TaskManagerTaskConnection,
    cashEntryProtocolSchemes?: ?Array<?CashEntryProtocolScheme>,
    businessEmployees?: ?BusinessEmployeeNodeConnection,
    businessSuppliers?: ?BusinessSupplierNodeConnection,
    businessPaymentIdentificationStatus?: ?BusinessPaymentIdentificationNode,
    paymentServiceAccountLimit?: ?$ElementType<Scalars, 'JSONString'>,
    areAllPaymentsFetchedAutomatically?: ?$ElementType<Scalars, 'Boolean'>,
    merchantPaymentAccount?: ?MerchantPaymentAccountNode,
    mainBankPaymentAccount?: ?MerchantPaymentAccountNode,
    solarisPaymentAccount?: ?MerchantPaymentAccountNode,
    mainBankPaymentAccountPastTransaction?: ?$ElementType<Scalars, 'JSONString'>,
    cashRegisterAccountsCount?: ?$ElementType<Scalars, 'Int'>,
    pendingPaymentsAmount?: ?$ElementType<Scalars, 'Float'>,
    solarisMandateDetails?: ?$ElementType<Scalars, 'JSONString'>,
    taxCountryCode?: ?$ElementType<Scalars, 'String'>,
    taxCountry?: ?$ElementType<Scalars, 'String'>,
    questionnaireUrl?: ?$ElementType<Scalars, 'String'>,
    immediateRegistrationFormUrl?: ?$ElementType<Scalars, 'String'>,
    jsonSchemes?: ?Array<?JsonSchemeNode>,
    jsonScheme?: ?JsonSchemeNode,
    domainsToDocumentTypes?: ?$ElementType<Scalars, 'JSONString'>,
    bookkeepingAccounts?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    paymentServiceStatus?: ?$ElementType<Scalars, 'String'>,
    bookingIndustry?: ?$ElementType<Scalars, 'String'>,
    documentSearchAutocomplete?: ?Array<?$ElementType<Scalars, 'String'>>,
    referralCode?: ?$ElementType<Scalars, 'String'>,
    socialInsurances?: ?SocialInsuranceNodeConnection,
    payments?: ?PaymentNodeConnection,
    payables?: ?PayableNodeConnection,
    activeExperiments?: ?Array<?$ElementType<Scalars, 'String'>>,
    financialAdvice?: ?FinancialAdviceNode,
    bookingExportsMonthRange?: ?Array<?$ElementType<Scalars, 'String'>>,
    accountingControlCenter?: ?AccountingControlCenterNode,
    weatherHistory?: ?$ElementType<Scalars, 'JSONString'>,
    weatherForecast?: ?$ElementType<Scalars, 'JSONString'>,
    experienceGroup?: ?$ElementType<Scalars, 'String'>,
    nedOnboardingProgress?: ?NedOnboardingProgressNode,
    merchantAccountingTimeline?: ?Array<?MerchantTimelineItemNode>,
    taxAdvisorBookkeepingOverview?: ?TaxAdvisorBookkeepingOverview,
    shouldShowWebappOnboardingCarousel?: ?$ElementType<Scalars, 'Boolean'>,
    bookingAccountCaptions?: ?BookingAccountCaptionNodeConnection,
    bookingRules?: ?BookingRuleNodeConnection,
    mecEvent?: ?EventNode,
    subscriptionPolling?: ?UpdatedEntitiesNode,
  |},
|};

export type BusinessNodeIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  missingRecurrentDocument_DocumentType?: ?$ElementType<Scalars, 'String'>,
  missingRecurrentDocument_DocumentType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasBookingReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeTaxAdvisorBusinessGroupsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  taxAdvisorId?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeChatmessageSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_In?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  processingStatus?: ?$ElementType<Scalars, 'String'>,
  processingStatus_In?: ?$ElementType<Scalars, 'String'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
  inputChannel_In?: ?$ElementType<Scalars, 'String'>,
  records_Payables_Direction?: ?$ElementType<Scalars, 'String'>,
  records_OccurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  searchElasticsearch?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  unextracted?: ?$ElementType<Scalars, 'Boolean'>,
  unprocessed?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  transactionTaskActionName?: ?$ElementType<Scalars, 'String'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  correctionNeeded?: ?$ElementType<Scalars, 'Boolean'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  inputChannelByNames?: ?$ElementType<Scalars, 'String'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  issueDateFrom?: ?$ElementType<Scalars, 'String'>,
  issueDateTo?: ?$ElementType<Scalars, 'String'>,
  prefetchPayableCounterParty?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeCounterPartiesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  supplierCounterPartyType?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodePaymentAccountsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  shouldTrySyncFinapi?: ?$ElementType<Scalars, 'Boolean'>,
  accountType?: ?$ElementType<Scalars, 'String'>,
  accountType_In?: ?$ElementType<Scalars, 'String'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  finapiAccountStatus?: ?$ElementType<Scalars, 'String'>,
  finapiAccountStatus_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isOnlyFigoActive?: ?$ElementType<Scalars, 'Boolean'>,
  isOnlyInRelevancePeriod?: ?$ElementType<Scalars, 'Boolean'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesscustomer_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_FirstName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_LastName?: ?$ElementType<Scalars, 'String'>,
  payables_Status?: ?$ElementType<Scalars, 'String'>,
  counterPartyId?: ?$ElementType<Scalars, 'ID'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  disjunctiveSearch?: ?$ElementType<Scalars, 'String'>,
  possibleWronglyClassifiedRecordTypes?: ?$ElementType<Scalars, 'Boolean'>,
  data?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  excludeUnchangedRecords?: ?$ElementType<Scalars, 'Boolean'>,
  excludeMultiRecordDocuments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeRecordTypes?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeBusinessTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  minPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  maxPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  minPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  maxPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  minDate?: ?$ElementType<Scalars, 'String'>,
  maxDate?: ?$ElementType<Scalars, 'String'>,
  payablesAmount?: ?$ElementType<Scalars, 'String'>,
  paymentsAmount?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  counterParty?: ?$ElementType<Scalars, 'String'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  reference?: ?$ElementType<Scalars, 'String'>,
  ocrSearch?: ?$ElementType<Scalars, 'String'>,
  matchToRecord?: ?$ElementType<Scalars, 'String'>,
  hasReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeContractsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  counterParty?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeExpenseStatementsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeAppUsersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  email?: ?$ElementType<Scalars, 'String'>,
  email_Iexact?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeAppusertobusinessSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeFeedLogsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  logType?: ?$ElementType<Scalars, 'String'>,
  logType_In?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  createdAt_In?: ?$ElementType<Scalars, 'DateTime'>,
  user?: ?$ElementType<Scalars, 'ID'>,
  user_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  excludeInternalUserActions?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeBookingExportsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  month?: ?$ElementType<Scalars, 'Date'>,
  createdInMonth?: ?$ElementType<Scalars, 'Date'>,
|};

export type BusinessNodeBookkeepingArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeBookingaccountcaptionSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeSuppliersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isAutoGeneratedLegalName?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodePaymentIdentificationRulesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeTodoListItemsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  date_Year?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Month?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isBlocker?: ?$ElementType<Scalars, 'Boolean'>,
  type?: ?$ElementType<Scalars, 'String'>,
  type_In?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  status_In?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeEventArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_Iexact?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_In?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  closedAt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_In?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  relevantFrameStartTime?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_In?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lte?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasMerchantPendingIssues?: ?$ElementType<Scalars, 'Boolean'>,
  getFirstEvent?: ?$ElementType<Scalars, 'Boolean'>,
  alreadyStarted?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeCashJournalsProgressArgs = {|
  relevantFrameStartTime?: ?$ElementType<Scalars, 'Date'>,
|};

export type BusinessNodeTasksArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType?: ?$ElementType<Scalars, 'String'>,
  taskType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType_NotIn?: ?Array<?$ElementType<Scalars, 'String'>>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  realBusinessesOnly?: ?$ElementType<Scalars, 'Boolean'>,
  onlyWithoutEvents?: ?$ElementType<Scalars, 'Boolean'>,
  certification?: ?$ElementType<Scalars, 'String'>,
  certification_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  module?: ?$ElementType<Scalars, 'String'>,
  module_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  sourceEntityId?: ?$ElementType<Scalars, 'String'>,
  sourceEntityId_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  externalId_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  categorizationFeatures?: ?$ElementType<Scalars, 'String'>,
  priority?: ?$ElementType<Scalars, 'String'>,
  priority_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  experience?: ?$ElementType<Scalars, 'String'>,
  experience_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  isBlockedByDelegation?: ?$ElementType<Scalars, 'Boolean'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeMerchantOnboardingTasksArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType?: ?$ElementType<Scalars, 'String'>,
  taskType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType_NotIn?: ?Array<?$ElementType<Scalars, 'String'>>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  onlyWithoutEvents?: ?$ElementType<Scalars, 'Boolean'>,
  sourceEntityId?: ?$ElementType<Scalars, 'String'>,
  sourceEntityId_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  categorizationFeatures?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeMerchantTasksArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType?: ?$ElementType<Scalars, 'String'>,
  taskType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType_NotIn?: ?Array<?$ElementType<Scalars, 'String'>>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  onlyWithoutEvents?: ?$ElementType<Scalars, 'Boolean'>,
  sourceEntityId?: ?$ElementType<Scalars, 'String'>,
  sourceEntityId_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  categorizationFeatures?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeBusinessEmployeesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  email?: ?$ElementType<Scalars, 'String'>,
  email_Iexact?: ?$ElementType<Scalars, 'String'>,
  employeeNumber?: ?$ElementType<Scalars, 'String'>,
  onboardingStatus?: ?$ElementType<Scalars, 'String'>,
  isOwner?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isRelevantForPaymentAccountCreation?: ?$ElementType<Scalars, 'Boolean'>,
  isRelevantForPaymentAccountTermsAndConditions?: ?$ElementType<Scalars, 'Boolean'>,
  employmentType?: ?$ElementType<Scalars, 'String'>,
  employeeName?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeBusinessSuppliersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business_Id?: ?$ElementType<Scalars, 'UUID'>,
  displayName_Iexact?: ?$ElementType<Scalars, 'String'>,
  legalName_Iexact?: ?$ElementType<Scalars, 'String'>,
  bankAccount_Iexact?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  isUseDirectDebit?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeMerchantPaymentAccountArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeJsonSchemesArgs = {|
  schemeCategory?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeJsonSchemeArgs = {|
  name?: ?$ElementType<Scalars, 'String'>,
  customSchemePrefix?: ?$ElementType<Scalars, 'String'>,
  recordId?: ?$ElementType<Scalars, 'ID'>,
  documentId?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeDomainsToDocumentTypesArgs = {|
  domain?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeDocumentSearchAutocompleteArgs = {|
  text?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeSocialInsurancesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  onlyMissingMainPayable?: ?$ElementType<Scalars, 'Boolean'>,
  bookkeepingPaymentsPerMonth?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBreakdownDerivedPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBreakdownOriginalPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeCashRevenuePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
  isExplained?: ?$ElementType<Scalars, 'Boolean'>,
  isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  dateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeWeatherHistoryArgs = {|
  q?: ?$ElementType<Scalars, 'String'>,
  fromDate: $ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
  lang?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeWeatherForecastArgs = {|
  q?: ?$ElementType<Scalars, 'String'>,
  days?: ?$ElementType<Scalars, 'String'>,
  lang?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeMerchantAccountingTimelineArgs = {|
  maxNumOfPastItems?: ?$ElementType<Scalars, 'Int'>,
  maxNumOfFutureItems?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeBookingAccountCaptionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeBookingRulesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  entityDirection?: ?$ElementType<Scalars, 'String'>,
  ruleType?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeMecEventArgs = {|
  eventId?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeSubscriptionPollingArgs = {|
  key: SubscriptionPolingTypeEnum,
  cursor?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeConnection = {|
  __typename?: 'BusinessNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessNodeEdge>,
|};

export type BusinessNodeEdge = {|
  __typename?: 'BusinessNodeEdge',
  node?: ?BusinessNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BusinessOnboardingNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessOnboardingNode',
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    bwaDocument?: ?DocumentNode,
    zreportDocument?: ?DocumentNode,
    salesforceId?: ?$ElementType<Scalars, 'String'>,
    salesforceData?: ?$ElementType<Scalars, 'JSONString'>,
    calendlyLink?: ?$ElementType<Scalars, 'String'>,
    payrollJotformLink?: ?$ElementType<Scalars, 'String'>,
    accountingJotformLink?: ?$ElementType<Scalars, 'String'>,
    payrollJotformCompleted?: ?$ElementType<Scalars, 'Boolean'>,
    accountingJotformCompleted?: ?$ElementType<Scalars, 'Boolean'>,
    id: $ElementType<Scalars, 'ID'>,
  |},
|};

export type BusinessPaymentIdentificationNode = {|
  __typename?: 'BusinessPaymentIdentificationNode',
  personsIdentification?: ?Array<?PersonIdentificationNode>,
|};

export const BusinessPaymentProviderFeeVatRateValues = Object.freeze({
  NotRelevant: 'NOT_RELEVANT',
  A_0: 'A_0',
  A_16: 'A_16',
  A_19: 'A_19',
  A_5: 'A_5',
  A_7: 'A_7',
});

export type BusinessPaymentProviderFeeVatRate = $Values<typeof BusinessPaymentProviderFeeVatRateValues>;

export type BusinessPaymentProviderNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessPaymentProviderNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    counterpartyPtr: CounterPartyNode,
    legalName?: ?$ElementType<Scalars, 'String'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    isForCashRegister: $ElementType<Scalars, 'Boolean'>,
    displayNameForCashRegister?: ?$ElementType<Scalars, 'String'>,
    feeBookingScenario?: ?BookingScenarioNode,
    feeVatRate?: ?BusinessPaymentProviderFeeVatRate,
    paymentAccount?: ?MerchantPaymentAccountNode,
    isUsedForRevenue?: ?$ElementType<Scalars, 'Boolean'>,
    revenueVatRate?: ?BusinessPaymentProviderRevenueVatRate,
    activeSince?: ?$ElementType<Scalars, 'Date'>,
    activeUntil: $ElementType<Scalars, 'Date'>,
    paymentIdentificationRule?: ?PaymentIdentificationRuleNode,
    bookingTextTemplate?: ?$ElementType<Scalars, 'String'>,
    relatedPayments: PaymentNodeConnection,
    paymentidentificationruleSet: PaymentIdentificationRuleNodeConnection,
    isUsageBased?: ?$ElementType<Scalars, 'Boolean'>,
    paymentProviderStatements?: ?PaymentProviderStatementNodeConnection,
    counterParty?: ?CounterPartyNode,
  |},
|};

export type BusinessPaymentProviderNodeRelatedPaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessPaymentProviderNodePaymentidentificationruleSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessPaymentProviderNodePaymentProviderStatementsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesscustomer_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_FirstName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_LastName?: ?$ElementType<Scalars, 'String'>,
  payables_Status?: ?$ElementType<Scalars, 'String'>,
  counterPartyId?: ?$ElementType<Scalars, 'ID'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  disjunctiveSearch?: ?$ElementType<Scalars, 'String'>,
  possibleWronglyClassifiedRecordTypes?: ?$ElementType<Scalars, 'Boolean'>,
  data?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  excludeUnchangedRecords?: ?$ElementType<Scalars, 'Boolean'>,
  excludeMultiRecordDocuments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeRecordTypes?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessPaymentProviderNodeConnection = {|
  __typename?: 'BusinessPaymentProviderNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessPaymentProviderNodeEdge>,
|};

export type BusinessPaymentProviderNodeEdge = {|
  __typename?: 'BusinessPaymentProviderNodeEdge',
  node?: ?BusinessPaymentProviderNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessPaymentProviderRevenueVatRateValues = Object.freeze({
  NotRelevant: 'NOT_RELEVANT',
  A_0: 'A_0',
  A_16: 'A_16',
  A_19: 'A_19',
  A_5: 'A_5',
  A_7: 'A_7',
});

export type BusinessPaymentProviderRevenueVatRate = $Values<typeof BusinessPaymentProviderRevenueVatRateValues>;

export const BusinessPayrollGermanyPartValues = Object.freeze({
  East: 'EAST',
  West: 'WEST',
});

export type BusinessPayrollGermanyPart = $Values<typeof BusinessPayrollGermanyPartValues>;

export type BusinessPayrollNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessPayrollNode',
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    payrollOnboardingDate: $ElementType<Scalars, 'Date'>,
    socialInsuranceId?: ?$ElementType<Scalars, 'String'>,
    employeeImmediateRegistrationRequire: $ElementType<Scalars, 'Boolean'>,
    adpClientId?: ?$ElementType<Scalars, 'Int'>,
    payrollTaxPeriod?: ?BusinessPayrollPayrollTaxPeriod,
    germanyPart?: ?BusinessPayrollGermanyPart,
    liabilityInsurancePin?: ?$ElementType<Scalars, 'String'>,
    weeklyWorkHours?: ?$ElementType<Scalars, 'Int'>,
    employerLiabilityInsurance?: ?$ElementType<Scalars, 'String'>,
    membershipNumberOfEmployerLiabilityInsurance?: ?$ElementType<Scalars, 'String'>,
    dangerClass?: ?$ElementType<Scalars, 'String'>,
    dangerScale?: ?$ElementType<Scalars, 'String'>,
    payrollProvider: BusinessPayrollPayrollProvider,
    taxOffice?: ?$ElementType<Scalars, 'String'>,
    lastExportPath?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
  |},
|};

export const BusinessPayrollPayrollProviderValues = Object.freeze({
  Adp: 'ADP',
  LohnDialog: 'LOHN_DIALOG',
  LohnUnion: 'LOHN_UNION',
  Zeitgold: 'ZEITGOLD',
});

export type BusinessPayrollPayrollProvider = $Values<typeof BusinessPayrollPayrollProviderValues>;

export const BusinessPayrollPayrollTaxPeriodValues = Object.freeze({
  Monthly: 'MONTHLY',
  Quarterly: 'QUARTERLY',
  Yearly: 'YEARLY',
});

export type BusinessPayrollPayrollTaxPeriod = $Values<typeof BusinessPayrollPayrollTaxPeriodValues>;

export const BusinessSupplierCounterPartyTypeValues = Object.freeze({
  Bank: 'BANK',
  CashRegister: 'CASH_REGISTER',
  Customer: 'CUSTOMER',
  Employee: 'EMPLOYEE',
  EmploymentAgency: 'EMPLOYMENT_AGENCY',
  FinancialAuthority: 'FINANCIAL_AUTHORITY',
  LoanOrDeposit: 'LOAN_OR_DEPOSIT',
  MerchantAccount: 'MERCHANT_ACCOUNT',
  PaymentProvider: 'PAYMENT_PROVIDER',
  SocialInsurance: 'SOCIAL_INSURANCE',
  Supplier: 'SUPPLIER',
  TvAndRadioLicenseFees: 'TV_AND_RADIO_LICENSE_FEES',
  Unknown: 'UNKNOWN',
});

export type BusinessSupplierCounterPartyType = $Values<typeof BusinessSupplierCounterPartyTypeValues>;

export type BusinessSupplierNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessSupplierNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    counterpartyPtr: CounterPartyNode,
    legalName?: ?$ElementType<Scalars, 'String'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    supplierType: BusinessSupplierSupplierType,
    vatRegistrationNumber?: ?$ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    customerNumber?: ?$ElementType<Scalars, 'String'>,
    isUseDirectDebit: $ElementType<Scalars, 'Boolean'>,
    email?: ?$ElementType<Scalars, 'String'>,
    counterPartyType: BusinessSupplierCounterPartyType,
    socialInsurance?: ?SocialInsuranceNode,
    isSocialInsuranceInDatev: $ElementType<Scalars, 'Boolean'>,
    offeredGoodsCategory: BusinessSupplierOfferedGoodsCategory,
    name?: ?$ElementType<Scalars, 'String'>,
    counterParty?: ?CounterPartyNode,
  |},
|};

export type BusinessSupplierNodeConnection = {|
  __typename?: 'BusinessSupplierNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessSupplierNodeEdge>,
|};

export type BusinessSupplierNodeEdge = {|
  __typename?: 'BusinessSupplierNodeEdge',
  node?: ?BusinessSupplierNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessSupplierOfferedGoodsCategoryValues = Object.freeze({
  Drinks: 'DRINKS',
  Food: 'FOOD',
  FoodAndDrinks: 'FOOD_AND_DRINKS',
  Other: 'OTHER',
  Unknown: 'UNKNOWN',
});

export type BusinessSupplierOfferedGoodsCategory = $Values<typeof BusinessSupplierOfferedGoodsCategoryValues>;

export const BusinessSupplierSupplierTypeValues = Object.freeze({
  FederalAgencyForEmployment: 'FEDERAL_AGENCY_FOR_EMPLOYMENT',
  LiabilityInsurance: 'LIABILITY_INSURANCE',
  Pension: 'PENSION',
  SocialInsurance: 'SOCIAL_INSURANCE',
  TaxAuthority: 'TAX_AUTHORITY',
  Unknown: 'UNKNOWN',
});

export type BusinessSupplierSupplierType = $Values<typeof BusinessSupplierSupplierTypeValues>;

export const BusinessTransactionDirectionValues = Object.freeze({
  Incoming: 'INCOMING',
  Outgoing: 'OUTGOING',
});

export type BusinessTransactionDirection = $Values<typeof BusinessTransactionDirectionValues>;

export type CashEntryProtocolScheme = {|
  ...Node,
  ...{|
    __typename?: 'CashEntryProtocolScheme',
    id: $ElementType<Scalars, 'ID'>,
    scheme?: ?$ElementType<Scalars, 'JSONString'>,
    recordType?: ?$ElementType<Scalars, 'String'>,
    direction?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type CashJournalLineEdge = {|
  __typename?: 'CashJournalLineEdge',
  node?: ?CashJournalLineNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type CashJournalLineNode = {|
  ...Node,
  ...{|
    __typename?: 'CashJournalLineNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    dailyCashJournal: DailyCashJournalNode,
    data: $ElementType<Scalars, 'JSONString'>,
    recordType: $ElementType<Scalars, 'String'>,
    amount?: ?$ElementType<Scalars, 'Float'>,
    description?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type CashJournalLineNodeConnection = {|
  __typename?: 'CashJournalLineNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?CashJournalLineNodeEdge>,
|};

export type CashJournalLineNodeEdge = {|
  __typename?: 'CashJournalLineNodeEdge',
  node?: ?CashJournalLineNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ChatMessageEdge = {|
  __typename?: 'ChatMessageEdge',
  node?: ?ChatMessageNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ChatMessageNode = {|
  ...Node,
  ...{|
    __typename?: 'ChatMessageNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    entityId: $ElementType<Scalars, 'UUID'>,
    business: BusinessNode,
    message: $ElementType<Scalars, 'String'>,
    senderType: ChatMessageSenderType,
    isRead: $ElementType<Scalars, 'Boolean'>,
    userName?: ?$ElementType<Scalars, 'String'>,
    isMerchantMessage?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type ChatMessageNodeConnection = {|
  __typename?: 'ChatMessageNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?ChatMessageNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type ChatMessageNodeEdge = {|
  __typename?: 'ChatMessageNodeEdge',
  node?: ?ChatMessageNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const ChatMessageSenderTypeValues = Object.freeze({
  Merchant: 'MERCHANT',
  TaxAdviser: 'TAX_ADVISER',
  Zeitgold: 'ZEITGOLD',
});

export type ChatMessageSenderType = $Values<typeof ChatMessageSenderTypeValues>;

export type ClientProfileOtherFieldsMutationInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  taxPaymentMethod?: ?$ElementType<Scalars, 'String'>,
  businessOwners?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ClientProfileOtherFieldsMutationPayload = {|
  __typename?: 'ClientProfileOtherFieldsMutationPayload',
  businessAccounting?: ?BusinessAccountingNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type CommitBookkeepingExport = {|
  __typename?: 'CommitBookkeepingExport',
  exports?: ?Array<?BookkeepingExportNode>,
|};

export type ConnectSupplierToApiInput = {|
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  loginDetails?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ConnectSupplierToApiPayload = {|
  __typename?: 'ConnectSupplierToApiPayload',
  supplier?: ?CounterPartyNode,
  errorMsg?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ContractActivityNode = {|
  ...Node,
  ...{|
    __typename?: 'ContractActivityNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    contract: ContractNode,
    payable: PayableNode,
    contractPaymentDetailsId?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type ContractActivityNodeConnection = {|
  __typename?: 'ContractActivityNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?ContractActivityNodeEdge>,
|};

export type ContractActivityNodeEdge = {|
  __typename?: 'ContractActivityNodeEdge',
  node?: ?ContractActivityNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const ContractBookingTemplateAmountCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type ContractBookingTemplateAmountCurrency = $Values<typeof ContractBookingTemplateAmountCurrencyValues>;

export type ContractBookingTemplateNode = {|
  ...Node,
  ...{|
    __typename?: 'ContractBookingTemplateNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    contract: ContractNode,
    contractPaymentDetailsId?: ?$ElementType<Scalars, 'String'>,
    scenario: BookingScenarioNode,
    vatRate?: ?$ElementType<Scalars, 'String'>,
    amountCurrency: ContractBookingTemplateAmountCurrency,
    amount: $ElementType<Scalars, 'Float'>,
    predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  |},
|};

export type ContractBookingTemplateNodeConnection = {|
  __typename?: 'ContractBookingTemplateNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?ContractBookingTemplateNodeEdge>,
|};

export type ContractBookingTemplateNodeEdge = {|
  __typename?: 'ContractBookingTemplateNodeEdge',
  node?: ?ContractBookingTemplateNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ContractNode = {|
  ...Node,
  ...{|
    __typename?: 'ContractNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    issueDate: $ElementType<Scalars, 'Date'>,
    counterParty: CounterPartyNode,
    business: BusinessNode,
    records?: ?RecordNodeConnection,
    contractNumber?: ?$ElementType<Scalars, 'String'>,
    shouldPay: $ElementType<Scalars, 'Boolean'>,
    terminationReason?: ?$ElementType<Scalars, 'String'>,
    customAmounts?: ?$ElementType<Scalars, 'JSONString'>,
    recurringPayments: $ElementType<Scalars, 'JSONString'>,
    loanStartDate?: ?$ElementType<Scalars, 'Date'>,
    yearlyInterestRatePercent?: ?$ElementType<Scalars, 'Float'>,
    contractActivities?: ?ContractActivityNodeConnection,
    bookingTemplates: ContractBookingTemplateNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    issues?: ?IssueNodeConnection,
    contractType?: ?$ElementType<Scalars, 'String'>,
    payablesOpenIssues?: ?IssueNodeConnection,
    record?: ?RecordNode,
  |},
|};

export type ContractNodeRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type ContractNodeContractActivitiesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  payable_DateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  payable_DateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  payable_DateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type ContractNodeBookingTemplatesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type ContractNodeIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  missingRecurrentDocument_DocumentType?: ?$ElementType<Scalars, 'String'>,
  missingRecurrentDocument_DocumentType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasBookingReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type ContractNodePayablesOpenIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  missingRecurrentDocument_DocumentType?: ?$ElementType<Scalars, 'String'>,
  missingRecurrentDocument_DocumentType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasBookingReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type ContractNodeConnection = {|
  __typename?: 'ContractNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?ContractNodeEdge>,
|};

export type ContractNodeEdge = {|
  __typename?: 'ContractNodeEdge',
  node?: ?ContractNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type CounterPartyEdge = {|
  __typename?: 'CounterPartyEdge',
  node?: ?CounterPartyNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type CounterPartyNode = {|
  ...Node,
  ...{|
    __typename?: 'CounterPartyNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    businesscustomer?: ?BusinessCustomerNode,
    businessemployee?: ?BusinessEmployeeNode,
    businesssupplier?: ?BusinessSupplierNode,
    businesspaymentprovider?: ?BusinessPaymentProviderNode,
    records: RecordNodeConnection,
    businessTransactions: TransactionNodeConnection,
    contracts: ContractNodeConnection,
    displayName?: ?$ElementType<Scalars, 'String'>,
    legalName?: ?$ElementType<Scalars, 'String'>,
    name?: ?$ElementType<Scalars, 'String'>,
    counterPartyType?: ?$ElementType<Scalars, 'String'>,
    counterPartyModelType?: ?$ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    getmyinvoicesName?: ?$ElementType<Scalars, 'String'>,
    counterPartyCategory?: ?$ElementType<Scalars, 'String'>,
    getmyinvoicesScheme?: ?$ElementType<Scalars, 'JSONString'>,
    isGetmyinvoicesSupported?: ?$ElementType<Scalars, 'Boolean'>,
    businessSupplier?: ?BusinessSupplierNode,
  |},
|};

export type CounterPartyNodeRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type CounterPartyNodeBusinessTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type CounterPartyNodeContractsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  counterParty?: ?$ElementType<Scalars, 'ID'>,
|};

export type CounterPartyNodeConnection = {|
  __typename?: 'CounterPartyNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?CounterPartyNodeEdge>,
|};

export type CounterPartyNodeEdge = {|
  __typename?: 'CounterPartyNodeEdge',
  node?: ?CounterPartyNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type CreatePreliminaryPayslipsInput = {|
  employeeIds?: ?Array<?$ElementType<Scalars, 'String'>>,
  payrollDate?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type CreatePreliminaryPayslipsPayload = {|
  __typename?: 'CreatePreliminaryPayslipsPayload',
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DailyCashJournalNode = {|
  ...Node,
  ...{|
    __typename?: 'DailyCashJournalNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    date: $ElementType<Scalars, 'Date'>,
    startBalance: $ElementType<Scalars, 'Float'>,
    cashRegister: MerchantPaymentAccountNode,
    isSealed: $ElementType<Scalars, 'Boolean'>,
    document?: ?DocumentNode,
    runningNumber: $ElementType<Scalars, 'Int'>,
    countedCashBalance?: ?$ElementType<Scalars, 'Float'>,
    processed: $ElementType<Scalars, 'Boolean'>,
    lines?: ?CashJournalLineNodeConnection,
    currentBalance?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type DailyCashJournalNodeLinesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  dailyCashJournal?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type DailyCashJournalNodeConnection = {|
  __typename?: 'DailyCashJournalNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?DailyCashJournalNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type DailyCashJournalNodeEdge = {|
  __typename?: 'DailyCashJournalNodeEdge',
  node?: ?DailyCashJournalNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type DeleteBookingRuleInput = {|
  bookingRuleId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBookingRulePayload = {|
  __typename?: 'DeleteBookingRulePayload',
  bookingRule?: ?BookingRuleEdge,
  deletedBookingRuleId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBusinessEmployeeFutureTimeDependentDataInput = {|
  timeDependentDataId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBusinessEmployeeFutureTimeDependentDataPayload = {|
  __typename?: 'DeleteBusinessEmployeeFutureTimeDependentDataPayload',
  deletedTimeDependentDataId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBusinessEmployeeInput = {|
  employeeId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBusinessEmployeePayload = {|
  __typename?: 'DeleteBusinessEmployeePayload',
  deletedEmployeeId?: ?$ElementType<Scalars, 'String'>,
  business?: ?BusinessNode,
  employee?: ?BusinessEmployeeNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteCashJournalLineInput = {|
  cashJournalLineId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteCashJournalLinePayload = {|
  __typename?: 'DeleteCashJournalLinePayload',
  dailyCashJournal?: ?DailyCashJournalNode,
  deletedCashJournalLineId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteChatMessageInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  chatMessageId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteChatMessagePayload = {|
  __typename?: 'DeleteChatMessagePayload',
  chatMessage?: ?ChatMessageEdge,
  deletedChatMessageId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteEntityNoteInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteEntityNotePayload = {|
  __typename?: 'DeleteEntityNotePayload',
  deletedEntityNoteId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeletePaymentInput = {|
  paymentId: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeletePaymentPayload = {|
  __typename?: 'DeletePaymentPayload',
  eventPaymentAccount?: ?EventPaymentAccountNode,
  payment?: ?PaymentNodeEdge,
  deletedPaymentId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteTaskManagerTaskNote = {|
  __typename?: 'DeleteTaskManagerTaskNote',
  deletedTaskNoteId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteTodoListItemsInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  todoItemIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
  eventId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteTodoListItemsPayload = {|
  __typename?: 'DeleteTodoListItemsPayload',
  deletedTodoListItemsIds?: ?Array<?$ElementType<Scalars, 'String'>>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentAttachmentNode = {|
  ...Node,
  ...{|
    __typename?: 'DocumentAttachmentNode',
    id: $ElementType<Scalars, 'ID'>,
    attachmentType?: ?$ElementType<Scalars, 'String'>,
    document?: ?DocumentNode,
  |},
|};

export const DocumentCategoryValues = Object.freeze({
  Other: 'OTHER',
  PayableDocument: 'PAYABLE_DOCUMENT',
  Unknown: 'UNKNOWN',
});

export type DocumentCategory = $Values<typeof DocumentCategoryValues>;

export type DocumentConnection = {|
  __typename?: 'DocumentConnection',
  pageInfo: PageInfo,
  edges: Array<?DocumentEdge>,
|};

export type DocumentEdge = {|
  __typename?: 'DocumentEdge',
  node?: ?DocumentNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const DocumentHandlerTypesEnumValues = Object.freeze({
  SolarisBankAccountStatement: 'SolarisBankAccountStatement',
  BankPayment: 'BankPayment',
  ExpenseStatement: 'ExpenseStatement',
  MonthlyBusinessReportRecord: 'MonthlyBusinessReportRecord',
  NedVatDeclarationRecord: 'NedVatDeclarationRecord',
  OnboardingZreport: 'OnboardingZreport',
  Payslip: 'Payslip',
  RecordsWithBase64Pdf: 'RecordsWithBase64Pdf',
  ReplacementReceiptRecord: 'ReplacementReceiptRecord',
  VatDeclarationRecord: 'VatDeclarationRecord',
});

export type DocumentHandlerTypesEnum = $Values<typeof DocumentHandlerTypesEnumValues>;

export type DocumentLinkingDataNode = {|
  ...Node,
  ...{|
    __typename?: 'DocumentLinkingDataNode',
    id: $ElementType<Scalars, 'ID'>,
    dataType?: ?$ElementType<Scalars, 'String'>,
    amount?: ?$ElementType<Scalars, 'Float'>,
    reference?: ?$ElementType<Scalars, 'String'>,
    document?: ?DocumentNode,
  |},
|};

export type DocumentNode = {|
  ...Node,
  ...{|
    __typename?: 'DocumentNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    ocrText?: ?$ElementType<Scalars, 'String'>,
    category: DocumentCategory,
    inputChannel?: ?$ElementType<Scalars, 'String'>,
    processingType: DocumentProcessingType,
    processingStatus: DocumentProcessingStatus,
    entranceDate: $ElementType<Scalars, 'DateTime'>,
    exportId?: ?$ElementType<Scalars, 'UUID'>,
    documentType?: ?$ElementType<Scalars, 'String'>,
    documentSubType?: ?$ElementType<Scalars, 'String'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    duplicationHash?: ?$ElementType<Scalars, 'String'>,
    merchantAttachedToTransactions: TransactionNodeConnection,
    fileMetadata?: ?$ElementType<Scalars, 'JSONString'>,
    originalDocument?: ?DocumentNode,
    domain?: ?$ElementType<Scalars, 'String'>,
    supplier?: ?SupplierNode,
    duplicateDocuments: DocumentNodeConnection,
    records: RecordNodeConnection,
    expenseStatementDocument?: ?ExpenseStatementToDocumentNode,
    dailycashjournalSet: DailyCashJournalNodeConnection,
    todoListItems: TodoListItemNodeConnection,
    bwaDocument?: ?BusinessOnboardingNode,
    zreportDocument?: ?BusinessOnboardingNode,
    uid?: ?$ElementType<Scalars, 'String'>,
    fullUrl?: ?$ElementType<Scalars, 'String'>,
    thumbnailUrl?: ?$ElementType<Scalars, 'String'>,
    bigThumbnailUrl?: ?$ElementType<Scalars, 'String'>,
    ownership?: ?$ElementType<Scalars, 'String'>,
    taskActions?: ?TaskActionNodeConnection,
    requiredScheme?: ?$ElementType<Scalars, 'JSONString'>,
    requiredSchemeForDocument?: ?$ElementType<Scalars, 'JSONString'>,
    fullSchemeForDocument?: ?$ElementType<Scalars, 'JSONString'>,
    paymentStatus?: ?$ElementType<Scalars, 'String'>,
    shouldExtract?: ?$ElementType<Scalars, 'Boolean'>,
    badInputRecordType?: ?$ElementType<Scalars, 'String'>,
    badInputRecordData?: ?$ElementType<Scalars, 'JSONString'>,
    statementBankAccount?: ?MerchantPaymentAccountNode,
    documentOrigin?: ?$ElementType<Scalars, 'String'>,
    counterParty?: ?CounterPartyNode,
    mainRecordData?: ?$ElementType<Scalars, 'JSONString'>,
    bookingRecommendations?: ?Array<?BookkeepingRecommendationNode>,
    isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
    isReadyForBooking?: ?$ElementType<Scalars, 'Boolean'>,
    complianceIssues?: ?Array<?$ElementType<Scalars, 'String'>>,
    bookings?: ?BookingsInfoNode,
    isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
    event?: ?EventNode,
    extractedFields?: ?$ElementType<Scalars, 'JSONString'>,
    onlineExtractionData?: ?$ElementType<Scalars, 'JSONString'>,
    fieldSources?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type DocumentNodeMerchantAttachedToTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodeDuplicateDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodeDailycashjournalSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  date?: ?$ElementType<Scalars, 'Date'>,
|};

export type DocumentNodeTodoListItemsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeRequiredSchemeArgs = {|
  documentType?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeBookingRecommendationsArgs = {|
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeOnlineExtractionDataArgs = {|
  classifyDocument?: ?$ElementType<Scalars, 'Boolean'>,
  extractFields?: ?$ElementType<Scalars, 'Boolean'>,
  returnOcrText?: ?$ElementType<Scalars, 'Boolean'>,
  documentTypesToExtract?: ?Array<?$ElementType<Scalars, 'String'>>,
  userSelectedDocumentType?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeConnection = {|
  __typename?: 'DocumentNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?DocumentNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodeEdge = {|
  __typename?: 'DocumentNodeEdge',
  node?: ?DocumentNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type DocumentPackageItemNode = {|
  ...Node,
  ...{|
    __typename?: 'DocumentPackageItemNode',
    id: $ElementType<Scalars, 'ID'>,
    documentPackageType?: ?$ElementType<Scalars, 'String'>,
    value?: ?$ElementType<Scalars, 'Float'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export const DocumentProcessingStatusValues = Object.freeze({
  ReadyForExport: 'READY_FOR_EXPORT',
  SystemProcessing: 'SYSTEM_PROCESSING',
  WaitingForInput: 'WAITING_FOR_INPUT',
});

export type DocumentProcessingStatus = $Values<typeof DocumentProcessingStatusValues>;

export const DocumentProcessingTypeValues = Object.freeze({
  FullProcessing: 'FULL_PROCESSING',
  NoProcessing: 'NO_PROCESSING',
  PartialProcessing: 'PARTIAL_PROCESSING',
  Unknown: 'UNKNOWN',
});

export type DocumentProcessingType = $Values<typeof DocumentProcessingTypeValues>;

export type DocumentUpdatedSubscription = {|
  __typename?: 'DocumentUpdatedSubscription',
  updatedDocument?: ?UpdatedDocumentNode,
|};

export type DynamicRecordTypeNode = {|
  ...Node,
  ...{|
    __typename?: 'DynamicRecordTypeNode',
    id: $ElementType<Scalars, 'ID'>,
    recordName?: ?$ElementType<Scalars, 'String'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    ownership: DynamicRecordTypeOwnership,
  |},
|};

export type DynamicRecordTypeNodeConnection = {|
  __typename?: 'DynamicRecordTypeNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?DynamicRecordTypeNodeEdge>,
|};

export type DynamicRecordTypeNodeEdge = {|
  __typename?: 'DynamicRecordTypeNodeEdge',
  node?: ?DynamicRecordTypeNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const DynamicRecordTypeOwnershipValues = Object.freeze({
  Pioneer: 'PIONEER',
  Tan: 'TAN',
  Zeitgold: 'ZEITGOLD',
});

export type DynamicRecordTypeOwnership = $Values<typeof DynamicRecordTypeOwnershipValues>;

export type EmployeeMonthlyUpdateNode = {|
  ...Node,
  ...{|
    __typename?: 'EmployeeMonthlyUpdateNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    month: $ElementType<Scalars, 'Date'>,
    employee: BusinessEmployeeNode,
    hoursAmount?: ?$ElementType<Scalars, 'Float'>,
    extraInformation?: ?$ElementType<Scalars, 'String'>,
    additionalBenefits?: ?$ElementType<Scalars, 'JSONString'>,
    status?: ?EmployeeMonthlyUpdateStatus,
  |},
|};

export type EmployeeMonthlyUpdateNodeConnection = {|
  __typename?: 'EmployeeMonthlyUpdateNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?EmployeeMonthlyUpdateNodeEdge>,
|};

export type EmployeeMonthlyUpdateNodeEdge = {|
  __typename?: 'EmployeeMonthlyUpdateNodeEdge',
  node?: ?EmployeeMonthlyUpdateNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const EmployeeMonthlyUpdateStatusValues = Object.freeze({
  Hourly: 'HOURLY',
  Monthly: 'MONTHLY',
  NotOnboarded: 'NOT_ONBOARDED',
});

export type EmployeeMonthlyUpdateStatus = $Values<typeof EmployeeMonthlyUpdateStatusValues>;

export type EntityCreatorUserNode = {|
  ...Node,
  ...{|
    __typename?: 'EntityCreatorUserNode',
    id: $ElementType<Scalars, 'ID'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    group?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type EntityNode =
  | PaymentNode
  | PayableNode
  | ContractNode
  | TransactionNode
  | TodoListItemNode
  | MerchantPaymentAccountNode
  | DocumentNode;

export type EntityNoteNode = {|
  ...Node,
  ...{|
    __typename?: 'EntityNoteNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business?: ?BusinessNode,
    sourceEntityId: $ElementType<Scalars, 'UUID'>,
    note: $ElementType<Scalars, 'String'>,
    noteContext: EntityNoteNoteContext,
  |},
|};

export type EntityNoteNodeConnection = {|
  __typename?: 'EntityNoteNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?EntityNoteNodeEdge>,
|};

export type EntityNoteNodeEdge = {|
  __typename?: 'EntityNoteNodeEdge',
  node?: ?EntityNoteNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const EntityNoteNoteContextValues = Object.freeze({
  BookkeeperMarkedDocumentReady: 'BOOKKEEPER_MARKED_DOCUMENT_READY',
  BookkeeperMarkedPaymentDone: 'BOOKKEEPER_MARKED_PAYMENT_DONE',
  NedAreTransactionsBeforeOnboardingAnalysed: 'NED_ARE_TRANSACTIONS_BEFORE_ONBOARDING_ANALYSED',
  OposHandledInDatev: 'OPOS_HANDLED_IN_DATEV',
  RecordExtractionApprovedByAccounting: 'RECORD_EXTRACTION_APPROVED_BY_ACCOUNTING',
  RecordExtractionVerified: 'RECORD_EXTRACTION_VERIFIED',
  BookingTasks: 'BOOKING_TASKS',
  DjangoAdmin: 'DJANGO_ADMIN',
  TaxAdvisorClientContractSigned: 'TAX_ADVISOR_CLIENT_CONTRACT_SIGNED',
  TaxAdvisorCompletedOnboardingCall: 'TAX_ADVISOR_COMPLETED_ONBOARDING_CALL',
  TaxAdvisorClientProfile: 'TAX_ADVISOR_CLIENT_PROFILE',
});

export type EntityNoteNoteContext = $Values<typeof EntityNoteNoteContextValues>;

export type EventConnection = {|
  __typename?: 'EventConnection',
  pageInfo: PageInfo,
  edges: Array<?EventEdge>,
|};

export type EventEdge = {|
  __typename?: 'EventEdge',
  node?: ?EventNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type EventNode = {|
  ...Node,
  ...{|
    __typename?: 'EventNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    issuePtr: IssueNode,
    issueType?: ?$ElementType<Scalars, 'String'>,
    issueKey?: ?$ElementType<Scalars, 'JSONString'>,
    sourceEntityId: $ElementType<Scalars, 'UUID'>,
    isOpen: $ElementType<Scalars, 'Boolean'>,
    business: BusinessNode,
    closedAt?: ?$ElementType<Scalars, 'DateTime'>,
    stepName?: ?$ElementType<Scalars, 'String'>,
    occurrenceDate: $ElementType<Scalars, 'Date'>,
    relevantFrameStartTime: $ElementType<Scalars, 'Date'>,
    relevantFrameEndTime: $ElementType<Scalars, 'Date'>,
    recurrence?: ?EventRecurrence,
    issues: IssueNodeConnection,
    todoListItems?: ?TodoListItemNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    tasks?: ?TaskManagerTaskConnection,
    documents?: ?DocumentNodeConnection,
    payments?: ?PaymentNodeConnection,
    eventPaymentAccounts?: ?EventPaymentAccountConnection,
    payables?: ?PayableNodeConnection,
    unbookedDocumentsCount?: ?$ElementType<Scalars, 'Int'>,
    bookingSuggestionsCount?: ?$ElementType<Scalars, 'Int'>,
    exportPreviewLink?: ?$ElementType<Scalars, 'String'>,
    generateExportPreview?: ?$ElementType<Scalars, 'String'>,
    totalPaymentsInProcessingCount?: ?$ElementType<Scalars, 'Int'>,
    paymentsCount?: ?$ElementType<Scalars, 'Int'>,
    documentsCount?: ?$ElementType<Scalars, 'Int'>,
    merchantTasks?: ?TaskManagerTaskConnection,
    openMerchantTasksCount?: ?$ElementType<Scalars, 'Int'>,
    merchantPendingIssues?: ?$ElementType<Scalars, 'Int'>,
    eventSummary?: ?$ElementType<Scalars, 'JSONString'>,
    vatHistory?: ?$ElementType<Scalars, 'JSONString'>,
    merchantMonthEndClosingSummary?: ?MonthEndClosingSummaryNode,
    bookkeepingProgress?: ?BookkeepingProgressNode,
    monthEndClosingDataCompleteness?: ?BookkeepingProgressNode,
    merchantUrgencyLevel?: ?$ElementType<Scalars, 'String'>,
    taskActions?: ?TaskActionNodeConnection,
    vatReportedTimePeriod?: ?$ElementType<Scalars, 'String'>,
    bwaReportedMonth?: ?$ElementType<Scalars, 'String'>,
    isSkipped?: ?$ElementType<Scalars, 'Boolean'>,
    isDone?: ?$ElementType<Scalars, 'Boolean'>,
    spawn?: ?EventNode,
  |},
|};

export type EventNodeIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  id_In?: ?$ElementType<Scalars, 'UUID'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  missingRecurrentDocument_DocumentType?: ?$ElementType<Scalars, 'String'>,
  missingRecurrentDocument_DocumentType_In?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodeTodoListItemsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  date_Year?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Month?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isBlocker?: ?$ElementType<Scalars, 'Boolean'>,
  type?: ?$ElementType<Scalars, 'String'>,
  type_In?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  status_In?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodeTasksArgs = {|
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type EventNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_In?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  processingStatus?: ?$ElementType<Scalars, 'String'>,
  processingStatus_In?: ?$ElementType<Scalars, 'String'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
  inputChannel_In?: ?$ElementType<Scalars, 'String'>,
  records_Payables_Direction?: ?$ElementType<Scalars, 'String'>,
  records_OccurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  searchElasticsearch?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  unextracted?: ?$ElementType<Scalars, 'Boolean'>,
  unprocessed?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  transactionTaskActionName?: ?$ElementType<Scalars, 'String'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  correctionNeeded?: ?$ElementType<Scalars, 'Boolean'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  inputChannelByNames?: ?$ElementType<Scalars, 'String'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  issueDateFrom?: ?$ElementType<Scalars, 'String'>,
  issueDateTo?: ?$ElementType<Scalars, 'String'>,
  prefetchPayableCounterParty?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  onlyMissingMainPayable?: ?$ElementType<Scalars, 'Boolean'>,
  bookkeepingPaymentsPerMonth?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBreakdownDerivedPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBreakdownOriginalPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeCashRevenuePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
  isExplained?: ?$ElementType<Scalars, 'Boolean'>,
  isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type EventNodeEventPaymentAccountsArgs = {|
  accountType_In?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type EventNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  dateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type EventNodePaymentsCountArgs = {|
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodeDocumentsCountArgs = {|
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodeMerchantTasksArgs = {|
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type EventNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodeSpawnArgs = {|
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type EventNodeConnection = {|
  __typename?: 'EventNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?EventNodeEdge>,
|};

export type EventNodeEdge = {|
  __typename?: 'EventNodeEdge',
  node?: ?EventNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type EventPaymentAccountConnection = {|
  __typename?: 'EventPaymentAccountConnection',
  pageInfo: PageInfo,
  edges: Array<?EventPaymentAccountEdge>,
|};

export type EventPaymentAccountEdge = {|
  __typename?: 'EventPaymentAccountEdge',
  node?: ?EventPaymentAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type EventPaymentAccountNode = {|
  __typename?: 'EventPaymentAccountNode',
  id: $ElementType<Scalars, 'ID'>,
  paymentAccount?: ?MerchantPaymentAccountNode,
  event?: ?EventNode,
  eventBalanceHistory?: ?Array<?BalanceHistoryNode>,
  eventPayments?: ?PaymentNodeConnection,
  totalPaymentsDirectedAmount?: ?$ElementType<Scalars, 'Float'>,
|};

export type EventPaymentAccountNodeEventBalanceHistoryArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
  toleranceInDays?: ?$ElementType<Scalars, 'Int'>,
|};

export type EventPaymentAccountNodeEventPaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  onlyMissingMainPayable?: ?$ElementType<Scalars, 'Boolean'>,
  bookkeepingPaymentsPerMonth?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBreakdownDerivedPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBreakdownOriginalPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeCashRevenuePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
  isExplained?: ?$ElementType<Scalars, 'Boolean'>,
  isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
|};

export const EventRecurrenceValues = Object.freeze({
  Daily: 'DAILY',
  Hourly: 'HOURLY',
  Monthly: 'MONTHLY',
  Weekly: 'WEEKLY',
  Yearly: 'YEARLY',
});

export type EventRecurrence = $Values<typeof EventRecurrenceValues>;

export type EventType = {|
  __typename?: 'EventType',
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type ExpenseStatementNode = {|
  ...Node,
  ...{|
    __typename?: 'ExpenseStatementNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    employee?: ?BusinessEmployeeNode,
    isMarkedAsPaid: $ElementType<Scalars, 'Boolean'>,
    businessTransaction?: ?TransactionNode,
    expenseStatementToDocuments: ExpenseStatementToDocumentNodeConnection,
  |},
|};

export type ExpenseStatementNodeExpenseStatementToDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type ExpenseStatementNodeConnection = {|
  __typename?: 'ExpenseStatementNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?ExpenseStatementNodeEdge>,
|};

export type ExpenseStatementNodeEdge = {|
  __typename?: 'ExpenseStatementNodeEdge',
  node?: ?ExpenseStatementNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ExpenseStatementToDocumentNode = {|
  ...Node,
  ...{|
    __typename?: 'ExpenseStatementToDocumentNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    expenseStatement: ExpenseStatementNode,
    document: DocumentNode,
  |},
|};

export type ExpenseStatementToDocumentNodeConnection = {|
  __typename?: 'ExpenseStatementToDocumentNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?ExpenseStatementToDocumentNodeEdge>,
|};

export type ExpenseStatementToDocumentNodeEdge = {|
  __typename?: 'ExpenseStatementToDocumentNodeEdge',
  node?: ?ExpenseStatementToDocumentNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const FeedLogLogTypeValues = Object.freeze({
  AccountCaptionsDownloaded: 'ACCOUNT_CAPTIONS_DOWNLOADED',
  ActivationEmailSentToClient: 'ACTIVATION_EMAIL_SENT_TO_CLIENT',
  BookingExportDownloaded: 'BOOKING_EXPORT_DOWNLOADED',
  BookingExportReadyForDownload: 'BOOKING_EXPORT_READY_FOR_DOWNLOAD',
  ForceExportRequested: 'FORCE_EXPORT_REQUESTED',
  MonthlyBookkeepingProcessClosed: 'MONTHLY_BOOKKEEPING_PROCESS_CLOSED',
  MonthlyBookkeepingProcessStarted: 'MONTHLY_BOOKKEEPING_PROCESS_STARTED',
  MonthlyExportBookingsCsvDownloaded: 'MONTHLY_EXPORT_BOOKINGS_CSV_DOWNLOADED',
  MonthlyExportBookingsZipDownloaded: 'MONTHLY_EXPORT_BOOKINGS_ZIP_DOWNLOADED',
  MonthlyExportUnbookedDocumentsZipDownloaded: 'MONTHLY_EXPORT_UNBOOKED_DOCUMENTS_ZIP_DOWNLOADED',
  TaDeadlineExtended: 'TA_DEADLINE_EXTENDED',
  TodoListPublished: 'TODO_LIST_PUBLISHED',
  TodoListReceivedBack: 'TODO_LIST_RECEIVED_BACK',
});

export type FeedLogLogType = $Values<typeof FeedLogLogTypeValues>;

export type FeedLogNode = {|
  ...Node,
  ...{|
    __typename?: 'FeedLogNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    metadata?: ?$ElementType<Scalars, 'JSONString'>,
    logType: FeedLogLogType,
    userEmail?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type FeedLogNodeConnection = {|
  __typename?: 'FeedLogNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?FeedLogNodeEdge>,
|};

export type FeedLogNodeEdge = {|
  __typename?: 'FeedLogNodeEdge',
  node?: ?FeedLogNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type FinancialAdviceNode = {|
  ...Node,
  ...{|
    __typename?: 'FinancialAdviceNode',
    id: $ElementType<Scalars, 'ID'>,
    monthlyCostByOfferedGoods?: ?Array<?OfferedGoodsPerMonthNode>,
    monthlySupplierOfferedGoodsBreakdown?: ?Array<?SupplierOfferedGoodsPerMonthNode>,
    calculatePayablesAmount?: ?$ElementType<Scalars, 'Float'>,
    payablesToPay?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    needToBePaidPayables?: ?FinancialAdvicePayableNodeConnection,
    payablesToReceive?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    costsBreakdownByMonths?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalCostsByMonths?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalGrossRevenuesByMonthAndVatRate?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalNetRevenuesByMonth?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalNetRevenuesByMonthAndVatRate?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalGrossRevenuesByMonth?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalEarningsByMonth?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    totalExpensesByMonth?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    bookingExportsMonthRange?: ?Array<?$ElementType<Scalars, 'String'>>,
  |},
|};

export type FinancialAdviceNodeMonthlyCostByOfferedGoodsArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeMonthlySupplierOfferedGoodsBreakdownArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeCalculatePayablesAmountArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  label?: ?$ElementType<Scalars, 'String'>,
|};

export type FinancialAdviceNodePayablesToPayArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeNeedToBePaidPayablesArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type FinancialAdviceNodePayablesToReceiveArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeCostsBreakdownByMonthsArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalCostsByMonthsArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalGrossRevenuesByMonthAndVatRateArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalNetRevenuesByMonthArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalNetRevenuesByMonthAndVatRateArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalGrossRevenuesByMonthArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalEarningsByMonthArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdviceNodeTotalExpensesByMonthArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type FinancialAdvicePayableNode = {|
  __typename?: 'FinancialAdvicePayableNode',
  id?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  employeeName?: ?$ElementType<Scalars, 'String'>,
  directedAmount?: ?$ElementType<Scalars, 'Float'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  label?: ?$ElementType<Scalars, 'String'>,
  unpaidIssueId?: ?$ElementType<Scalars, 'String'>,
  missingPaymentIssueId?: ?$ElementType<Scalars, 'String'>,
  payable?: ?PayableNode,
|};

export type FinancialAdvicePayableNodeConnection = {|
  __typename?: 'FinancialAdvicePayableNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?FinancialAdvicePayableNodeEdge>,
|};

export type FinancialAdvicePayableNodeEdge = {|
  __typename?: 'FinancialAdvicePayableNodeEdge',
  node?: ?FinancialAdvicePayableNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type FinishDailyCashJournalInput = {|
  dailyCashJournalId?: ?$ElementType<Scalars, 'String'>,
  lastClosingDate?: ?$ElementType<Scalars, 'DateTime'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type FinishDailyCashJournalPayload = {|
  __typename?: 'FinishDailyCashJournalPayload',
  dailyCashJournal?: ?DailyCashJournalNode,
  business?: ?BusinessNode,
  cashRegister?: ?MerchantPaymentAccountNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type GenerateMerchantActivationMecEmail = {|
  __typename?: 'GenerateMerchantActivationMecEmail',
  emailText?: ?$ElementType<Scalars, 'String'>,
|};

export type GenerateNedMerchantActivationMecEmailInput = {|
  eventId?: ?$ElementType<Scalars, 'String'>,
  missingCashJournals?: ?$ElementType<Scalars, 'JSONString'>,
  missingBankAccountStatements?: ?$ElementType<Scalars, 'JSONString'>,
  existingZreportsCount?: ?$ElementType<Scalars, 'Int'>,
  missingCreditCardStatements?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type GenerateNedMerchantActivationMecEmailPayload = {|
  __typename?: 'GenerateNedMerchantActivationMecEmailPayload',
  emailText?: ?$ElementType<Scalars, 'String'>,
  contactName?: ?$ElementType<Scalars, 'String'>,
  emailAddress?: ?$ElementType<Scalars, 'String'>,
  subject?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ImportEmployeeDataFromDatev = {|
  __typename?: 'ImportEmployeeDataFromDatev',
  business?: ?BusinessNode,
|};

export type IntroduceAccountBalance = {|
  __typename?: 'IntroduceAccountBalance',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
|};

export type IntroduceAccountStatus = {|
  __typename?: 'IntroduceAccountStatus',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
|};

export type IntroduceActionInput = {|
  actionName?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type IntroduceAppUserInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  appScope?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAppUserPayload = {|
  __typename?: 'IntroduceAppUserPayload',
  appUser?: ?AppUserNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBackendTaskAction = {|
  __typename?: 'IntroduceBackendTaskAction',
  taskAction?: ?TaskActionNode,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBookingRuleInput = {|
  taxAdvisorBusinessGroupId?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  entityDirection?: ?$ElementType<Scalars, 'String'>,
  priority?: ?$ElementType<Scalars, 'Int'>,
  conditions?: ?Array<?BookingRuleConditionsInput>,
  action?: ?BookingRuleActionInput,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBookingRulePayload = {|
  __typename?: 'IntroduceBookingRulePayload',
  bookingRule?: ?BookingRuleEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessBookingAccountCaptionInput = {|
  datevAccountName?: ?$ElementType<Scalars, 'String'>,
  taxAdvisorId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessBookingAccountCaptionPayload = {|
  __typename?: 'IntroduceBusinessBookingAccountCaptionPayload',
  bookingAccountCaption?: ?BookingAccountCaptionEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessCustomer = {|
  __typename?: 'IntroduceBusinessCustomer',
  business?: ?BusinessNode,
  businessCustomer?: ?BusinessCustomerNode,
|};

export type IntroduceBusinessEmployeeInput = {|
  data?: ?$ElementType<Scalars, 'JSONString'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  isAlreadyExistEmployee?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessEmployeePayload = {|
  __typename?: 'IntroduceBusinessEmployeePayload',
  employee?: ?BusinessEmployeeNode,
  employeeEdge?: ?BusinessEmployeeEdge,
  employeeOnboardingTaskId?: ?$ElementType<Scalars, 'String'>,
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessPaymentProviderInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  displayName: $ElementType<Scalars, 'String'>,
  legalName: $ElementType<Scalars, 'String'>,
  isForCashRegister?: ?$ElementType<Scalars, 'Boolean'>,
  displayNameForCashRegister?: ?$ElementType<Scalars, 'String'>,
  activeSince?: ?$ElementType<Scalars, 'Date'>,
  activeUntil?: ?$ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessPaymentProviderPayload = {|
  __typename?: 'IntroduceBusinessPaymentProviderPayload',
  businessPaymentProvider?: ?BusinessPaymentProviderNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessSupplier = {|
  __typename?: 'IntroduceBusinessSupplier',
  business?: ?BusinessNode,
  businessSupplier?: ?BusinessSupplierNode,
|};

export type IntroduceCashJournalLineInput = {|
  data?: ?$ElementType<Scalars, 'JSONString'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  dailyCashJournalId?: ?$ElementType<Scalars, 'String'>,
  ignoreDuplicates?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCashJournalLinePayload = {|
  __typename?: 'IntroduceCashJournalLinePayload',
  dailyCashJournal?: ?DailyCashJournalNode,
  cashJournalLine?: ?CashJournalLineNode,
  cashJournalLineEdge?: ?CashJournalLineEdge,
  errorCode?: ?$ElementType<Scalars, 'String'>,
  duplicateCashJournalLine?: ?CashJournalLineNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceChatMessageInput = {|
  entityRelayId?: ?$ElementType<Scalars, 'String'>,
  message?: ?$ElementType<Scalars, 'String'>,
  isMerchantMessage?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceChatMessagePayload = {|
  __typename?: 'IntroduceChatMessagePayload',
  chatMessage?: ?ChatMessageEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceContractRecordLinking = {|
  __typename?: 'IntroduceContractRecordLinking',
  record?: ?RecordNode,
|};

export type IntroduceCountedCashBalanceInput = {|
  countedCashBalance?: ?$ElementType<Scalars, 'Float'>,
  dailyCashJournalId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCountedCashBalancePayload = {|
  __typename?: 'IntroduceCountedCashBalancePayload',
  dailyCashJournal?: ?DailyCashJournalNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCounterParty = {|
  __typename?: 'IntroduceCounterParty',
  counterParty?: ?CounterPartyNode,
|};

export type IntroduceCounterPartyFromUserInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCounterPartyFromUserPayload = {|
  __typename?: 'IntroduceCounterPartyFromUserPayload',
  counterParty?: ?CounterPartyEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCsvBankAccountStatementInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  accountId?: ?$ElementType<Scalars, 'String'>,
  csvBase64?: ?$ElementType<Scalars, 'String'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
  additionalData?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCsvBankAccountStatementPayload = {|
  __typename?: 'IntroduceCsvBankAccountStatementPayload',
  document?: ?DocumentNode,
  resultType?: ?$ElementType<Scalars, 'String'>,
  csvPaymentsCount?: ?$ElementType<Scalars, 'Int'>,
  newPaymentCount?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceDailyCashJournalInput = {|
  date?: ?$ElementType<Scalars, 'DateTime'>,
  startBalance?: ?$ElementType<Scalars, 'Float'>,
  cashRegisterId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceDailyCashJournalPayload = {|
  __typename?: 'IntroduceDailyCashJournalPayload',
  dailyCashJournal?: ?DailyCashJournalNode,
  cashRegister?: ?MerchantPaymentAccountNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceDocument = {|
  __typename?: 'IntroduceDocument',
  document?: ?DocumentNode,
|};

export type IntroduceDocumentOcrText = {|
  __typename?: 'IntroduceDocumentOcrText',
  document?: ?DocumentNode,
|};

export type IntroduceDocumentPaymentInstructionInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceDocumentPaymentInstructionPayload = {|
  __typename?: 'IntroduceDocumentPaymentInstructionPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceEmployeeMonthlyUpdateInput = {|
  employeeId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  month?: ?$ElementType<Scalars, 'DateTime'>,
  hoursReport?: ?$ElementType<Scalars, 'JSONString'>,
  extraInformation?: ?$ElementType<Scalars, 'JSONString'>,
  additionalBenefits?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceEmployeeMonthlyUpdatePayload = {|
  __typename?: 'IntroduceEmployeeMonthlyUpdatePayload',
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceEntityActionsInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  entityId?: ?$ElementType<Scalars, 'String'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  actions?: ?Array<?IntroduceActionInput>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceEntityActionsPayload = {|
  __typename?: 'IntroduceEntityActionsPayload',
  taskActions?: ?Array<?TaskActionNode>,
  entity?: ?EntityNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceEntityNote = {|
  __typename?: 'IntroduceEntityNote',
  entityNote?: ?EntityNoteNode,
|};

export type IntroduceExpenseStatementInput = {|
  employeeId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  documentsList?: ?$ElementType<Scalars, 'JSONString'>,
  isMarkedAsPaid?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceExpenseStatementPayload = {|
  __typename?: 'IntroduceExpenseStatementPayload',
  newExpenseStatement?: ?ExpenseStatementNode,
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceFeedLogInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  logType: $ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceFeedLogPayload = {|
  __typename?: 'IntroduceFeedLogPayload',
  feedLog?: ?FeedLogNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceFinApiAccountStatus = {|
  __typename?: 'IntroduceFinApiAccountStatus',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
|};

export type IntroduceHaasUser = {|
  __typename?: 'IntroduceHaasUser',
  error?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceIssueActionInput = {|
  issueId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceIssueActionPayload = {|
  __typename?: 'IntroduceIssueActionPayload',
  taskAction?: ?TaskActionNode,
  event?: ?EventNode,
  issue?: ?IssueNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceLastPaymentSyncDate = {|
  __typename?: 'IntroduceLastPaymentSyncDate',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
|};

export type IntroduceLinking = {|
  __typename?: 'IntroduceLinking',
  record?: ?RecordNode,
|};

export type IntroduceManualAccountBalanceInput = {|
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  balance?: ?$ElementType<Scalars, 'Float'>,
  balanceDate?: ?$ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceManualAccountBalancePayload = {|
  __typename?: 'IntroduceManualAccountBalancePayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  balanceHistory?: ?BalanceHistoryNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceMerchantPaymentAccountInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  accountType: $ElementType<Scalars, 'String'>,
  displayName: $ElementType<Scalars, 'String'>,
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  predefinedBookingAccount: $ElementType<Scalars, 'Int'>,
  activeSince?: ?$ElementType<Scalars, 'Date'>,
  activeUntil?: ?$ElementType<Scalars, 'Date'>,
  isCashRegisterPointOfSale?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceMerchantPaymentAccountPayload = {|
  __typename?: 'IntroduceMerchantPaymentAccountPayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceMerchantPaymentRequestInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  scannedDocumentDetails?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceMerchantPaymentRequestPayload = {|
  __typename?: 'IntroduceMerchantPaymentRequestPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNedEntityNoteInput = {|
  entityId: $ElementType<Scalars, 'String'>,
  entityType: $ElementType<Scalars, 'String'>,
  note: $ElementType<Scalars, 'String'>,
  noteContext: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNedEntityNotePayload = {|
  __typename?: 'IntroduceNedEntityNotePayload',
  entityNote?: ?EntityNoteNode,
  entity?: ?EntityNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNewStructuredDocument = {|
  __typename?: 'IntroduceNewStructuredDocument',
  document?: ?DocumentNode,
|};

export type IntroduceNewStructuredDocumentForRelayInput = {|
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  apiType?: ?$ElementType<Scalars, 'String'>,
  zeitgoldBusinessId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNewStructuredDocumentForRelayPayload = {|
  __typename?: 'IntroduceNewStructuredDocumentForRelayPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentIdentificationRuleForPaymentProviderInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  merchantPaymentAccountId: $ElementType<Scalars, 'String'>,
  paymentIdentificationRuleId?: ?$ElementType<Scalars, 'String'>,
  counterParty?: ?$ElementType<Scalars, 'String'>,
  keywords?: ?Array<?$ElementType<Scalars, 'String'>>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  bookingTextTemplate?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentIdentificationRuleForPaymentProviderPayload = {|
  __typename?: 'IntroducePaymentIdentificationRuleForPaymentProviderPayload',
  paymentIdentificationRule?: ?PaymentIdentificationRuleNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  paymentReference?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  counterPartyIban?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  counterPartyName?: ?$ElementType<Scalars, 'String'>,
  eventId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentPayload = {|
  __typename?: 'IntroducePaymentPayload',
  eventPaymentAccount?: ?EventPaymentAccountNode,
  paymentEdge?: ?PaymentNodeEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentPayloadPaymentEdgeArgs = {|
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceRecord = {|
  __typename?: 'IntroduceRecord',
  record?: ?RecordNode,
|};

export type IntroduceScenarioBookings = {|
  __typename?: 'IntroduceScenarioBookings',
  numOfBookings?: ?$ElementType<Scalars, 'Int'>,
|};

export type IntroduceSolarisAccountBalance = {|
  __typename?: 'IntroduceSolarisAccountBalance',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
|};

export type IntroduceSupplierInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  supplierId?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  supplierAccount?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSupplierPayload = {|
  __typename?: 'IntroduceSupplierPayload',
  supplier?: ?SupplierNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaskActionUserExplanation = {|
  __typename?: 'IntroduceTaskActionUserExplanation',
  userExplanationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaskManagerActionInput = {|
  task?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  ignoreWarning?: ?$ElementType<Scalars, 'Boolean'>,
  executionDurationSeconds?: ?$ElementType<Scalars, 'Float'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaskManagerActionPayload = {|
  __typename?: 'IntroduceTaskManagerActionPayload',
  taskAction?: ?TaskManagerTaskActionNode,
  business?: ?BusinessNode,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
  result?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  task?: ?TaskManagerTaskNode,
  finishedTestResult?: ?$ElementType<Scalars, 'Boolean'>,
  isExplanationRequested?: ?$ElementType<Scalars, 'Boolean'>,
  updatedTaskId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaskManagerTaskNote = {|
  __typename?: 'IntroduceTaskManagerTaskNote',
  taskNote?: ?TaskManagerTaskNoteNode,
|};

export type IntroduceTodoListSourceFileInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  eventId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  fileType?: ?TodoListSourceFileEnum,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTodoListSourceFilePayload = {|
  __typename?: 'IntroduceTodoListSourceFilePayload',
  todoListItems?: ?Array<?TodoListItemNode>,
  business?: ?BusinessNode,
  event?: ?EventNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTransactionActionInput = {|
  transactionId?: ?$ElementType<Scalars, 'String'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTransactionActionPayload = {|
  __typename?: 'IntroduceTransactionActionPayload',
  action?: ?TaskActionNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUserPendingCertification = {|
  __typename?: 'IntroduceUserPendingCertification',
  certificationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUserScannedDocumentInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  document?: ?$ElementType<Scalars, 'JSONString'>,
  documentType?: ?DocumentHandlerTypesEnum,
  uploadReason?: ?UploadReasonTypesEnum,
  uploadedFileName?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUserScannedDocumentPayload = {|
  __typename?: 'IntroduceUserScannedDocumentPayload',
  business?: ?BusinessNode,
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IssueNode = {|
  ...Node,
  ...{|
    __typename?: 'IssueNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    issueType?: ?$ElementType<Scalars, 'String'>,
    issueKey?: ?$ElementType<Scalars, 'JSONString'>,
    sourceEntityId: $ElementType<Scalars, 'UUID'>,
    isOpen: $ElementType<Scalars, 'Boolean'>,
    business: BusinessNode,
    closedAt?: ?$ElementType<Scalars, 'DateTime'>,
    stepName?: ?$ElementType<Scalars, 'String'>,
    events: EventNodeConnection,
    event?: ?EventNode,
    issueTaskActions: TaskActionNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    transaction?: ?TransactionNode,
    record?: ?RecordNode,
    merchantPaymentAccount?: ?MerchantPaymentAccountNode,
    businessEmployee?: ?BusinessEmployeeNode,
    payable?: ?PayableNode,
    payment?: ?PaymentNode,
    missingRecurrentDocument?: ?MissingRecurrentDocumentNode,
  |},
|};

export type IssueNodeEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type IssueNodeIssueTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type IssueNodeConnection = {|
  __typename?: 'IssueNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?IssueNodeEdge>,
|};

export type IssueNodeEdge = {|
  __typename?: 'IssueNodeEdge',
  node?: ?IssueNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type JsonSchemeNode = {|
  ...Node,
  ...{|
    __typename?: 'JsonSchemeNode',
    id: $ElementType<Scalars, 'ID'>,
    scheme?: ?$ElementType<Scalars, 'JSONString'>,
    name?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type LinearProgressItemNode = {|
  ...Node,
  ...{|
    __typename?: 'LinearProgressItemNode',
    id: $ElementType<Scalars, 'ID'>,
    value?: ?$ElementType<Scalars, 'Float'>,
    maxValue?: ?$ElementType<Scalars, 'Float'>,
    percent?: ?$ElementType<Scalars, 'Float'>,
    goalPercent?: ?$ElementType<Scalars, 'Float'>,
    entity?: ?EntityNode,
  |},
|};

export type LinkingDataUnionNode = TransactionLinkingDataNode | DocumentLinkingDataNode;

export type LinkingRecommendationNode = {|
  ...Node,
  ...{|
    __typename?: 'LinkingRecommendationNode',
    id: $ElementType<Scalars, 'ID'>,
    score?: ?$ElementType<Scalars, 'Float'>,
    transaction?: ?TransactionNode,
    linkingData?: ?LinkingDataUnionNode,
    linkingDatas?: ?Array<?LinkingDataUnionNode>,
    reasons?: ?Array<?$ElementType<Scalars, 'String'>>,
  |},
|};

export type MandatoryBookkeepingBusinessFieldsMutationInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  bookkeepingSpecification?: ?$ElementType<Scalars, 'String'>,
  bookingIndustry?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MandatoryBookkeepingBusinessFieldsMutationPayload = {|
  __typename?: 'MandatoryBookkeepingBusinessFieldsMutationPayload',
  businessAccounting?: ?BusinessAccountingNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkDocumentAsDuplicateInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  originalDocumentId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkDocumentAsDuplicatePayload = {|
  __typename?: 'MarkDocumentAsDuplicatePayload',
  document?: ?DocumentNode,
  originalDocument?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkDocumentAsIrrelevantInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  reason?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkDocumentAsIrrelevantPayload = {|
  __typename?: 'MarkDocumentAsIrrelevantPayload',
  document?: ?DocumentNode,
  markedIrrelevantDocumentId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkMessagesAsReadInput = {|
  entityRelayId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkMessagesAsReadPayload = {|
  __typename?: 'MarkMessagesAsReadPayload',
  unreadMessagesCount?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export const MerchantPaymentAccountBalanceCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type MerchantPaymentAccountBalanceCurrency = $Values<typeof MerchantPaymentAccountBalanceCurrencyValues>;

export const MerchantPaymentAccountCreditLineCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type MerchantPaymentAccountCreditLineCurrency = $Values<typeof MerchantPaymentAccountCreditLineCurrencyValues>;

export type MerchantPaymentAccountEdge = {|
  __typename?: 'MerchantPaymentAccountEdge',
  node?: ?MerchantPaymentAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MerchantPaymentAccountNode = {|
  ...Node,
  ...{|
    __typename?: 'MerchantPaymentAccountNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    createdByUser?: ?EntityCreatorUserNode,
    business: BusinessNode,
    accountType?: ?$ElementType<Scalars, 'String'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
    balanceDate?: ?$ElementType<Scalars, 'DateTime'>,
    balanceCurrency: MerchantPaymentAccountBalanceCurrency,
    balance?: ?$ElementType<Scalars, 'Float'>,
    creditLineCurrency: MerchantPaymentAccountCreditLineCurrency,
    creditLine: $ElementType<Scalars, 'Float'>,
    figoServiceName?: ?$ElementType<Scalars, 'String'>,
    figoAccountStatus?: ?$ElementType<Scalars, 'String'>,
    shouldTrySyncFinapi?: ?$ElementType<Scalars, 'Boolean'>,
    lastAutomaticPaymentSyncDate?: ?$ElementType<Scalars, 'Date'>,
    finapiAccountStatus?: ?$ElementType<Scalars, 'String'>,
    isPoweredBySolaris: $ElementType<Scalars, 'Boolean'>,
    isCashRegisterPointOfSale: $ElementType<Scalars, 'Boolean'>,
    activeSince?: ?$ElementType<Scalars, 'Date'>,
    activeUntil: $ElementType<Scalars, 'Date'>,
    bankCompany?: ?$ElementType<Scalars, 'String'>,
    businessByMainBankAccount: BusinessNodeConnection,
    paymentProvider?: ?BusinessPaymentProviderNode,
    balanceHistory?: ?Array<?BalanceHistoryNode>,
    payments?: ?PaymentNodeConnection,
    dailyCashJournals?: ?DailyCashJournalNodeConnection,
    paymentidentificationruleSet: PaymentIdentificationRuleNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    bankAccountCredentialsForm?: ?$ElementType<Scalars, 'JSONString'>,
    isActive?: ?$ElementType<Scalars, 'Boolean'>,
    isMainBankAccount?: ?$ElementType<Scalars, 'Boolean'>,
    supportedTanSchemes?: ?$ElementType<Scalars, 'JSONString'>,
    figoOnboardingDate?: ?$ElementType<Scalars, 'Date'>,
    lastPaymentSyncedDate?: ?$ElementType<Scalars, 'Date'>,
    paymentCompleteness?: ?PaymentCompletenessNode,
    averageMonthlyPaymentsByDirection?: ?$ElementType<Scalars, 'JSONString'>,
    monthEndBalance?: ?MonthEndBalancesNode,
    isPaypalAccount?: ?$ElementType<Scalars, 'Boolean'>,
    statementRecords?: ?Array<?BankStatementNode>,
    lastCsvPaymentDate?: ?$ElementType<Scalars, 'String'>,
    predefinedBookingAccountUpdateInProgress?: ?$ElementType<Scalars, 'Int'>,
    isCorrectionNeeded?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type MerchantPaymentAccountNodeBusinessByMainBankAccountArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MerchantPaymentAccountNodeBalanceHistoryArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
|};

export type MerchantPaymentAccountNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  onlyMissingMainPayable?: ?$ElementType<Scalars, 'Boolean'>,
  bookkeepingPaymentsPerMonth?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBreakdownDerivedPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBreakdownOriginalPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeCashRevenuePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
  isExplained?: ?$ElementType<Scalars, 'Boolean'>,
  isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type MerchantPaymentAccountNodeDailyCashJournalsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  date_Range?: ?$ElementType<Scalars, 'Date'>,
  date_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type MerchantPaymentAccountNodePaymentidentificationruleSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MerchantPaymentAccountNodePaymentCompletenessArgs = {|
  month?: ?$ElementType<Scalars, 'String'>,
|};

export type MerchantPaymentAccountNodeMonthEndBalanceArgs = {|
  month?: ?$ElementType<Scalars, 'Date'>,
|};

export type MerchantPaymentAccountNodeStatementRecordsArgs = {|
  month?: ?$ElementType<Scalars, 'String'>,
|};

export type MerchantPaymentAccountNodeLastCsvPaymentDateArgs = {|
  month?: ?$ElementType<Scalars, 'Date'>,
|};

export type MerchantPaymentAccountNodeConnection = {|
  __typename?: 'MerchantPaymentAccountNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?MerchantPaymentAccountNodeEdge>,
|};

export type MerchantPaymentAccountNodeEdge = {|
  __typename?: 'MerchantPaymentAccountNodeEdge',
  node?: ?MerchantPaymentAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MerchantTimelineItemNode = {|
  ...Node,
  ...{|
    __typename?: 'MerchantTimelineItemNode',
    id: $ElementType<Scalars, 'ID'>,
    date?: ?$ElementType<Scalars, 'DateTime'>,
    itemType?: ?$ElementType<Scalars, 'String'>,
    monthStart?: ?$ElementType<Scalars, 'DateTime'>,
  |},
|};

export type MergeTransactions = {|
  __typename?: 'MergeTransactions',
  transaction?: ?TransactionNode,
|};

export type MissingRecurrentDocumentNode = {|
  ...Node,
  ...{|
    __typename?: 'MissingRecurrentDocumentNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    startDate: $ElementType<Scalars, 'Date'>,
    endDate?: ?$ElementType<Scalars, 'Date'>,
    sourceEntityId: $ElementType<Scalars, 'UUID'>,
    expectedIdentifier?: ?$ElementType<Scalars, 'String'>,
    isOpen: $ElementType<Scalars, 'Boolean'>,
    documentType?: ?$ElementType<Scalars, 'String'>,
    closedAt?: ?$ElementType<Scalars, 'DateTime'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  |},
|};

export type MonthEndBalancesNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndBalancesNode',
    id: $ElementType<Scalars, 'ID'>,
    accountName?: ?$ElementType<Scalars, 'String'>,
    predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
    isFigoOnboarded?: ?$ElementType<Scalars, 'Boolean'>,
    sourceName?: ?$ElementType<Scalars, 'String'>,
    monthEndBalance?: ?AccountBalanceNode,
    monthStartBalance?: ?AccountBalanceNode,
    balanceDiff?: ?$ElementType<Scalars, 'String'>,
    bookingBalance?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type MonthEndClosingLinks = {|
  __typename?: 'MonthEndClosingLinks',
  bookkeepingExportPreview?: ?$ElementType<Scalars, 'String'>,
  exportedBookingsFile?: ?$ElementType<Scalars, 'String'>,
  exportedBookedDocumentsFile?: ?$ElementType<Scalars, 'String'>,
  exportedUnbookedDocumentsFile?: ?$ElementType<Scalars, 'String'>,
  exportedTodoListDocumentsFile?: ?$ElementType<Scalars, 'String'>,
  accountCaptionsFile?: ?$ElementType<Scalars, 'String'>,
|};

export type MonthEndClosingMissingBankAccountStatementsConnection = {|
  __typename?: 'MonthEndClosingMissingBankAccountStatementsConnection',
  pageInfo: PageInfo,
  edges: Array<?MonthEndClosingMissingBankAccountStatementsEdge>,
|};

export type MonthEndClosingMissingBankAccountStatementsEdge = {|
  __typename?: 'MonthEndClosingMissingBankAccountStatementsEdge',
  node?: ?MonthEndClosingMissingBankAccountStatementsNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MonthEndClosingMissingBankAccountStatementsNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingMissingBankAccountStatementsNode',
    id: $ElementType<Scalars, 'ID'>,
    bankAccount?: ?MerchantPaymentAccountNode,
    missingIntervals?: ?Array<?Array<?$ElementType<Scalars, 'String'>>>,
    issue?: ?IssueNode,
    dataCompletenessStatus?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type MonthEndClosingMissingCashJournalsConnection = {|
  __typename?: 'MonthEndClosingMissingCashJournalsConnection',
  pageInfo: PageInfo,
  edges: Array<?MonthEndClosingMissingCashJournalsEdge>,
|};

export type MonthEndClosingMissingCashJournalsEdge = {|
  __typename?: 'MonthEndClosingMissingCashJournalsEdge',
  node?: ?MonthEndClosingMissingCashJournalsNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MonthEndClosingMissingCashJournalsNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingMissingCashJournalsNode',
    id: $ElementType<Scalars, 'ID'>,
    cashRegister?: ?MerchantPaymentAccountNode,
    missingFromDate?: ?$ElementType<Scalars, 'String'>,
    missingUntilDate?: ?$ElementType<Scalars, 'String'>,
    count?: ?$ElementType<Scalars, 'Int'>,
    totalExpectedAmount?: ?$ElementType<Scalars, 'Int'>,
    dataCompletenessStatus?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type MonthEndClosingMissingCreditCardStatementsConnection = {|
  __typename?: 'MonthEndClosingMissingCreditCardStatementsConnection',
  pageInfo: PageInfo,
  edges: Array<?MonthEndClosingMissingCreditCardStatementsEdge>,
|};

export type MonthEndClosingMissingCreditCardStatementsEdge = {|
  __typename?: 'MonthEndClosingMissingCreditCardStatementsEdge',
  node?: ?MonthEndClosingMissingCreditCardStatementsNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MonthEndClosingMissingCreditCardStatementsNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingMissingCreditCardStatementsNode',
    id: $ElementType<Scalars, 'ID'>,
    creditCard?: ?MerchantPaymentAccountNode,
    isCreditCardStatementMissing?: ?$ElementType<Scalars, 'Boolean'>,
    dataCompletenessStatus?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type MonthEndClosingMissingRequiredDocumentsNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingMissingRequiredDocumentsNode',
    id: $ElementType<Scalars, 'ID'>,
    cashJournals?: ?MonthEndClosingMissingCashJournalsConnection,
    bankAccountStatements?: ?MonthEndClosingMissingBankAccountStatementsConnection,
    creditCardStatements?: ?MonthEndClosingMissingCreditCardStatementsConnection,
  |},
|};

export type MonthEndClosingMissingRequiredDocumentsNodeCashJournalsArgs = {|
  dataCompletenessStatus?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingMissingRequiredDocumentsNodeBankAccountStatementsArgs = {|
  dataCompletenessStatus?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingMissingRequiredDocumentsNodeCreditCardStatementsArgs = {|
  dataCompletenessStatus?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingSectionConnection = {|
  __typename?: 'MonthEndClosingSectionConnection',
  pageInfo: PageInfo,
  edges: Array<?MonthEndClosingSectionEdge>,
|};

export type MonthEndClosingSectionEdge = {|
  __typename?: 'MonthEndClosingSectionEdge',
  node?: ?MonthEndClosingSectionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MonthEndClosingSectionNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingSectionNode',
    id: $ElementType<Scalars, 'ID'>,
    name?: ?$ElementType<Scalars, 'String'>,
    subSections?: ?MonthEndClosingSubSectionConnection,
    isSealed?: ?$ElementType<Scalars, 'Boolean'>,
    isAboveThreshold?: ?$ElementType<Scalars, 'Boolean'>,
    value?: ?$ElementType<Scalars, 'Float'>,
    threshold?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type MonthEndClosingSectionNodeSubSectionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingSubSectionConnection = {|
  __typename?: 'MonthEndClosingSubSectionConnection',
  pageInfo: PageInfo,
  edges: Array<?MonthEndClosingSubSectionEdge>,
|};

export type MonthEndClosingSubSectionEdge = {|
  __typename?: 'MonthEndClosingSubSectionEdge',
  node?: ?MonthEndClosingSubSectionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MonthEndClosingSubSectionNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingSubSectionNode',
    id: $ElementType<Scalars, 'ID'>,
    name?: ?$ElementType<Scalars, 'String'>,
    savingAmount?: ?$ElementType<Scalars, 'Float'>,
    tasksCount?: ?$ElementType<Scalars, 'Int'>,
    tasks?: ?TaskManagerTaskConnection,
    monthEndClosingSummary?: ?MonthEndClosingSummaryNode,
  |},
|};

export type MonthEndClosingSubSectionNodeTasksArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingSummaryNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndClosingSummaryNode',
    id: $ElementType<Scalars, 'ID'>,
    sections?: ?MonthEndClosingSectionConnection,
    allSections?: ?MonthEndClosingSectionConnection,
    event?: ?EventConnection,
    bwa?: ?RecordNode,
    vatDeclaration?: ?RecordNode,
    estimatedVatToPay?: ?$ElementType<Scalars, 'Float'>,
    numberOfReconciledPayments?: ?$ElementType<Scalars, 'Int'>,
    numberOfUnreconciledPayments?: ?$ElementType<Scalars, 'Int'>,
    vatAmountCollected?: ?$ElementType<Scalars, 'Float'>,
    vatAmountDeducted?: ?$ElementType<Scalars, 'Float'>,
    isVatDeclarationMonth?: ?$ElementType<Scalars, 'Boolean'>,
    merchantDueDate?: ?$ElementType<Scalars, 'String'>,
    status?: ?$ElementType<Scalars, 'String'>,
    potentialVatSavingAmount?: ?$ElementType<Scalars, 'Float'>,
    missingRequiredDocuments?: ?MonthEndClosingMissingRequiredDocumentsNode,
    bookkeepingDistribution?: ?BookkeepingDistributionNode,
    links?: ?MonthEndClosingLinks,
    todoListDueDate?: ?$ElementType<Scalars, 'String'>,
    merchantIsBookkeepingPageLocked?: ?$ElementType<Scalars, 'Boolean'>,
    merchantBookkeepingPageLockDate?: ?$ElementType<Scalars, 'DateTime'>,
    hasActivePointOfSaleCashRegisterInMonth?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type MonthEndClosingSummaryNodeSectionsArgs = {|
  subSectionsNames?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingSummaryNodeAllSectionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MonthEndClosingSummaryNodeEventArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type Mutation = {|
  __typename?: 'Mutation',
  introduceRecord?: ?IntroduceRecord,
  introduceLinking?: ?IntroduceLinking,
  introduceContractRecordLinking?: ?IntroduceContractRecordLinking,
  mergeTransactions?: ?MergeTransactions,
  introduceCounterParty?: ?IntroduceCounterParty,
  introduceCounterPartyFromUser?: ?IntroduceCounterPartyFromUserPayload,
  addFigoAuthorization?: ?AddFigoAuthorizationPayload,
  introduceScenarioBookings?: ?IntroduceScenarioBookings,
  introduceTaskManagerTaskNote?: ?IntroduceTaskManagerTaskNote,
  deleteTaskManagerTaskNote?: ?DeleteTaskManagerTaskNote,
  introduceTaskManagerAction?: ?IntroduceTaskManagerActionPayload,
  introduceBackendTaskAction?: ?IntroduceBackendTaskAction,
  introduceIssueAction?: ?IntroduceIssueActionPayload,
  introduceEntityActions?: ?IntroduceEntityActionsPayload,
  onPaymentExecuted?: ?OnPaymentExecutedPayload,
  introduceUserPendingCertification?: ?IntroduceUserPendingCertification,
  introduceTaskActionUserExplanation?: ?IntroduceTaskActionUserExplanation,
  introduceDocument?: ?IntroduceDocument,
  introduceManualAccountBalance?: ?IntroduceManualAccountBalancePayload,
  introduceDocumentOcrText?: ?IntroduceDocumentOcrText,
  introduceDocumentPaymentInstruction?: ?IntroduceDocumentPaymentInstructionPayload,
  introduceDailyCashJournal?: ?IntroduceDailyCashJournalPayload,
  introduceCashJournalLine?: ?IntroduceCashJournalLinePayload,
  finishDailyCashJournal?: ?FinishDailyCashJournalPayload,
  deleteCashJournalLine?: ?DeleteCashJournalLinePayload,
  introduceCountedCashBalance?: ?IntroduceCountedCashBalancePayload,
  introduceExpenseStatement?: ?IntroduceExpenseStatementPayload,
  introduceUserScannedDocument?: ?IntroduceUserScannedDocumentPayload,
  uploadNewDocuments?: ?UploadNewDocuments,
  introduceAccountBalance?: ?IntroduceAccountBalance,
  introduceSolarisAccountBalance?: ?IntroduceSolarisAccountBalance,
  introduceAccountStatus?: ?IntroduceAccountStatus,
  introduceLastPaymentSyncDate?: ?IntroduceLastPaymentSyncDate,
  introduceFinapiAccountStatus?: ?IntroduceFinApiAccountStatus,
  introduceEmployeeMonthlyUpdate?: ?IntroduceEmployeeMonthlyUpdatePayload,
  introduceBusinessEmployee?: ?IntroduceBusinessEmployeePayload,
  deleteBusinessEmployee?: ?DeleteBusinessEmployeePayload,
  deleteBusinessEmployeeFutureTimeDependentData?: ?DeleteBusinessEmployeeFutureTimeDependentDataPayload,
  updateEmployee?: ?UpdateEmployeePayload,
  introduceHaasUser?: ?IntroduceHaasUser,
  introduceBusinessSupplier?: ?IntroduceBusinessSupplier,
  introduceBusinessCustomer?: ?IntroduceBusinessCustomer,
  updateBusinessSupplier?: ?UpdateBusinessSupplierPayload,
  updateBusinessCustomer?: ?UpdateBusinessCustomerPayload,
  connectSupplierToApi?: ?ConnectSupplierToApiPayload,
  refreshIssue?: ?RefreshIssue,
  importEmployeeDataFromDatev?: ?ImportEmployeeDataFromDatev,
  commitBookkeepingExport?: ?CommitBookkeepingExport,
  createPreliminaryPayslips?: ?CreatePreliminaryPayslipsPayload,
  introduceAppUser?: ?IntroduceAppUserPayload,
  introduceEntityNote?: ?IntroduceEntityNote,
  deleteEntityNote?: ?DeleteEntityNotePayload,
  generateMerchantActivationMecEmail?: ?GenerateMerchantActivationMecEmail,
  generateNedMerchantActivationMecEmail?: ?GenerateNedMerchantActivationMecEmailPayload,
  introduceMerchantPaymentAccount?: ?IntroduceMerchantPaymentAccountPayload,
  introduceBusinessPaymentProvider?: ?IntroduceBusinessPaymentProviderPayload,
  clientProfileOtherFieldsMutation?: ?ClientProfileOtherFieldsMutationPayload,
  bookkeepingExportBusinessFieldsMutation?: ?BookkeepingExportBusinessFieldsMutationPayload,
  mandatoryBookkeepingBusinessFieldsMutation?: ?MandatoryBookkeepingBusinessFieldsMutationPayload,
  updateMerchantPaymentAccount?: ?UpdateMerchantPaymentAccountPayload,
  updateBusinessPaymentProvider?: ?UpdateBusinessPaymentProviderPayload,
  introducePaymentIdentificationRuleForPaymentProvider?: ?IntroducePaymentIdentificationRuleForPaymentProviderPayload,
  updatePaymentIdentificationRuleActiveStatus?: ?UpdatePaymentIdentificationRuleActiveStatusPayload,
  introduceNedEntityNote?: ?IntroduceNedEntityNotePayload,
  introduceFeedLog?: ?IntroduceFeedLogPayload,
  markDocumentAsIrrelevant?: ?MarkDocumentAsIrrelevantPayload,
  introduceTodoListSourceFile?: ?IntroduceTodoListSourceFilePayload,
  updateTodoListItems?: ?UpdateTodoListItemsPayload,
  deleteTodoListItems?: ?DeleteTodoListItemsPayload,
  updatePayableDueDate?: ?UpdatePayableDueDatePayload,
  updateMerchantDocument?: ?UpdateMerchantDocumentPayload,
  markDocumentAsDuplicate?: ?MarkDocumentAsDuplicatePayload,
  introduceMerchantPaymentRequest?: ?IntroduceMerchantPaymentRequestPayload,
  requestPaymentTaskForDocument?: ?RequestPaymentTaskForDocumentPayload,
  introduceChatMessage?: ?IntroduceChatMessagePayload,
  updateChatMessage?: ?UpdateChatMessagePayload,
  deleteChatMessage?: ?DeleteChatMessagePayload,
  markMessagesAsRead?: ?MarkMessagesAsReadPayload,
  introduceTransactionAction?: ?IntroduceTransactionActionPayload,
  introducePayment?: ?IntroducePaymentPayload,
  updatePaymentDetails?: ?UpdatePaymentDetailsPayload,
  deletePayment?: ?DeletePaymentPayload,
  introduceNewStructuredDocument?: ?IntroduceNewStructuredDocument,
  introduceNewStructuredDocumentForRelay?: ?IntroduceNewStructuredDocumentForRelayPayload,
  introduceCsvBankAccountStatement?: ?IntroduceCsvBankAccountStatementPayload,
  introduceSupplier?: ?IntroduceSupplierPayload,
  introduceBookingRule?: ?IntroduceBookingRulePayload,
  deleteBookingRule?: ?DeleteBookingRulePayload,
  updatePaymentBookings?: ?UpdatePaymentBookingsPayload,
  introduceBusinessBookingAccountCaption?: ?IntroduceBusinessBookingAccountCaptionPayload,
|};

export type MutationIntroduceRecordArgs = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  humanExtractedData?: ?$ElementType<Scalars, 'JSONString'>,
  ignoreWarning?: ?$ElementType<Scalars, 'Boolean'>,
  recordId?: ?$ElementType<Scalars, 'String'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceLinkingArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
  transactionLinkDataList?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type MutationIntroduceContractRecordLinkingArgs = {|
  contractId?: ?$ElementType<Scalars, 'String'>,
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationMergeTransactionsArgs = {|
  transactionIds?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type MutationIntroduceCounterPartyArgs = {|
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type MutationIntroduceCounterPartyFromUserArgs = {|
  input: IntroduceCounterPartyFromUserInput,
|};

export type MutationAddFigoAuthorizationArgs = {|
  input: AddFigoAuthorizationInput,
|};

export type MutationIntroduceScenarioBookingsArgs = {|
  data?: ?$ElementType<Scalars, 'JSONString'>,
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceTaskManagerTaskNoteArgs = {|
  content?: ?$ElementType<Scalars, 'String'>,
  editable?: ?$ElementType<Scalars, 'Boolean'>,
  id?: ?$ElementType<Scalars, 'String'>,
  module?: ?$ElementType<Scalars, 'String'>,
  rule?: ?$ElementType<Scalars, 'String'>,
  title?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationDeleteTaskManagerTaskNoteArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceTaskManagerActionArgs = {|
  input: IntroduceTaskManagerActionInput,
|};

export type MutationIntroduceBackendTaskActionArgs = {|
  actionName?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  issueId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceIssueActionArgs = {|
  input: IntroduceIssueActionInput,
|};

export type MutationIntroduceEntityActionsArgs = {|
  input: IntroduceEntityActionsInput,
|};

export type MutationOnPaymentExecutedArgs = {|
  input: OnPaymentExecutedInput,
|};

export type MutationIntroduceUserPendingCertificationArgs = {|
  certificationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceTaskActionUserExplanationArgs = {|
  explanation?: ?$ElementType<Scalars, 'String'>,
  taskAction?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceDocumentArgs = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  docId?: ?$ElementType<Scalars, 'String'>,
  documentSubType?: ?$ElementType<Scalars, 'String'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  humanExtractedData?: ?$ElementType<Scalars, 'JSONString'>,
  isExtracted: $ElementType<Scalars, 'Boolean'>,
  isSystemProcessing: $ElementType<Scalars, 'Boolean'>,
  metadata?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type MutationIntroduceManualAccountBalanceArgs = {|
  input: IntroduceManualAccountBalanceInput,
|};

export type MutationIntroduceDocumentOcrTextArgs = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  ocrText?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceDocumentPaymentInstructionArgs = {|
  input: IntroduceDocumentPaymentInstructionInput,
|};

export type MutationIntroduceDailyCashJournalArgs = {|
  input: IntroduceDailyCashJournalInput,
|};

export type MutationIntroduceCashJournalLineArgs = {|
  input: IntroduceCashJournalLineInput,
|};

export type MutationFinishDailyCashJournalArgs = {|
  input: FinishDailyCashJournalInput,
|};

export type MutationDeleteCashJournalLineArgs = {|
  input: DeleteCashJournalLineInput,
|};

export type MutationIntroduceCountedCashBalanceArgs = {|
  input: IntroduceCountedCashBalanceInput,
|};

export type MutationIntroduceExpenseStatementArgs = {|
  input: IntroduceExpenseStatementInput,
|};

export type MutationIntroduceUserScannedDocumentArgs = {|
  input: IntroduceUserScannedDocumentInput,
|};

export type MutationUploadNewDocumentsArgs = {|
  apiType?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  splitToSinglePages?: ?$ElementType<Scalars, 'Boolean'>,
  zeitgoldBusinessId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceAccountBalanceArgs = {|
  balance?: ?$ElementType<Scalars, 'Float'>,
  balanceDate?: ?$ElementType<Scalars, 'DateTime'>,
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceSolarisAccountBalanceArgs = {|
  balance?: ?$ElementType<Scalars, 'Float'>,
  balanceDate?: ?$ElementType<Scalars, 'DateTime'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceAccountStatusArgs = {|
  accountStatus?: ?$ElementType<Scalars, 'String'>,
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceLastPaymentSyncDateArgs = {|
  lastPaymentSyncDate?: ?$ElementType<Scalars, 'String'>,
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceFinapiAccountStatusArgs = {|
  accountStatus?: ?$ElementType<Scalars, 'String'>,
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceEmployeeMonthlyUpdateArgs = {|
  input: IntroduceEmployeeMonthlyUpdateInput,
|};

export type MutationIntroduceBusinessEmployeeArgs = {|
  input: IntroduceBusinessEmployeeInput,
|};

export type MutationDeleteBusinessEmployeeArgs = {|
  input: DeleteBusinessEmployeeInput,
|};

export type MutationDeleteBusinessEmployeeFutureTimeDependentDataArgs = {|
  input: DeleteBusinessEmployeeFutureTimeDependentDataInput,
|};

export type MutationUpdateEmployeeArgs = {|
  input: UpdateEmployeeInput,
|};

export type MutationIntroduceHaasUserArgs = {|
  email?: ?$ElementType<Scalars, 'String'>,
  fullName?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  registrationToken?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceBusinessSupplierArgs = {|
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  customerNumber?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  socialInsuranceId?: ?$ElementType<Scalars, 'String'>,
  vatRegistrationNumber?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceBusinessCustomerArgs = {|
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  customerNumber?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationUpdateBusinessSupplierArgs = {|
  input: UpdateBusinessSupplierInput,
|};

export type MutationUpdateBusinessCustomerArgs = {|
  input: UpdateBusinessCustomerInput,
|};

export type MutationConnectSupplierToApiArgs = {|
  input: ConnectSupplierToApiInput,
|};

export type MutationRefreshIssueArgs = {|
  externalId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationImportEmployeeDataFromDatevArgs = {|
  data?: ?$ElementType<Scalars, 'JSONString'>,
  payload?: ?$ElementType<Scalars, 'JSONString'>,
  zeitgoldBusinessId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationCommitBookkeepingExportArgs = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  createdInMonth?: ?$ElementType<Scalars, 'String'>,
  exportSource?: ?$ElementType<Scalars, 'String'>,
  fromDate?: ?$ElementType<Scalars, 'String'>,
  untilDate?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationCreatePreliminaryPayslipsArgs = {|
  input: CreatePreliminaryPayslipsInput,
|};

export type MutationIntroduceAppUserArgs = {|
  input: IntroduceAppUserInput,
|};

export type MutationIntroduceEntityNoteArgs = {|
  businessId: $ElementType<Scalars, 'String'>,
  entityId: $ElementType<Scalars, 'String'>,
  entityType: $ElementType<Scalars, 'String'>,
  note: $ElementType<Scalars, 'String'>,
  noteContext: $ElementType<Scalars, 'String'>,
|};

export type MutationDeleteEntityNoteArgs = {|
  input: DeleteEntityNoteInput,
|};

export type MutationGenerateMerchantActivationMecEmailArgs = {|
  eventId?: ?$ElementType<Scalars, 'String'>,
  missingBankAccountStatements?: ?$ElementType<Scalars, 'JSONString'>,
  missingCashJournals?: ?$ElementType<Scalars, 'JSONString'>,
  missingPaymentProviderStatements?: ?$ElementType<Scalars, 'JSONString'>,
  missingZreports?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type MutationGenerateNedMerchantActivationMecEmailArgs = {|
  input: GenerateNedMerchantActivationMecEmailInput,
|};

export type MutationIntroduceMerchantPaymentAccountArgs = {|
  input: IntroduceMerchantPaymentAccountInput,
|};

export type MutationIntroduceBusinessPaymentProviderArgs = {|
  input: IntroduceBusinessPaymentProviderInput,
|};

export type MutationClientProfileOtherFieldsMutationArgs = {|
  input: ClientProfileOtherFieldsMutationInput,
|};

export type MutationBookkeepingExportBusinessFieldsMutationArgs = {|
  input: BookkeepingExportBusinessFieldsMutationInput,
|};

export type MutationMandatoryBookkeepingBusinessFieldsMutationArgs = {|
  input: MandatoryBookkeepingBusinessFieldsMutationInput,
|};

export type MutationUpdateMerchantPaymentAccountArgs = {|
  input: UpdateMerchantPaymentAccountInput,
|};

export type MutationUpdateBusinessPaymentProviderArgs = {|
  input: UpdateBusinessPaymentProviderInput,
|};

export type MutationIntroducePaymentIdentificationRuleForPaymentProviderArgs = {|
  input: IntroducePaymentIdentificationRuleForPaymentProviderInput,
|};

export type MutationUpdatePaymentIdentificationRuleActiveStatusArgs = {|
  input: UpdatePaymentIdentificationRuleActiveStatusInput,
|};

export type MutationIntroduceNedEntityNoteArgs = {|
  input: IntroduceNedEntityNoteInput,
|};

export type MutationIntroduceFeedLogArgs = {|
  input: IntroduceFeedLogInput,
|};

export type MutationMarkDocumentAsIrrelevantArgs = {|
  input: MarkDocumentAsIrrelevantInput,
|};

export type MutationIntroduceTodoListSourceFileArgs = {|
  input: IntroduceTodoListSourceFileInput,
|};

export type MutationUpdateTodoListItemsArgs = {|
  input: UpdateTodoListItemsInput,
|};

export type MutationDeleteTodoListItemsArgs = {|
  input: DeleteTodoListItemsInput,
|};

export type MutationUpdatePayableDueDateArgs = {|
  input: UpdatePayableDueDateInput,
|};

export type MutationUpdateMerchantDocumentArgs = {|
  input: UpdateMerchantDocumentInput,
|};

export type MutationMarkDocumentAsDuplicateArgs = {|
  input: MarkDocumentAsDuplicateInput,
|};

export type MutationIntroduceMerchantPaymentRequestArgs = {|
  input: IntroduceMerchantPaymentRequestInput,
|};

export type MutationRequestPaymentTaskForDocumentArgs = {|
  input: RequestPaymentTaskForDocumentInput,
|};

export type MutationIntroduceChatMessageArgs = {|
  input: IntroduceChatMessageInput,
|};

export type MutationUpdateChatMessageArgs = {|
  input: UpdateChatMessageInput,
|};

export type MutationDeleteChatMessageArgs = {|
  input: DeleteChatMessageInput,
|};

export type MutationMarkMessagesAsReadArgs = {|
  input: MarkMessagesAsReadInput,
|};

export type MutationIntroduceTransactionActionArgs = {|
  input: IntroduceTransactionActionInput,
|};

export type MutationIntroducePaymentArgs = {|
  input: IntroducePaymentInput,
|};

export type MutationUpdatePaymentDetailsArgs = {|
  input: UpdatePaymentDetailsInput,
|};

export type MutationDeletePaymentArgs = {|
  input: DeletePaymentInput,
|};

export type MutationIntroduceNewStructuredDocumentArgs = {|
  apiType?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  zeitgoldBusinessId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceNewStructuredDocumentForRelayArgs = {|
  input: IntroduceNewStructuredDocumentForRelayInput,
|};

export type MutationIntroduceCsvBankAccountStatementArgs = {|
  input: IntroduceCsvBankAccountStatementInput,
|};

export type MutationIntroduceSupplierArgs = {|
  input: IntroduceSupplierInput,
|};

export type MutationIntroduceBookingRuleArgs = {|
  input: IntroduceBookingRuleInput,
|};

export type MutationDeleteBookingRuleArgs = {|
  input: DeleteBookingRuleInput,
|};

export type MutationUpdatePaymentBookingsArgs = {|
  input: UpdatePaymentBookingsInput,
|};

export type MutationIntroduceBusinessBookingAccountCaptionArgs = {|
  input: IntroduceBusinessBookingAccountCaptionInput,
|};

export type NedOnboardingProgressNode = {|
  __typename?: 'NedOnboardingProgressNode',
  isBusinessOnboarded?: ?$ElementType<Scalars, 'Boolean'>,
  tasks?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
|};

export type Node = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type OfferedGoodsPerMonthNode = {|
  __typename?: 'OfferedGoodsPerMonthNode',
  offeredGoodsCategory?: ?$ElementType<Scalars, 'String'>,
  amountPerMonth?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
|};

export type OnPaymentExecutedInput = {|
  entityId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  executionDurationSeconds?: ?$ElementType<Scalars, 'Float'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type OnPaymentExecutedPayload = {|
  __typename?: 'OnPaymentExecutedPayload',
  taskAction?: ?TaskActionNode,
  result?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type PageInfo = {|
  __typename?: 'PageInfo',
  hasNextPage: $ElementType<Scalars, 'Boolean'>,
  hasPreviousPage: $ElementType<Scalars, 'Boolean'>,
  startCursor?: ?$ElementType<Scalars, 'String'>,
  endCursor?: ?$ElementType<Scalars, 'String'>,
|};

export const PayableContractPaymentTypeValues = Object.freeze({
  Deposit: 'DEPOSIT',
  FundAndInterest: 'FUND_AND_INTEREST',
  FundOnly: 'FUND_ONLY',
  InterestOnly: 'INTEREST_ONLY',
});

export type PayableContractPaymentType = $Values<typeof PayableContractPaymentTypeValues>;

export const PayableFundOrInterestValues = Object.freeze({
  Deposit: 'DEPOSIT',
  FundAndInterest: 'FUND_AND_INTEREST',
  FundOnly: 'FUND_ONLY',
  InterestOnly: 'INTEREST_ONLY',
});

export type PayableFundOrInterest = $Values<typeof PayableFundOrInterestValues>;

export type PayableNode = {|
  ...Node,
  ...{|
    __typename?: 'PayableNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    dateOccurred: $ElementType<Scalars, 'Date'>,
    referenceId: $ElementType<Scalars, 'String'>,
    referencesList: Array<$ElementType<Scalars, 'String'>>,
    amount: $ElementType<Scalars, 'Float'>,
    amountToReconcile: $ElementType<Scalars, 'Float'>,
    vatRatesAmountsGross?: ?$ElementType<Scalars, 'JSONString'>,
    dueDateWithEarlyPaymentDiscount?: ?$ElementType<Scalars, 'Date'>,
    dueDate?: ?$ElementType<Scalars, 'String'>,
    merchantDueDate?: ?$ElementType<Scalars, 'Date'>,
    earlyPaymentDiscountPercent?: ?$ElementType<Scalars, 'Float'>,
    businessTransaction?: ?TransactionNode,
    record?: ?RecordNode,
    status: PayableStatus,
    direction?: ?$ElementType<Scalars, 'String'>,
    payableOrigin: PayablePayableOrigin,
    shouldPay?: ?$ElementType<Scalars, 'Boolean'>,
    datePeriod?: ?Array<$ElementType<Scalars, 'Date'>>,
    originalPayableAmountCurrency: PayableOriginalPayableAmountCurrency,
    originalPayableAmount?: ?$ElementType<Scalars, 'Float'>,
    fundOrInterest?: ?PayableFundOrInterest,
    contractPaymentType?: ?PayableContractPaymentType,
    contractActivity?: ?ContractActivityNode,
    uid?: ?$ElementType<Scalars, 'String'>,
    counterParty?: ?CounterPartyNode,
    vatAmount?: ?$ElementType<Scalars, 'Float'>,
    amountWithDiscount?: ?$ElementType<Scalars, 'Float'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
    vatCompliantProblems?: ?Array<?$ElementType<Scalars, 'String'>>,
    replacementReceiptScheme?: ?$ElementType<Scalars, 'JSONString'>,
    invoiceNumber?: ?$ElementType<Scalars, 'String'>,
    taskActions?: ?TaskActionNodeConnection,
    transactions?: ?TransactionNode,
    transactionIssues?: ?IssueNodeConnection,
    financialAdvicePayable?: ?FinancialAdvicePayableNode,
    verificationSource?: ?$ElementType<Scalars, 'String'>,
    isHighlightedForReview?: ?$ElementType<Scalars, 'Boolean'>,
    bookings?: ?BookkeepingNodeConnection,
    document?: ?DocumentNode,
  |},
|};

export type PayableNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type PayableNodeTransactionIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  missingRecurrentDocument_DocumentType?: ?$ElementType<Scalars, 'String'>,
  missingRecurrentDocument_DocumentType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasBookingReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PayableNodeBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  bookingDate_Year?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  export_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  export_Month?: ?$ElementType<Scalars, 'Date'>,
  export_CreatedInMonth?: ?$ElementType<Scalars, 'Date'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isReversed?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  onlyBookingsWithReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
  relatedAccount?: ?$ElementType<Scalars, 'String'>,
  isContributingToTaxType?: ?$ElementType<Scalars, 'String'>,
|};

export type PayableNodeConnection = {|
  __typename?: 'PayableNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PayableNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type PayableNodeEdge = {|
  __typename?: 'PayableNodeEdge',
  node?: ?PayableNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const PayableOriginalPayableAmountCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type PayableOriginalPayableAmountCurrency = $Values<typeof PayableOriginalPayableAmountCurrencyValues>;

export const PayablePayableOriginValues = Object.freeze({
  BankFee: 'BANK_FEE',
  BankInterest: 'BANK_INTEREST',
  BatchPaymentWithBreakdown: 'BATCH_PAYMENT_WITH_BREAKDOWN',
  CashInternalTransfer: 'CASH_INTERNAL_TRANSFER',
  CashRegisterCashRevenue: 'CASH_REGISTER_CASH_REVENUE',
  Customer: 'CUSTOMER',
  EmployeeExpense: 'EMPLOYEE_EXPENSE',
  EmployeeOwnerTransfer: 'EMPLOYEE_OWNER_TRANSFER',
  EmployeePayroll: 'EMPLOYEE_PAYROLL',
  EmployeeTip: 'EMPLOYEE_TIP',
  EmploymentAgencyIntegrationSubsidies: 'EMPLOYMENT_AGENCY_INTEGRATION_SUBSIDIES',
  FinancialAuthority: 'FINANCIAL_AUTHORITY',
  InternalElectronicTransfer: 'INTERNAL_ELECTRONIC_TRANSFER',
  LoanOrDeposit: 'LOAN_OR_DEPOSIT',
  MerchantWrongChange: 'MERCHANT_WRONG_CHANGE',
  OwnerCapital: 'OWNER_CAPITAL',
  PaymentProviderSettlement: 'PAYMENT_PROVIDER_SETTLEMENT',
  SocialInsuranceStatement: 'SOCIAL_INSURANCE_STATEMENT',
  Supplier: 'SUPPLIER',
  TvAndRadioLicenseFees: 'TV_AND_RADIO_LICENSE_FEES',
  Unknown: 'UNKNOWN',
});

export type PayablePayableOrigin = $Values<typeof PayablePayableOriginValues>;

export const PayableStatusValues = Object.freeze({
  CurrentVersion: 'CURRENT_VERSION',
  Duplicate: 'DUPLICATE',
  PreviousVersion: 'PREVIOUS_VERSION',
});

export type PayableStatus = $Values<typeof PayableStatusValues>;

export type PaymentBookingsInput = {|
  scenario?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  vatRate?: ?$ElementType<Scalars, 'String'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentCompletenessNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentCompletenessNode',
    id: $ElementType<Scalars, 'ID'>,
    startBalanceDate?: ?$ElementType<Scalars, 'String'>,
    startBalanceAmount?: ?$ElementType<Scalars, 'String'>,
    startBalanceSourceId?: ?$ElementType<Scalars, 'String'>,
    startBalanceSourceType?: ?$ElementType<Scalars, 'String'>,
    endBalanceDate?: ?$ElementType<Scalars, 'String'>,
    endBalanceAmount?: ?$ElementType<Scalars, 'String'>,
    endBalanceSourceId?: ?$ElementType<Scalars, 'String'>,
    endBalanceSourceType?: ?$ElementType<Scalars, 'String'>,
    isPaymentsComplete?: ?$ElementType<Scalars, 'Boolean'>,
    missingPaymentsAmountSum?: ?$ElementType<Scalars, 'String'>,
    paymentsHistory?: ?$ElementType<Scalars, 'JSONString'>,
    bankStatementsInfos?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type PaymentExplanationProgress = {|
  ...Node,
  ...{|
    __typename?: 'PaymentExplanationProgress',
    id: $ElementType<Scalars, 'ID'>,
    total?: ?$ElementType<Scalars, 'Float'>,
    explained?: ?$ElementType<Scalars, 'Float'>,
    unexplained?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type PaymentIdentificationRuleNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentIdentificationRuleNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    name: $ElementType<Scalars, 'String'>,
    isActive: $ElementType<Scalars, 'Boolean'>,
    business: BusinessNode,
    equalsMerchantPaymentAccount?: ?MerchantPaymentAccountNode,
    containsCounterPartyName: $ElementType<Scalars, 'String'>,
    amountMin?: ?$ElementType<Scalars, 'Float'>,
    amountMax?: ?$ElementType<Scalars, 'Float'>,
    containsReferenceKeywords: $ElementType<Scalars, 'String'>,
    referenceFindAll: $ElementType<Scalars, 'Boolean'>,
    resultPayableOrigin: PaymentIdentificationRuleResultPayableOrigin,
    resultOwner?: ?BusinessEmployeeNode,
    resultPaymentProvider?: ?BusinessPaymentProviderNode,
    isLatestStateApplied: $ElementType<Scalars, 'Boolean'>,
    businessPaymentProvider: BusinessPaymentProviderNodeConnection,
    counterParty?: ?$ElementType<Scalars, 'String'>,
    keywords?: ?Array<?$ElementType<Scalars, 'String'>>,
  |},
|};

export type PaymentIdentificationRuleNodeBusinessPaymentProviderArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  isForCashRegister?: ?$ElementType<Scalars, 'Boolean'>,
  isUsedForRevenue?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type PaymentIdentificationRuleNodeConnection = {|
  __typename?: 'PaymentIdentificationRuleNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PaymentIdentificationRuleNodeEdge>,
|};

export type PaymentIdentificationRuleNodeEdge = {|
  __typename?: 'PaymentIdentificationRuleNodeEdge',
  node?: ?PaymentIdentificationRuleNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const PaymentIdentificationRuleResultPayableOriginValues = Object.freeze({
  BankFee: 'BANK_FEE',
  BankInterest: 'BANK_INTEREST',
  BatchPaymentWithBreakdown: 'BATCH_PAYMENT_WITH_BREAKDOWN',
  CashInternalTransfer: 'CASH_INTERNAL_TRANSFER',
  CashRegisterCashRevenue: 'CASH_REGISTER_CASH_REVENUE',
  Customer: 'CUSTOMER',
  EmployeeExpense: 'EMPLOYEE_EXPENSE',
  EmployeeOwnerTransfer: 'EMPLOYEE_OWNER_TRANSFER',
  EmployeePayroll: 'EMPLOYEE_PAYROLL',
  EmployeeTip: 'EMPLOYEE_TIP',
  EmploymentAgencyIntegrationSubsidies: 'EMPLOYMENT_AGENCY_INTEGRATION_SUBSIDIES',
  FinancialAuthority: 'FINANCIAL_AUTHORITY',
  InternalElectronicTransfer: 'INTERNAL_ELECTRONIC_TRANSFER',
  LoanOrDeposit: 'LOAN_OR_DEPOSIT',
  MerchantWrongChange: 'MERCHANT_WRONG_CHANGE',
  OwnerCapital: 'OWNER_CAPITAL',
  PaymentProviderSettlement: 'PAYMENT_PROVIDER_SETTLEMENT',
  SocialInsuranceStatement: 'SOCIAL_INSURANCE_STATEMENT',
  Supplier: 'SUPPLIER',
  TvAndRadioLicenseFees: 'TV_AND_RADIO_LICENSE_FEES',
  Unknown: 'UNKNOWN',
});

export type PaymentIdentificationRuleResultPayableOrigin = $Values<
  typeof PaymentIdentificationRuleResultPayableOriginValues,
>;

export const PaymentMethodValues = Object.freeze({
  Cash: 'CASH',
  CreditTransfer: 'CREDIT_TRANSFER',
  CreditTransferReturn: 'CREDIT_TRANSFER_RETURN',
  DirectDebit: 'DIRECT_DEBIT',
  DirectDebitReturn: 'DIRECT_DEBIT_RETURN',
  PaymentProvider: 'PAYMENT_PROVIDER',
  Unknown: 'UNKNOWN',
});

export type PaymentMethod = $Values<typeof PaymentMethodValues>;

export type PaymentNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    amount: $ElementType<Scalars, 'Float'>,
    paymentReference: $ElementType<Scalars, 'String'>,
    paymentDate: $ElementType<Scalars, 'Date'>,
    counterPartyIban?: ?$ElementType<Scalars, 'String'>,
    counterPartyName: $ElementType<Scalars, 'String'>,
    paymentAccount: MerchantPaymentAccountNode,
    record: RecordNode,
    direction?: ?$ElementType<Scalars, 'String'>,
    method: PaymentMethod,
    payableDatePeriod?: ?Array<$ElementType<Scalars, 'Date'>>,
    isBatched: $ElementType<Scalars, 'Boolean'>,
    isApproximatedContactPayment: $ElementType<Scalars, 'Boolean'>,
    relatedOwner?: ?BusinessEmployeeNode,
    relatedPaymentProvider?: ?BusinessPaymentProviderNode,
    payableOrigin: PaymentPayableOrigin,
    supplier?: ?SupplierNode,
    breakdownRecord?: ?RecordNode,
    paymentToTransactions?: ?PaymentToTransactionNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    cleanPaymentReference?: ?$ElementType<Scalars, 'String'>,
    paymentIdentificationOptions?: ?Array<?$ElementType<Scalars, 'String'>>,
    counterPartyType?: ?$ElementType<Scalars, 'String'>,
    isLinkedToPayable?: ?$ElementType<Scalars, 'Boolean'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
    processingState?: ?$ElementType<Scalars, 'String'>,
    isExplained?: ?$ElementType<Scalars, 'Boolean'>,
    isExplainedInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
    explanationDetail?: ?$ElementType<Scalars, 'String'>,
    paymentLabels?: ?Array<?$ElementType<Scalars, 'String'>>,
    attachedDocuments?: ?Array<?DocumentAttachmentNode>,
    replacementReceipt?: ?DocumentNode,
    chatMessages?: ?ChatMessageNodeConnection,
    canExplainByReplacementReceipt?: ?$ElementType<Scalars, 'Boolean'>,
    shouldMerchantIdentifyPayment?: ?$ElementType<Scalars, 'Boolean'>,
    hasMerchantIdentifiedPayment?: ?$ElementType<Scalars, 'Boolean'>,
    explanationProgressAmounts?: ?PaymentExplanationProgress,
    explanationProgressCounts?: ?PaymentExplanationProgress,
    verificationSource?: ?$ElementType<Scalars, 'String'>,
    bookingsSum?: ?$ElementType<Scalars, 'Float'>,
    isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
    processingStatus?: ?$ElementType<Scalars, 'String'>,
    isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
    bookings?: ?BookkeepingNodeConnection,
    notes?: ?EntityNoteNodeConnection,
    isExportReady?: ?$ElementType<Scalars, 'Boolean'>,
    isHighlightedForReview?: ?$ElementType<Scalars, 'Boolean'>,
    eventPaymentAccount?: ?EventPaymentAccountNode,
  |},
|};

export type PaymentNodePaymentToTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentNodeChatMessagesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isRead?: ?$ElementType<Scalars, 'Boolean'>,
  senderType?: ?$ElementType<Scalars, 'String'>,
  senderType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isMerchantMessage?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type PaymentNodeBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  bookingDate_Year?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  export_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  export_Month?: ?$ElementType<Scalars, 'Date'>,
  export_CreatedInMonth?: ?$ElementType<Scalars, 'Date'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isReversed?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  onlyBookingsWithReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
  relatedAccount?: ?$ElementType<Scalars, 'String'>,
  isContributingToTaxType?: ?$ElementType<Scalars, 'String'>,
|};

export type PaymentNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type PaymentNodeConnection = {|
  __typename?: 'PaymentNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PaymentNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
  totalAmount?: ?$ElementType<Scalars, 'Float'>,
  totalDirectedAmount?: ?$ElementType<Scalars, 'Float'>,
|};

export type PaymentNodeEdge = {|
  __typename?: 'PaymentNodeEdge',
  node?: ?PaymentNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const PaymentPayableOriginValues = Object.freeze({
  BankFee: 'BANK_FEE',
  BankInterest: 'BANK_INTEREST',
  BatchPaymentWithBreakdown: 'BATCH_PAYMENT_WITH_BREAKDOWN',
  CashInternalTransfer: 'CASH_INTERNAL_TRANSFER',
  CashRegisterCashRevenue: 'CASH_REGISTER_CASH_REVENUE',
  Customer: 'CUSTOMER',
  EmployeeExpense: 'EMPLOYEE_EXPENSE',
  EmployeeOwnerTransfer: 'EMPLOYEE_OWNER_TRANSFER',
  EmployeePayroll: 'EMPLOYEE_PAYROLL',
  EmployeeTip: 'EMPLOYEE_TIP',
  EmploymentAgencyIntegrationSubsidies: 'EMPLOYMENT_AGENCY_INTEGRATION_SUBSIDIES',
  FinancialAuthority: 'FINANCIAL_AUTHORITY',
  InternalElectronicTransfer: 'INTERNAL_ELECTRONIC_TRANSFER',
  LoanOrDeposit: 'LOAN_OR_DEPOSIT',
  MerchantWrongChange: 'MERCHANT_WRONG_CHANGE',
  OwnerCapital: 'OWNER_CAPITAL',
  PaymentProviderSettlement: 'PAYMENT_PROVIDER_SETTLEMENT',
  SocialInsuranceStatement: 'SOCIAL_INSURANCE_STATEMENT',
  Supplier: 'SUPPLIER',
  TvAndRadioLicenseFees: 'TV_AND_RADIO_LICENSE_FEES',
  Unknown: 'UNKNOWN',
});

export type PaymentPayableOrigin = $Values<typeof PaymentPayableOriginValues>;

export type PaymentProviderStatementNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentProviderStatementNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    document: DocumentNode,
    counterParty?: ?CounterPartyNode,
    recordType: $ElementType<Scalars, 'String'>,
    business: BusinessNode,
    data: $ElementType<Scalars, 'JSONString'>,
    validatedFields: $ElementType<Scalars, 'JSONString'>,
    occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
    balanceHistory: BalanceHistoryNodeConnection,
    payments: PaymentNodeConnection,
    breakdownOriginalPayment?: ?PaymentNode,
    payables: PayableNodeConnection,
    contracts: ContractNodeConnection,
    startDate?: ?$ElementType<Scalars, 'String'>,
    endDate?: ?$ElementType<Scalars, 'String'>,
    payoutAmount?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type PaymentProviderStatementNodeBalanceHistoryArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentProviderStatementNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentProviderStatementNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentProviderStatementNodeContractsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  counterParty?: ?$ElementType<Scalars, 'ID'>,
|};

export type PaymentProviderStatementNodeConnection = {|
  __typename?: 'PaymentProviderStatementNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PaymentProviderStatementNodeEdge>,
|};

export type PaymentProviderStatementNodeEdge = {|
  __typename?: 'PaymentProviderStatementNodeEdge',
  node?: ?PaymentProviderStatementNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type PaymentsExtractionStatusNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentsExtractionStatusNode',
    id: $ElementType<Scalars, 'ID'>,
    isRequired?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export const PaymentToTransactionAmountCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type PaymentToTransactionAmountCurrency = $Values<typeof PaymentToTransactionAmountCurrencyValues>;

export type PaymentToTransactionNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentToTransactionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    payment: PaymentNode,
    businessTransaction: TransactionNode,
    amountCurrency: PaymentToTransactionAmountCurrency,
    amount: $ElementType<Scalars, 'Float'>,
    paymentReference: $ElementType<Scalars, 'String'>,
    metadata?: ?$ElementType<Scalars, 'JSONString'>,
    processingState: PaymentToTransactionProcessingState,
    transactionJourney?: ?TransactionJourneyNode,
    savingAmount?: ?$ElementType<Scalars, 'Float'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type PaymentToTransactionNodeConnection = {|
  __typename?: 'PaymentToTransactionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PaymentToTransactionNodeEdge>,
|};

export type PaymentToTransactionNodeEdge = {|
  __typename?: 'PaymentToTransactionNodeEdge',
  node?: ?PaymentToTransactionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const PaymentToTransactionProcessingStateValues = Object.freeze({
  MerchantUnidentifiedPayment: 'MERCHANT_UNIDENTIFIED_PAYMENT',
  MissingMerchantMainPayable: 'MISSING_MERCHANT_MAIN_PAYABLE',
  NonTaxCompliantPayable: 'NON_TAX_COMPLIANT_PAYABLE',
  NonVatCompliantPayable: 'NON_VAT_COMPLIANT_PAYABLE',
  Reconciled: 'RECONCILED',
  Unknown: 'UNKNOWN',
  ZeitgoldInitialProcessing: 'ZEITGOLD_INITIAL_PROCESSING',
  ZeitgoldProcessing: 'ZEITGOLD_PROCESSING',
});

export type PaymentToTransactionProcessingState = $Values<typeof PaymentToTransactionProcessingStateValues>;

export type PaymentUpdatedSubscription = {|
  __typename?: 'PaymentUpdatedSubscription',
  updatedPayment?: ?UpdatedPaymentNode,
|};

export type PersonIdentificationNode = {|
  __typename?: 'PersonIdentificationNode',
  personId?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  businessEmployee?: ?BusinessEmployeeNode,
|};

export type PreliminaryPayslipRecommendationNode = {|
  ...Node,
  ...{|
    __typename?: 'PreliminaryPayslipRecommendationNode',
    id: $ElementType<Scalars, 'ID'>,
    hourlyWage?: ?$ElementType<Scalars, 'Float'>,
    hoursAmount?: ?$ElementType<Scalars, 'Float'>,
    grossAmount?: ?$ElementType<Scalars, 'Float'>,
    netAmount?: ?$ElementType<Scalars, 'Float'>,
    paidPrepaymentsSum?: ?$ElementType<Scalars, 'Float'>,
    payoutAmount?: ?$ElementType<Scalars, 'Float'>,
    monthlyIncome?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type Query = {|
  __typename?: 'Query',
  todoListItems?: ?TodoListItemNodeConnection,
  paymentsExtractionStatus?: ?PaymentsExtractionStatusNode,
  intersectingBankStatements?: ?DocumentConnection,
  employeeMonthlyUpdateReport?: ?EmployeeMonthlyUpdateNode,
  socialInsurance?: ?SocialInsuranceNode,
  socialInsurances?: ?SocialInsuranceNodeConnection,
  taskAction?: ?TaskActionNode,
  taskActions?: ?TaskActionNodeConnection,
  taskManagerUserTaskProgress?: ?UserTaskProgressNode,
  taskManagerTask?: ?TaskManagerTaskNode,
  taskManagerTasksPickedChunk?: ?TaskManagerTaskConnection,
  taskManagerTasks?: ?TaskManagerTaskConnection,
  taskManagerTaskNote?: ?TaskManagerTaskNoteNode,
  taskManagerCertifications?: ?TaskManagerCertificationConnection,
  taskManagerModules?: ?TaskManagerModuleConnection,
  allAvailableTaskTypes?: ?Array<?TaskType>,
  bookkeeping?: ?BookkeepingNode,
  bookkeepings?: ?BookkeepingNodeConnection,
  bookkeepingsTimeline?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
  bookingScenario?: ?BookingScenarioNode,
  recommendedBookingScenarios?: ?Array<?BookingScenarioNode>,
  bookingScenarios?: ?Array<?BookingScenarioNode>,
  bookkeepingExportsPreview?: ?Array<?BookkeepingExportPreviewNode>,
  monthEndBalances?: ?Array<?MonthEndBalancesNode>,
  bookingRecommendations?: ?Array<?BookingRecommendationNode>,
  bookkeepingExports?: ?Array<?BookkeepingExportNode>,
  bookingRuleSchema?: ?BookingRuleSchemaNode,
  node?: ?Node,
  address?: ?AddressNode,
  addresses?: ?AddressNodeConnection,
  record?: ?RecordNode,
  records?: ?RecordNodeConnection,
  transaction?: ?TransactionNode,
  transactions?: ?TransactionNodeConnection,
  document?: ?DocumentNode,
  documents?: ?DocumentNodeConnection,
  counterParty?: ?CounterPartyNode,
  counterParties?: ?CounterPartyNodeConnection,
  business?: ?BusinessNode,
  businesses?: ?BusinessNodeConnection,
  payment?: ?PaymentNode,
  payments?: ?PaymentNodeConnection,
  payable?: ?PayableNode,
  payables?: ?PayableNodeConnection,
  issue?: ?IssueNode,
  issues?: ?IssueNodeConnection,
  event?: ?EventNode,
  events?: ?EventNodeConnection,
  businessSupplier?: ?BusinessSupplierNode,
  businessSuppliers?: ?BusinessSupplierNodeConnection,
  businessEmployee?: ?BusinessEmployeeNode,
  businessEmployees?: ?BusinessEmployeeNodeConnection,
  businessPaymentProvider?: ?BusinessPaymentProviderNode,
  businessPaymentProviders?: ?BusinessPaymentProviderNodeConnection,
  businessCustomer?: ?BusinessCustomerNode,
  businessCustomers?: ?BusinessCustomerNodeConnection,
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  merchantPaymentAccounts?: ?MerchantPaymentAccountNodeConnection,
  expenseStatement?: ?ExpenseStatementNode,
  expenseStatements?: ?ExpenseStatementNodeConnection,
  paymentToTransaction?: ?PaymentToTransactionNode,
  paymentToTransactions?: ?PaymentToTransactionNodeConnection,
  dailyCashJournal?: ?DailyCashJournalNode,
  dailyCashJournals?: ?DailyCashJournalNodeConnection,
  cashJournalLine?: ?CashJournalLineNode,
  cashJournalLines?: ?CashJournalLineNodeConnection,
  appUser?: ?AppUserNode,
  appUsers?: ?AppUserNodeConnection,
  jsonScheme?: ?JsonSchemeNode,
  linkingRecommendations?: ?Array<?LinkingRecommendationNode>,
  manualOverrideRecommendation?: ?LinkingRecommendationNode,
  recordTypes?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
  classificationRecordTypeComboBoxOptions?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
  dynamicRecordTypes?: ?DynamicRecordTypeNodeConnection,
  allAvailableEventTypes?: ?Array<?EventType>,
  internalMissingPayableOrigins?: ?Array<?$ElementType<Scalars, 'String'>>,
  dummy?: ?$ElementType<Scalars, 'String'>,
  documentFieldSources?: ?$ElementType<Scalars, 'JSONString'>,
  processingStatus?: ?$ElementType<Scalars, 'String'>,
  documentExtractedFields?: ?$ElementType<Scalars, 'JSONString'>,
  onlineExtractions?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type QueryTodoListItemsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  date_Year?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Month?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isBlocker?: ?$ElementType<Scalars, 'Boolean'>,
  type?: ?$ElementType<Scalars, 'String'>,
  type_In?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  status_In?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryPaymentsExtractionStatusArgs = {|
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  startDate?: ?$ElementType<Scalars, 'String'>,
  endDate?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryIntersectingBankStatementsArgs = {|
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  startDate?: ?$ElementType<Scalars, 'String'>,
  endDate?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryEmployeeMonthlyUpdateReportArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QuerySocialInsuranceArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QuerySocialInsurancesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryTaskActionArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryTaskManagerTaskArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
  retrieveWithoutLock?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryTaskManagerTasksPickedChunkArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
  retrieveWithoutLock?: ?$ElementType<Scalars, 'Boolean'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  skipTaskId?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryTaskManagerTasksArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType?: ?$ElementType<Scalars, 'String'>,
  taskType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  taskType_NotIn?: ?Array<?$ElementType<Scalars, 'String'>>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  realBusinessesOnly?: ?$ElementType<Scalars, 'Boolean'>,
  onlyWithoutEvents?: ?$ElementType<Scalars, 'Boolean'>,
  certification?: ?$ElementType<Scalars, 'String'>,
  certification_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  module?: ?$ElementType<Scalars, 'String'>,
  module_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  sourceEntityId?: ?$ElementType<Scalars, 'String'>,
  sourceEntityId_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  externalId_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  categorizationFeatures?: ?$ElementType<Scalars, 'String'>,
  priority?: ?$ElementType<Scalars, 'String'>,
  priority_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  experience?: ?$ElementType<Scalars, 'String'>,
  experience_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  isBlockedByDelegation?: ?$ElementType<Scalars, 'Boolean'>,
  business?: ?$ElementType<Scalars, 'String'>,
  business_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryTaskManagerTaskNoteArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryTaskManagerCertificationsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryTaskManagerModulesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryBookkeepingArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBookkeepingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  bookingDate_Year?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  export_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  export_Month?: ?$ElementType<Scalars, 'Date'>,
  export_CreatedInMonth?: ?$ElementType<Scalars, 'Date'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isReversed?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  onlyBookingsWithReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
  relatedAccount?: ?$ElementType<Scalars, 'String'>,
  isContributingToTaxType?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookkeepingsTimelineArgs = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  dateRangeStart?: ?$ElementType<Scalars, 'String'>,
  dateRangeEnd?: ?$ElementType<Scalars, 'String'>,
  accountRanges?: ?Array<?Array<?$ElementType<Scalars, 'Int'>>>,
  includeExportedBookings?: ?$ElementType<Scalars, 'Boolean'>,
  onlyBookingsUpdatedAfter?: ?$ElementType<Scalars, 'String'>,
  onlyBookingsExportedInMonth?: ?$ElementType<Scalars, 'String'>,
  year?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryBookingScenarioArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryRecommendedBookingScenariosArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookingScenariosArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookkeepingExportsPreviewArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
  exportsCreationMonth?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  exportSources?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type QueryMonthEndBalancesArgs = {|
  month?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookingRecommendationsArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookkeepingExportsArgs = {|
  month?: ?$ElementType<Scalars, 'String'>,
  createdInMonth?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryNodeArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryAddressArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryAddressesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryRecordArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesscustomer_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesssupplier_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_LegalName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businesspaymentprovider_DisplayName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_FirstName?: ?$ElementType<Scalars, 'String'>,
  counterParty_Businessemployee_LastName?: ?$ElementType<Scalars, 'String'>,
  payables_Status?: ?$ElementType<Scalars, 'String'>,
  counterPartyId?: ?$ElementType<Scalars, 'ID'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  disjunctiveSearch?: ?$ElementType<Scalars, 'String'>,
  possibleWronglyClassifiedRecordTypes?: ?$ElementType<Scalars, 'Boolean'>,
  data?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  excludeUnchangedRecords?: ?$ElementType<Scalars, 'Boolean'>,
  excludeMultiRecordDocuments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeRecordTypes?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryTransactionArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  minPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  maxPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  minPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  maxPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  minDate?: ?$ElementType<Scalars, 'String'>,
  maxDate?: ?$ElementType<Scalars, 'String'>,
  payablesAmount?: ?$ElementType<Scalars, 'String'>,
  paymentsAmount?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  counterParty?: ?$ElementType<Scalars, 'String'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  reference?: ?$ElementType<Scalars, 'String'>,
  ocrSearch?: ?$ElementType<Scalars, 'String'>,
  matchToRecord?: ?$ElementType<Scalars, 'String'>,
  hasReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryDocumentArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_In?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  processingStatus?: ?$ElementType<Scalars, 'String'>,
  processingStatus_In?: ?$ElementType<Scalars, 'String'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
  inputChannel_In?: ?$ElementType<Scalars, 'String'>,
  records_Payables_Direction?: ?$ElementType<Scalars, 'String'>,
  records_OccurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  searchElasticsearch?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  unextracted?: ?$ElementType<Scalars, 'Boolean'>,
  unprocessed?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  transactionTaskActionName?: ?$ElementType<Scalars, 'String'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  correctionNeeded?: ?$ElementType<Scalars, 'Boolean'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  inputChannelByNames?: ?$ElementType<Scalars, 'String'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  issueDateFrom?: ?$ElementType<Scalars, 'String'>,
  issueDateTo?: ?$ElementType<Scalars, 'String'>,
  prefetchPayableCounterParty?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryCounterPartyArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryCounterPartiesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  supplierCounterPartyType?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBusinessArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  name?: ?$ElementType<Scalars, 'String'>,
  name_Iexact?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  offboardingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  offboardingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  isTestOrDemo?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  nameForEmailBox?: ?$ElementType<Scalars, 'String'>,
  adpClientId?: ?$ElementType<Scalars, 'Float'>,
  onlyNed?: ?$ElementType<Scalars, 'Boolean'>,
  onlyFactory?: ?$ElementType<Scalars, 'Boolean'>,
  businessAccounting_TaxAdviser_Id_In?: ?$ElementType<Scalars, 'String'>,
  businessAccounting_TaxAdviser_Id?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryPaymentArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryPaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  onlyMissingMainPayable?: ?$ElementType<Scalars, 'Boolean'>,
  bookkeepingPaymentsPerMonth?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBreakdownDerivedPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBreakdownOriginalPayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeCashRevenuePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
  isExplained?: ?$ElementType<Scalars, 'Boolean'>,
  isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryPayableArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryPayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  dateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryIssueArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryEventArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_Iexact?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_In?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  closedAt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_In?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  relevantFrameStartTime?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_In?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lte?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasMerchantPendingIssues?: ?$ElementType<Scalars, 'Boolean'>,
  getFirstEvent?: ?$ElementType<Scalars, 'Boolean'>,
  alreadyStarted?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBusinessSupplierArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessSuppliersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  supplierCounterPartyType?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBusinessEmployeeArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessEmployeesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessPaymentProviderArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessPaymentProvidersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  isForCashRegister?: ?$ElementType<Scalars, 'Boolean'>,
  isUsedForRevenue?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBusinessCustomerArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessCustomersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  supplierCounterPartyType?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryMerchantPaymentAccountArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryMerchantPaymentAccountsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  shouldTrySyncFinapi?: ?$ElementType<Scalars, 'Boolean'>,
  accountType?: ?$ElementType<Scalars, 'String'>,
  accountType_In?: ?$ElementType<Scalars, 'String'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  finapiAccountStatus?: ?$ElementType<Scalars, 'String'>,
  finapiAccountStatus_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isOnlyFigoActive?: ?$ElementType<Scalars, 'Boolean'>,
  isOnlyInRelevancePeriod?: ?$ElementType<Scalars, 'Boolean'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryExpenseStatementArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryExpenseStatementsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryPaymentToTransactionArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryPaymentToTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryDailyCashJournalArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryDailyCashJournalsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryCashJournalLineArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryCashJournalLinesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryAppUserArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryAppUsersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryJsonSchemeArgs = {|
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryLinkingRecommendationsArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
  payableId?: ?$ElementType<Scalars, 'String'>,
  paymentId?: ?$ElementType<Scalars, 'String'>,
  isExtensiveLinking?: ?$ElementType<Scalars, 'Boolean'>,
  onlyTransactions?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryManualOverrideRecommendationArgs = {|
  transactionId?: ?$ElementType<Scalars, 'ID'>,
|};

export type QueryRecordTypesArgs = {|
  relevantForLinking?: ?$ElementType<Scalars, 'Boolean'>,
  relevantForBooking?: ?$ElementType<Scalars, 'Boolean'>,
  relevantForRecordTypeField?: ?$ElementType<Scalars, 'Boolean'>,
  schemeCategory?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryDynamicRecordTypesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryDocumentFieldSourcesArgs = {|
  documentId: $ElementType<Scalars, 'String'>,
  businessId: $ElementType<Scalars, 'String'>,
|};

export type QueryProcessingStatusArgs = {|
  documentId: $ElementType<Scalars, 'String'>,
  businessId: $ElementType<Scalars, 'String'>,
|};

export type QueryDocumentExtractedFieldsArgs = {|
  documentId: $ElementType<Scalars, 'String'>,
  businessId: $ElementType<Scalars, 'String'>,
|};

export type QueryOnlineExtractionsArgs = {|
  businessId: $ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  documentTypesToExtract?: ?Array<?$ElementType<Scalars, 'String'>>,
  classifyDocument?: ?$ElementType<Scalars, 'Boolean'>,
  extractFields?: ?$ElementType<Scalars, 'Boolean'>,
  returnOcrText?: ?$ElementType<Scalars, 'Boolean'>,
  userSelectedDocumentType?: ?$ElementType<Scalars, 'String'>,
|};

export type QuestionConnection = {|
  __typename?: 'QuestionConnection',
  pageInfo: PageInfo,
  edges: Array<?QuestionEdge>,
|};

export type QuestionEdge = {|
  __typename?: 'QuestionEdge',
  node?: ?QuestionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type QuestionNode = {|
  ...Node,
  ...{|
    __typename?: 'QuestionNode',
    id: $ElementType<Scalars, 'ID'>,
    key?: ?$ElementType<Scalars, 'String'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    actionOptions?: ?ActionOptionConnection,
  |},
|};

export type QuestionNodeActionOptionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordConnection = {|
  __typename?: 'RecordConnection',
  pageInfo: PageInfo,
  edges: Array<?RecordEdge>,
|};

export type RecordEdge = {|
  __typename?: 'RecordEdge',
  node?: ?RecordNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type RecordNode = {|
  ...Node,
  ...{|
    __typename?: 'RecordNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    document: DocumentNode,
    counterParty?: ?CounterPartyNode,
    recordType: $ElementType<Scalars, 'String'>,
    business: BusinessNode,
    data: $ElementType<Scalars, 'JSONString'>,
    validatedFields: $ElementType<Scalars, 'JSONString'>,
    occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
    balanceHistory: BalanceHistoryNodeConnection,
    payments: PaymentNodeConnection,
    breakdownOriginalPayment?: ?PaymentNode,
    payables: PayableNodeConnection,
    contracts: ContractNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    relatedBookings?: ?BookkeepingConnection,
    linkedTransactionsLinkingData?: ?TransactionLinkingDataConnection,
    isBatched?: ?$ElementType<Scalars, 'Boolean'>,
    shouldLink?: ?$ElementType<Scalars, 'Boolean'>,
    shouldContractLink?: ?$ElementType<Scalars, 'Boolean'>,
    originalContractId?: ?$ElementType<Scalars, 'String'>,
    shouldBook?: ?$ElementType<Scalars, 'Boolean'>,
    derivedEntity?: ?RelatedEntityNode,
    documentId?: ?$ElementType<Scalars, 'String'>,
    taskActions?: ?TaskActionNodeConnection,
    notes?: ?EntityNoteNodeConnection,
    isExtractionVerified?: ?$ElementType<Scalars, 'Boolean'>,
    isExtractionApprovedByAccounting?: ?$ElementType<Scalars, 'Boolean'>,
    linkedDocuments?: ?Array<?RecordToLinkedDocumentsNode>,
    isMainContractRecord?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type RecordNodeBalanceHistoryArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordNodeContractsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  counterParty?: ?$ElementType<Scalars, 'ID'>,
|};

export type RecordNodeRelatedBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordNodeLinkedTransactionsLinkingDataArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type RecordNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type RecordNodeConnection = {|
  __typename?: 'RecordNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?RecordNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordNodeEdge = {|
  __typename?: 'RecordNodeEdge',
  node?: ?RecordNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type RecordToLinkedDocumentsNode = {|
  __typename?: 'RecordToLinkedDocumentsNode',
  name?: ?$ElementType<Scalars, 'String'>,
  isMain?: ?$ElementType<Scalars, 'Boolean'>,
  document?: ?DocumentNode,
|};

export type RefreshIssue = {|
  __typename?: 'RefreshIssue',
  issue?: ?IssueNode,
|};

export type RelatedEntityConnection = {|
  __typename?: 'RelatedEntityConnection',
  pageInfo: PageInfo,
  edges: Array<?RelatedEntityEdge>,
|};

export type RelatedEntityEdge = {|
  __typename?: 'RelatedEntityEdge',
  node?: ?RelatedEntityNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type RelatedEntityNode = {|
  ...Node,
  ...{|
    __typename?: 'RelatedEntityNode',
    id: $ElementType<Scalars, 'ID'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    amount?: ?$ElementType<Scalars, 'Float'>,
    referenceId?: ?$ElementType<Scalars, 'String'>,
    date?: ?$ElementType<Scalars, 'String'>,
    recordType?: ?$ElementType<Scalars, 'String'>,
    thumbnail?: ?$ElementType<Scalars, 'String'>,
    bigThumbnail?: ?$ElementType<Scalars, 'String'>,
    fullUrl?: ?$ElementType<Scalars, 'String'>,
    entityType?: ?$ElementType<Scalars, 'String'>,
    direction?: ?$ElementType<Scalars, 'String'>,
    documentId?: ?$ElementType<Scalars, 'String'>,
    entity?: ?EntityNode,
    record?: ?RecordNode,
    references?: ?Array<?$ElementType<Scalars, 'String'>>,
    amounts?: ?Array<?$ElementType<Scalars, 'String'>>,
    status?: ?$ElementType<Scalars, 'String'>,
    payableOrigin?: ?$ElementType<Scalars, 'String'>,
    businessTransactions?: ?TransactionNodeConnection,
    isCompliant?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type RelatedEntityNodeBusinessTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RequestPaymentTaskForDocumentInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type RequestPaymentTaskForDocumentPayload = {|
  __typename?: 'RequestPaymentTaskForDocumentPayload',
  transaction?: ?TransactionNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SocialInsuranceNode = {|
  ...Node,
  ...{|
    __typename?: 'SocialInsuranceNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    name: $ElementType<Scalars, 'String'>,
    insuranceType: $ElementType<Scalars, 'String'>,
    designation: $ElementType<Scalars, 'String'>,
    companyNumber: $ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    isActive: $ElementType<Scalars, 'Boolean'>,
    businessemployeeSet: BusinessEmployeeNodeConnection,
    businessSupplier: BusinessSupplierNodeConnection,
  |},
|};

export type SocialInsuranceNodeBusinessemployeeSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type SocialInsuranceNodeBusinessSupplierArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business_Id?: ?$ElementType<Scalars, 'UUID'>,
  displayName_Iexact?: ?$ElementType<Scalars, 'String'>,
  legalName_Iexact?: ?$ElementType<Scalars, 'String'>,
  bankAccount_Iexact?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  isUseDirectDebit?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type SocialInsuranceNodeConnection = {|
  __typename?: 'SocialInsuranceNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?SocialInsuranceNodeEdge>,
|};

export type SocialInsuranceNodeEdge = {|
  __typename?: 'SocialInsuranceNodeEdge',
  node?: ?SocialInsuranceNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type StatusProgressItemNode = {|
  ...Node,
  ...{|
    __typename?: 'StatusProgressItemNode',
    id: $ElementType<Scalars, 'ID'>,
    status?: ?$ElementType<Scalars, 'String'>,
    entity?: ?EntityNode,
    data?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type Subscription = {|
  __typename?: 'Subscription',
  documentUpdatedSubscription?: ?DocumentUpdatedSubscription,
  paymentUpdatedSubscription?: ?PaymentUpdatedSubscription,
|};

export type SubscriptionDocumentUpdatedSubscriptionArgs = {|
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type SubscriptionPaymentUpdatedSubscriptionArgs = {|
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export const SubscriptionPolingTypeEnumValues = Object.freeze({
  Document: 'document',
  Payment: 'payment',
});

export type SubscriptionPolingTypeEnum = $Values<typeof SubscriptionPolingTypeEnumValues>;

export type SupplierNode = {|
  ...Node,
  ...{|
    __typename?: 'SupplierNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    isOwnerAsSupplier: $ElementType<Scalars, 'Boolean'>,
    legalName: $ElementType<Scalars, 'String'>,
    isAutoGeneratedLegalName: $ElementType<Scalars, 'Boolean'>,
    isLegalNameEditedByTheUser: $ElementType<Scalars, 'Boolean'>,
    vatNumbers: Array<$ElementType<Scalars, 'String'>>,
    taxNumbers: Array<$ElementType<Scalars, 'String'>>,
    ibans: Array<$ElementType<Scalars, 'String'>>,
    supplierAccount?: ?$ElementType<Scalars, 'Int'>,
    documents: DocumentNodeConnection,
    payments: PaymentNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    supplierAccountCaption?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type SupplierNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
|};

export type SupplierNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type SupplierNodeConnection = {|
  __typename?: 'SupplierNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?SupplierNodeEdge>,
|};

export type SupplierNodeEdge = {|
  __typename?: 'SupplierNodeEdge',
  node?: ?SupplierNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type SupplierOfferedGoodsPerMonthNode = {|
  __typename?: 'SupplierOfferedGoodsPerMonthNode',
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  supplierName?: ?$ElementType<Scalars, 'String'>,
  offeredGoodsCategory?: ?$ElementType<Scalars, 'String'>,
  amountPerMonth?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
|};

export type TaskActionNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskActionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    actionName?: ?$ElementType<Scalars, 'String'>,
    metadata: $ElementType<Scalars, 'JSONString'>,
    sourceEntityId?: ?$ElementType<Scalars, 'UUID'>,
    ignoreWarning: $ElementType<Scalars, 'Boolean'>,
    executionDurationSeconds?: ?$ElementType<Scalars, 'Float'>,
    business: BusinessNode,
    issue?: ?IssueNode,
    executingUsername?: ?$ElementType<Scalars, 'String'>,
    comment?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TaskActionNodeConnection = {|
  __typename?: 'TaskActionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskActionNodeEdge>,
|};

export type TaskActionNodeEdge = {|
  __typename?: 'TaskActionNodeEdge',
  node?: ?TaskActionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskManagerCertificationConnection = {|
  __typename?: 'TaskManagerCertificationConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskManagerCertificationEdge>,
|};

export type TaskManagerCertificationEdge = {|
  __typename?: 'TaskManagerCertificationEdge',
  node?: ?TaskManagerCertificationNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskManagerCertificationNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskManagerCertificationNode',
    id: $ElementType<Scalars, 'ID'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    name?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TaskManagerModuleConnection = {|
  __typename?: 'TaskManagerModuleConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskManagerModuleEdge>,
|};

export type TaskManagerModuleEdge = {|
  __typename?: 'TaskManagerModuleEdge',
  node?: ?TaskManagerModuleNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskManagerModuleNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskManagerModuleNode',
    id: $ElementType<Scalars, 'ID'>,
    name?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TaskManagerRenderedTaskNoteConnection = {|
  __typename?: 'TaskManagerRenderedTaskNoteConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskManagerRenderedTaskNoteEdge>,
|};

export type TaskManagerRenderedTaskNoteEdge = {|
  __typename?: 'TaskManagerRenderedTaskNoteEdge',
  node?: ?TaskManagerRenderedTaskNoteNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskManagerRenderedTaskNoteNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskManagerRenderedTaskNoteNode',
    id: $ElementType<Scalars, 'ID'>,
    taskNoteId: $ElementType<Scalars, 'ID'>,
    title?: ?$ElementType<Scalars, 'String'>,
    content?: ?$ElementType<Scalars, 'String'>,
    editable?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type TaskManagerTaskActionConnection = {|
  __typename?: 'TaskManagerTaskActionConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskManagerTaskActionEdge>,
|};

export type TaskManagerTaskActionEdge = {|
  __typename?: 'TaskManagerTaskActionEdge',
  node?: ?TaskManagerTaskActionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskManagerTaskActionNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskManagerTaskActionNode',
    id: $ElementType<Scalars, 'ID'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    actionName?: ?$ElementType<Scalars, 'String'>,
    task?: ?$ElementType<Scalars, 'String'>,
    originalTask?: ?$ElementType<Scalars, 'String'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    createdAt?: ?$ElementType<Scalars, 'String'>,
    comment?: ?$ElementType<Scalars, 'String'>,
    reason?: ?$ElementType<Scalars, 'String'>,
    username?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TaskManagerTaskConnection = {|
  __typename?: 'TaskManagerTaskConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskManagerTaskEdge>,
|};

export type TaskManagerTaskEdge = {|
  __typename?: 'TaskManagerTaskEdge',
  node?: ?TaskManagerTaskNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskManagerTaskNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskManagerTaskNode',
    id: $ElementType<Scalars, 'ID'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    taskType?: ?$ElementType<Scalars, 'String'>,
    externalId?: ?$ElementType<Scalars, 'String'>,
    businessId?: ?$ElementType<Scalars, 'String'>,
    dueDate?: ?$ElementType<Scalars, 'String'>,
    isEscalated?: ?$ElementType<Scalars, 'Boolean'>,
    isOpen?: ?$ElementType<Scalars, 'Boolean'>,
    createdAt?: ?$ElementType<Scalars, 'String'>,
    closedAt?: ?$ElementType<Scalars, 'String'>,
    taskDefinition?: ?$ElementType<Scalars, 'JSONString'>,
    renderedTaskNotes?: ?Array<?TaskManagerRenderedTaskNoteNode>,
    workflowActions?: ?Array<?WorkflowActionNode>,
    taskActions?: ?TaskManagerTaskActionConnection,
    documentWorkflowTaskActions?: ?TaskManagerTaskActionConnection,
    sourceEntityActions?: ?TaskActionNodeConnection,
    issueActions?: ?TaskActionNodeConnection,
    originalTaskId?: ?$ElementType<Scalars, 'String'>,
    priority?: ?$ElementType<Scalars, 'Int'>,
    events?: ?EventNodeConnection,
    business?: ?BusinessNode,
    issue?: ?IssueNode,
    document?: ?DocumentNode,
    transaction?: ?TransactionNode,
    autoCompleted?: ?$ElementType<Scalars, 'Boolean'>,
    isInTestingMode?: ?$ElementType<Scalars, 'Boolean'>,
    supportClientTaskPrefetch?: ?$ElementType<Scalars, 'Boolean'>,
    supportClientAsyncTaskActions?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type TaskManagerTaskNodeTaskActionsArgs = {|
  actionNames?: ?Array<?$ElementType<Scalars, 'String'>>,
  reasons?: ?Array<?$ElementType<Scalars, 'String'>>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaskManagerTaskNodeDocumentWorkflowTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaskManagerTaskNodeSourceEntityActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type TaskManagerTaskNodeIssueActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type TaskManagerTaskNodeEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_Iexact?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_In?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  closedAt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_In?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  relevantFrameStartTime?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_In?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lte?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasMerchantPendingIssues?: ?$ElementType<Scalars, 'Boolean'>,
  getFirstEvent?: ?$ElementType<Scalars, 'Boolean'>,
  alreadyStarted?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type TaskManagerTaskNoteNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskManagerTaskNoteNode',
    id: $ElementType<Scalars, 'ID'>,
    rule?: ?$ElementType<Scalars, 'String'>,
    title?: ?$ElementType<Scalars, 'String'>,
    content?: ?$ElementType<Scalars, 'String'>,
    module?: ?$ElementType<Scalars, 'String'>,
    renderForTaskIds?: ?TaskManagerRenderedTaskNoteConnection,
  |},
|};

export type TaskManagerTaskNoteNodeRenderForTaskIdsArgs = {|
  taskIds?: ?Array<?$ElementType<Scalars, 'String'>>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaskType = {|
  __typename?: 'TaskType',
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type TaxAdviserNode = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdviserNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    legalName: $ElementType<Scalars, 'String'>,
    address?: ?AddressNode,
    contactPhoneNumber: $ElementType<Scalars, 'String'>,
    contactEmail: $ElementType<Scalars, 'String'>,
    datevName?: ?$ElementType<Scalars, 'String'>,
    datevNumber?: ?$ElementType<Scalars, 'Int'>,
    businessGroups: TaxAdvisorBusinessGroupNodeConnection,
    businessesAccountings: BusinessAccountingNodeConnection,
    bookingaccountcaptionSet: BookingAccountCaptionNodeConnection,
    todoListItems?: ?TodoListItemNodeConnection,
  |},
|};

export type TaxAdviserNodeBusinessGroupsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  taxAdvisorId?: ?$ElementType<Scalars, 'ID'>,
|};

export type TaxAdviserNodeBusinessesAccountingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdviserNodeBookingaccountcaptionSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdviserNodeTodoListItemsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  date_Year?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Month?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  date_Lt?: ?$ElementType<Scalars, 'Date'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gt?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isBlocker?: ?$ElementType<Scalars, 'Boolean'>,
  type?: ?$ElementType<Scalars, 'String'>,
  type_In?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  status_In?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName?: ?$ElementType<Scalars, 'String'>,
  sourceEntityName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type TaxAdviserNodeConnection = {|
  __typename?: 'TaxAdviserNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TaxAdviserNodeEdge>,
|};

export type TaxAdviserNodeEdge = {|
  __typename?: 'TaxAdviserNodeEdge',
  node?: ?TaxAdviserNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaxAdvisorBookkeepingOverview = {|
  __typename?: 'TaxAdvisorBookkeepingOverview',
  event?: ?EventNode,
  bookkeepingStatus?: ?$ElementType<Scalars, 'String'>,
|};

export const TaxAdvisorBusinessGroupGroupTypeValues = Object.freeze({
  AllBusinesses: 'ALL_BUSINESSES',
  BusinessIndustry: 'BUSINESS_INDUSTRY',
  BusinessList: 'BUSINESS_LIST',
});

export type TaxAdvisorBusinessGroupGroupType = $Values<typeof TaxAdvisorBusinessGroupGroupTypeValues>;

export const TaxAdvisorBusinessGroupIndustryValues = Object.freeze({
  AgencyOrConsulting: 'AGENCY_OR_CONSULTING',
  Agriculture: 'AGRICULTURE',
  CraftsmanOrConstruction: 'CRAFTSMAN_OR_CONSTRUCTION',
  DoctorsSurgeryOrMedicalServices: 'DOCTORS_SURGERY_OR_MEDICAL_SERVICES',
  Education: 'EDUCATION',
  Freelancer: 'FREELANCER',
  Gastronomy: 'GASTRONOMY',
  HairdresserOrCosmeticOrWellness: 'HAIRDRESSER_OR_COSMETIC_OR_WELLNESS',
  HotelIndustry: 'HOTEL_INDUSTRY',
  Manufacturing: 'MANUFACTURING',
  OtherServices: 'OTHER_SERVICES',
  PublicServiceOrAssociation: 'PUBLIC_SERVICE_OR_ASSOCIATION',
  RealEstateOrBuildingServices: 'REAL_ESTATE_OR_BUILDING_SERVICES',
  Retail: 'RETAIL',
  TransportOrLogisticOrCar: 'TRANSPORT_OR_LOGISTIC_OR_CAR',
  Unknown: 'UNKNOWN',
});

export type TaxAdvisorBusinessGroupIndustry = $Values<typeof TaxAdvisorBusinessGroupIndustryValues>;

export type TaxAdvisorBusinessGroupNode = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdvisorBusinessGroupNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    taxAdvisor: TaxAdviserNode,
    name?: ?$ElementType<Scalars, 'String'>,
    groupType: TaxAdvisorBusinessGroupGroupType,
    industry: TaxAdvisorBusinessGroupIndustry,
    businesses: BusinessNodeConnection,
    bookingRules: BookingRuleNodeConnection,
  |},
|};

export type TaxAdvisorBusinessGroupNodeBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdvisorBusinessGroupNodeBookingRulesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdvisorBusinessGroupNodeConnection = {|
  __typename?: 'TaxAdvisorBusinessGroupNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TaxAdvisorBusinessGroupNodeEdge>,
|};

export type TaxAdvisorBusinessGroupNodeEdge = {|
  __typename?: 'TaxAdvisorBusinessGroupNodeEdge',
  node?: ?TaxAdvisorBusinessGroupNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const TodoListItemAmountCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type TodoListItemAmountCurrency = $Values<typeof TodoListItemAmountCurrencyValues>;

export type TodoListItemNode = {|
  ...Node,
  ...{|
    __typename?: 'TodoListItemNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    account: $ElementType<Scalars, 'Int'>,
    offsetingAccount: $ElementType<Scalars, 'Int'>,
    amountCurrency: TodoListItemAmountCurrency,
    amount: $ElementType<Scalars, 'Float'>,
    bookkeepingType?: ?$ElementType<Scalars, 'String'>,
    date: $ElementType<Scalars, 'Date'>,
    bookingText?: ?$ElementType<Scalars, 'String'>,
    reference: $ElementType<Scalars, 'String'>,
    business: BusinessNode,
    taxAdviser: TaxAdviserNode,
    isBlocker: $ElementType<Scalars, 'Boolean'>,
    type: TodoListItemType,
    status: TodoListItemStatus,
    sourceEntityName: TodoListItemSourceEntityName,
    sourceFiles: Array<$ElementType<Scalars, 'String'>>,
    documents?: ?DocumentNodeConnection,
    events?: ?EventNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
    chatMessages?: ?ChatMessageNodeConnection,
    isUnclearAccountTodoItem?: ?$ElementType<Scalars, 'Boolean'>,
    isMarkedAsPrivateExpense?: ?$ElementType<Scalars, 'Boolean'>,
    privateExpenseOwner?: ?BusinessEmployeeNode,
    replacementReceipt?: ?DocumentNode,
    accountType?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TodoListItemNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_In?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  entranceDate_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  processingStatus?: ?$ElementType<Scalars, 'String'>,
  processingStatus_In?: ?$ElementType<Scalars, 'String'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
  inputChannel_In?: ?$ElementType<Scalars, 'String'>,
  records_Payables_Direction?: ?$ElementType<Scalars, 'String'>,
  records_OccurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  records_OccurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  searchElasticsearch?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  unextracted?: ?$ElementType<Scalars, 'Boolean'>,
  unprocessed?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  transactionTaskActionName?: ?$ElementType<Scalars, 'String'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  correctionNeeded?: ?$ElementType<Scalars, 'Boolean'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  inputChannelByNames?: ?$ElementType<Scalars, 'String'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  issueDateFrom?: ?$ElementType<Scalars, 'String'>,
  issueDateTo?: ?$ElementType<Scalars, 'String'>,
  prefetchPayableCounterParty?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type TodoListItemNodeEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_Iexact?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  occurrenceDate?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_In?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  occurrenceDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  closedAt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_In?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  closedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  relevantFrameStartTime?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_In?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Lte?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gt?: ?$ElementType<Scalars, 'Date'>,
  relevantFrameStartTime_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasMerchantPendingIssues?: ?$ElementType<Scalars, 'Boolean'>,
  getFirstEvent?: ?$ElementType<Scalars, 'Boolean'>,
  alreadyStarted?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type TodoListItemNodeChatMessagesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isRead?: ?$ElementType<Scalars, 'Boolean'>,
  senderType?: ?$ElementType<Scalars, 'String'>,
  senderType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isMerchantMessage?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type TodoListItemNodeConnection = {|
  __typename?: 'TodoListItemNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TodoListItemNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type TodoListItemNodeEdge = {|
  __typename?: 'TodoListItemNodeEdge',
  node?: ?TodoListItemNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const TodoListItemSourceEntityNameValues = Object.freeze({
  IncomingPayable: 'INCOMING_PAYABLE',
  IncomingPayment: 'INCOMING_PAYMENT',
  OutgoingPayable: 'OUTGOING_PAYABLE',
  OutgoingPayment: 'OUTGOING_PAYMENT',
  Unknown: 'UNKNOWN',
});

export type TodoListItemSourceEntityName = $Values<typeof TodoListItemSourceEntityNameValues>;

export const TodoListItemStatusValues = Object.freeze({
  MerchantSolved: 'MERCHANT_SOLVED',
  Pending: 'PENDING',
  Published: 'PUBLISHED',
  Solved: 'SOLVED',
});

export type TodoListItemStatus = $Values<typeof TodoListItemStatusValues>;

export const TodoListItemTypeValues = Object.freeze({
  MissingDocument: 'MISSING_DOCUMENT',
  MissingInfo: 'MISSING_INFO',
  MissingPayment: 'MISSING_PAYMENT',
  NonCompliant: 'NON_COMPLIANT',
  Other: 'OTHER',
});

export type TodoListItemType = $Values<typeof TodoListItemTypeValues>;

export const TodoListSourceFileEnumValues = Object.freeze({
  Opos: 'opos',
  UnclearAccount: 'unclear_account',
});

export type TodoListSourceFileEnum = $Values<typeof TodoListSourceFileEnumValues>;

export type TransactionJourneyEventConnection = {|
  __typename?: 'TransactionJourneyEventConnection',
  pageInfo: PageInfo,
  edges: Array<?TransactionJourneyEventEdge>,
|};

export type TransactionJourneyEventEdge = {|
  __typename?: 'TransactionJourneyEventEdge',
  node?: ?TransactionJourneyEventNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TransactionJourneyEventNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionJourneyEventNode',
    id: $ElementType<Scalars, 'ID'>,
    eventType?: ?$ElementType<Scalars, 'String'>,
    date?: ?$ElementType<Scalars, 'String'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    documents?: ?DocumentConnection,
    employee?: ?BusinessEmployeeNode,
  |},
|};

export type TransactionJourneyEventNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionJourneyNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionJourneyNode',
    id: $ElementType<Scalars, 'ID'>,
    processingState?: ?$ElementType<Scalars, 'String'>,
    events?: ?TransactionJourneyEventConnection,
    actionsGraph?: ?ActionsGraphNode,
    transaction?: ?TransactionNode,
    payment?: ?PaymentNode,
  |},
|};

export type TransactionJourneyNodeEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionLinkingDataConnection = {|
  __typename?: 'TransactionLinkingDataConnection',
  pageInfo: PageInfo,
  edges: Array<?TransactionLinkingDataEdge>,
|};

export type TransactionLinkingDataEdge = {|
  __typename?: 'TransactionLinkingDataEdge',
  node?: ?TransactionLinkingDataNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TransactionLinkingDataNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionLinkingDataNode',
    id: $ElementType<Scalars, 'ID'>,
    dataType?: ?$ElementType<Scalars, 'String'>,
    amount?: ?$ElementType<Scalars, 'Float'>,
    reference?: ?$ElementType<Scalars, 'String'>,
    businessTransaction?: ?TransactionNode,
    status?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TransactionNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    counterParty?: ?CounterPartyNode,
    business: BusinessNode,
    payableOrigin?: ?$ElementType<Scalars, 'String'>,
    direction?: ?BusinessTransactionDirection,
    filteringData: $ElementType<Scalars, 'JSONString'>,
    merchantAttachedDocuments: DocumentNodeConnection,
    payments: PaymentNodeConnection,
    paymentsToTransaction: PaymentToTransactionNodeConnection,
    allPayables: PayableNodeConnection,
    expenseStatement?: ?ExpenseStatementNode,
    uid?: ?$ElementType<Scalars, 'String'>,
    transactionRelatedEntities?: ?RelatedEntityConnection,
    transactionSummary?: ?TransactionSummaryNode,
    transactionPaymentInfo?: ?TransactionPaymentInfoNode,
    issues?: ?IssueNodeConnection,
    taskActions?: ?TaskActionNodeConnection,
    relatedRecords?: ?RecordConnection,
    paymentStatus?: ?$ElementType<Scalars, 'String'>,
    userBookingHint?: ?$ElementType<Scalars, 'String'>,
    hasNoOpenReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
    hasBookingsOfTipOrDiscountSolver?: ?$ElementType<Scalars, 'Boolean'>,
    isAllRecordsInTransactionVerified?: ?$ElementType<Scalars, 'Boolean'>,
    notes?: ?EntityNoteNodeConnection,
    chatMessages?: ?ChatMessageNodeConnection,
    payables?: ?PayableNodeConnection,
    replacementReceiptScheme?: ?$ElementType<Scalars, 'JSONString'>,
    mainPayable?: ?PayableNode,
    privateExpenseOwner?: ?BusinessEmployeeNode,
    explanationDetail?: ?$ElementType<Scalars, 'String'>,
    explanationLabels?: ?Array<?$ElementType<Scalars, 'String'>>,
    isExplained?: ?$ElementType<Scalars, 'Boolean'>,
    replacementReceipt?: ?DocumentNode,
    canExplainByReplacementReceipt?: ?$ElementType<Scalars, 'Boolean'>,
    attachedDocuments?: ?Array<?DocumentAttachmentNode>,
  |},
|};

export type TransactionNodeMerchantAttachedDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  entranceDate?: ?$ElementType<Scalars, 'DateTime'>,
  inputChannel?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodePaymentsToTransactionArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeAllPayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeTransactionRelatedEntitiesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  missingRecurrentDocument_DocumentType?: ?$ElementType<Scalars, 'String'>,
  missingRecurrentDocument_DocumentType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  hasBookingReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type TransactionNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodeRelatedRecordsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodeChatMessagesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isRead?: ?$ElementType<Scalars, 'Boolean'>,
  senderType?: ?$ElementType<Scalars, 'String'>,
  senderType_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isMerchantMessage?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeConnection = {|
  __typename?: 'TransactionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TransactionNodeEdge>,
|};

export type TransactionNodeEdge = {|
  __typename?: 'TransactionNodeEdge',
  node?: ?TransactionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TransactionPaymentInfoNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionPaymentInfoNode',
    id: $ElementType<Scalars, 'ID'>,
    earliestDueDate?: ?$ElementType<Scalars, 'String'>,
    estimatedDueDate?: ?$ElementType<Scalars, 'String'>,
    latestDueDate?: ?$ElementType<Scalars, 'String'>,
    payeeBankAccount?: ?$ElementType<Scalars, 'String'>,
    paymentReference?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TransactionSummaryNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionSummaryNode',
    id: $ElementType<Scalars, 'ID'>,
    mainIdentifier?: ?$ElementType<Scalars, 'String'>,
    date?: ?$ElementType<Scalars, 'String'>,
    originalInvoiceAmount?: ?$ElementType<Scalars, 'Float'>,
    transactionBalance?: ?$ElementType<Scalars, 'Float'>,
    transactionBookingBalance?: ?$ElementType<Scalars, 'Float'>,
    lastSolarisPaymentReturnAmount?: ?$ElementType<Scalars, 'Float'>,
    lastPayableDate?: ?$ElementType<Scalars, 'String'>,
    lastPaymentDate?: ?$ElementType<Scalars, 'String'>,
    totalPayablesAmount?: ?$ElementType<Scalars, 'Float'>,
    totalPaymentsAmount?: ?$ElementType<Scalars, 'Float'>,
    totalSkontoAmount?: ?$ElementType<Scalars, 'Float'>,
    totalAmountPaid?: ?$ElementType<Scalars, 'Float'>,
    discountDate?: ?$ElementType<Scalars, 'String'>,
    paymentsDirection?: ?$ElementType<Scalars, 'String'>,
    discountAmount?: ?$ElementType<Scalars, 'Float'>,
    latePaymentFee?: ?$ElementType<Scalars, 'Float'>,
    isDiscountApplies?: ?$ElementType<Scalars, 'Boolean'>,
    originatingTransaction?: ?TransactionNode,
    originatingPayable?: ?PayableNode,
    hasPayableWithDirectDebitIndication?: ?$ElementType<Scalars, 'Boolean'>,
    isSuspectedAsDirectDebit?: ?$ElementType<Scalars, 'Boolean'>,
    hasMixedToBePaidInstructions?: ?$ElementType<Scalars, 'Boolean'>,
    isEligibleForPaymentInstruction?: ?$ElementType<Scalars, 'Boolean'>,
    canBeForceReconciled?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type UpdateBusinessCustomerInput = {|
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  customerNumber?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateBusinessCustomerPayload = {|
  __typename?: 'UpdateBusinessCustomerPayload',
  businessCustomer?: ?BusinessCustomerNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateBusinessPaymentProviderInput = {|
  businessPaymentProviderId: $ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  isForCashRegister?: ?$ElementType<Scalars, 'Boolean'>,
  displayNameForCashRegister?: ?$ElementType<Scalars, 'String'>,
  activeSince?: ?$ElementType<Scalars, 'Date'>,
  activeUntil?: ?$ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateBusinessPaymentProviderPayload = {|
  __typename?: 'UpdateBusinessPaymentProviderPayload',
  businessPaymentProvider?: ?BusinessPaymentProviderNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateBusinessSupplierInput = {|
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  vatRegistrationNumber?: ?$ElementType<Scalars, 'String'>,
  customerNumber?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  supplierType?: ?$ElementType<Scalars, 'String'>,
  counterPartyType?: ?$ElementType<Scalars, 'String'>,
  isUseDirectDebit?: ?$ElementType<Scalars, 'Boolean'>,
  offeredGoodsCategory?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateBusinessSupplierPayload = {|
  __typename?: 'UpdateBusinessSupplierPayload',
  businessSupplier?: ?BusinessSupplierNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateChatMessageInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  chatMessageId?: ?$ElementType<Scalars, 'ID'>,
  message?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateChatMessagePayload = {|
  __typename?: 'UpdateChatMessagePayload',
  chatMessage?: ?ChatMessageEdge,
  updatedChatMessageId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatedDocumentNode = {|
  __typename?: 'UpdatedDocumentNode',
  business?: ?BusinessNode,
  document?: ?DocumentNode,
|};

export type UpdatedEntitiesNode = {|
  __typename?: 'UpdatedEntitiesNode',
  id: $ElementType<Scalars, 'ID'>,
  lastCursor?: ?$ElementType<Scalars, 'String'>,
  entities?: ?Array<?UpdatedEntityUnion>,
|};

export type UpdatedEntityUnion = UpdatedPaymentNode | UpdatedDocumentNode;

export type UpdatedPaymentNode = {|
  __typename?: 'UpdatedPaymentNode',
  business?: ?BusinessNode,
  payment?: ?PaymentNode,
  deletedPaymentId?: ?$ElementType<Scalars, 'ID'>,
  eventPaymentAccount?: ?EventPaymentAccountNode,
|};

export type UpdateEmployeeInput = {|
  employeeId?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateEmployeePayload = {|
  __typename?: 'UpdateEmployeePayload',
  employee?: ?BusinessEmployeeNode,
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateMerchantDocumentInput = {|
  documentType?: ?$ElementType<Scalars, 'String'>,
  domain?: ?$ElementType<Scalars, 'String'>,
  docId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  extractionData?: ?$ElementType<Scalars, 'JSONString'>,
  metadata?: ?$ElementType<Scalars, 'JSONString'>,
  bookingsInfoData?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateMerchantDocumentPayload = {|
  __typename?: 'UpdateMerchantDocumentPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateMerchantPaymentAccountInput = {|
  merchantPaymentAccountId: $ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  activeSince?: ?$ElementType<Scalars, 'Date'>,
  activeUntil?: ?$ElementType<Scalars, 'Date'>,
  isCashRegisterPointOfSale?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateMerchantPaymentAccountPayload = {|
  __typename?: 'UpdateMerchantPaymentAccountPayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePayableDueDateInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  payableId: $ElementType<Scalars, 'String'>,
  dueDate: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePayableDueDatePayload = {|
  __typename?: 'UpdatePayableDueDatePayload',
  payable?: ?PayableNode,
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentBookingsInput = {|
  paymentId?: ?$ElementType<Scalars, 'String'>,
  bookings?: ?Array<?PaymentBookingsInput>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentBookingsPayload = {|
  __typename?: 'UpdatePaymentBookingsPayload',
  payment?: ?PaymentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentDetailsInput = {|
  paymentId: $ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  paymentReference?: ?$ElementType<Scalars, 'String'>,
  counterPartyName?: ?$ElementType<Scalars, 'String'>,
  counterPartyIban?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentDetailsPayload = {|
  __typename?: 'UpdatePaymentDetailsPayload',
  eventPaymentAccount?: ?EventPaymentAccountNode,
  payment?: ?PaymentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentDetailsPayloadPaymentArgs = {|
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentIdentificationRuleActiveStatusInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  paymentIdentificationRuleId: $ElementType<Scalars, 'String'>,
  isActive: $ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentIdentificationRuleActiveStatusPayload = {|
  __typename?: 'UpdatePaymentIdentificationRuleActiveStatusPayload',
  paymentIdentificationRule?: ?PaymentIdentificationRuleNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateTodoListItemsInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  todoItemIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
  updateInputs?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateTodoListItemsPayload = {|
  __typename?: 'UpdateTodoListItemsPayload',
  updatedTodoListItems?: ?Array<?TodoListItemNode>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UploadNewDocuments = {|
  __typename?: 'UploadNewDocuments',
  business?: ?BusinessNode,
|};

export const UploadReasonTypesEnumValues = Object.freeze({
  BlockerTodoExplanation: 'blocker_todo_explanation',
});

export type UploadReasonTypesEnum = $Values<typeof UploadReasonTypesEnumValues>;

export type UserTaskProgressNode = {|
  ...Node,
  ...{|
    __typename?: 'UserTaskProgressNode',
    id: $ElementType<Scalars, 'ID'>,
    doneToday?: ?$ElementType<Scalars, 'Int'>,
    remaining?: ?$ElementType<Scalars, 'Int'>,
  |},
|};

export type WorkflowActionNode = {|
  __typename?: 'WorkflowActionNode',
  reason?: ?$ElementType<Scalars, 'String'>,
  label?: ?$ElementType<Scalars, 'String'>,
  taskType?: ?$ElementType<Scalars, 'String'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
|};
