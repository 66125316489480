import _ from 'lodash';

// Same as ManualResetEvent. Wait blocks until set is called.
// For more info: https://docs.microsoft.com/en-us/dotnet/api/system.threading.manualresetevent?view=netframework-4.8
class ResetEvent {
  isSet = false;

  waitables = [];

  wait = () =>
    new Promise((resolve) => {
      if (this.isSet) {
        resolve();
      } else {
        this.waitables.push(resolve);
      }
    });

  set = () => {
    this.isSet = true;
    const { waitables } = this;
    this.waitables = [];
    _.forEach(waitables, (waitable) => waitable());
  };
}

export default ResetEvent;
