import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { format } from 'timeago.js';

import ls from 'local-storage';
import { MomentFormatEnum } from './enums';
import 'moment/locale/en-gb';
import 'moment/locale/de';

const moment = extendMoment(Moment);

export const isValidJSDate = (d) => {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
};

// moment.js behaves differently on iOS (iOS expects format to be provided to constructor, otherwise it can't
// parse it and "Invalid date" is returned)
export const momentFromString = (dateStr) =>
  dateStr ? moment(dateStr, ['YYYY-MM', 'YYYY-MM-DD', 'MMM YYYY', 'MM YYYY', 'YYYY-MM-DDThh:mm']) : moment(dateStr);

// Our backend stores times as UTC, so we need to tell moments. .local() can be used for converting hours
export const momentFromUTCString = (dateStr) =>
  dateStr ? moment.utc(dateStr, ['YYYY-MM', 'YYYY-MM-DD', 'MMM YYYY', 'MM YYYY', 'YYYY-MM-DDThh:mm']) : moment(dateStr);

export const getPreviousMonthMoment = (dateStr) => momentFromString(dateStr).subtract(1, 'month');

export const getPreviousDayDateTime = () => moment().subtract(24, 'hours').format();

export const getNextDayMomentFromMoment = (momentObj) => momentObj.clone().add(1, 'days');

export const getNextMonthMoment = (dateStr) => momentFromString(dateStr).add(1, 'month');

export const getPreviousMonth = (dateStr) => getPreviousMonthMoment(dateStr).format('YYYY-MM');

export const getNextMonth = (dateStr) => getNextMonthMoment(dateStr).format('YYYY-MM');

export const getCurrentMonth = (dateStr) => momentFromString(dateStr).format('YYYY-MM');

export const getPreviousMonthFirstDay = (dateStr) =>
  getPreviousMonthMoment(dateStr).startOf('month').format('YYYY-MM-DD');

export const getPreviousMonthLastDay = (dateStr) => getPreviousMonthMoment(dateStr).endOf('month').format('YYYY-MM-DD');

export const getMonthFirstDayMoment = (dateStr) => momentFromString(dateStr).startOf('month');

export const getMonthFirstDay = (dateStr) => getMonthFirstDayMoment(dateStr).format('YYYY-MM-DD');

export const getNextMonthFirstDay = (dateStr) => getMonthFirstDay(momentFromString(dateStr).add(1, 'month'));

export const getPastMonthsFirstDay = (dateStr, monthsInThePast) =>
  getMonthFirstDay(momentFromString(dateStr).subtract(monthsInThePast, 'month'));

export const getMonthLastDay = (dateStr) => momentFromString(dateStr).endOf('month').format('YYYY-MM-DD');

export const getYearFirstDay = (dateStr) => momentFromString(dateStr).startOf('year').format('YYYY-MM-DD');

export const getCurrentDate = () => moment().format('YYYY-MM-DD');

export const getCurrentDateTime = () => moment().format();

export const getCurrentMonthToDisplay = () => moment().format('MM YYYY');

export const getPreviousMonthToDisplay = (dateStr) => getPreviousMonthMoment(dateStr).format('MMM YYYY');

export const getNextMonthToDisplay = (dateStr) => momentFromString(dateStr).add(1, 'month').format('MMM YYYY');

export const getSameMonthPreviousYear = (dateStr) => momentFromString(dateStr).subtract(1, 'year').format('YYYY-MM-DD');

export const convertToYearMonthDayFormat = (dateStr) =>
  dateStr ? momentFromString(dateStr).format('YYYY-MM-DD') : null;

export const convertToShortDateFormat = (dateStr) => (dateStr ? momentFromString(dateStr).format('DD.MM.YYYY') : null);

export const convertToShortDateAndTimeFormat = (dateStr) =>
  dateStr ? momentFromUTCString(dateStr).local().format('DD.MM.YYYY HH:mm') : null;

export const convertToMonthYearFormat = (dateStr) => momentFromString(dateStr).format('MMM YYYY');

export const convertToMonthDayFormat = (dateStr) => momentFromString(dateStr).format('MMM DD');

export const convertToMonthFormat = (dateStr) => momentFromString(dateStr).format('MMM');

export const isCurrentOrFutureMonth = (dateStr) => momentFromString(dateStr).isSameOrAfter(moment(), 'month');

export const isFutureMonth = (dateStr) => momentFromString(dateStr).isAfter(moment(), 'month');

export const isPastMonth = (dateStr) => momentFromString(dateStr).isBefore(moment(), 'month');

export const getAllShortMonthNames = () => moment.monthsShort();

export const getCurrentYear = () => moment().year();

export const isBefore = (dateStr1, dateStr2) => momentFromString(dateStr1).isBefore(momentFromString(dateStr2));

export const isAfter = (dateStr1, dateStr2) => momentFromString(dateStr1).isAfter(momentFromString(dateStr2));

export const isSame = (dateStr1, dateStr2, granularity) =>
  momentFromString(dateStr1).isSame(momentFromString(dateStr2), granularity);

const minMaxDate = (func, dateStr1, dateStr2, formatStr) => {
  if (!dateStr1 && !dateStr2) return null;
  if (!dateStr1) return momentFromString(dateStr2).format(formatStr);
  if (!dateStr2) return momentFromString(dateStr1).format(formatStr);

  return func(momentFromString(dateStr1), momentFromString(dateStr2)).format(formatStr);
};

export const maxDate = (dateStr1, dateStr2, formatStr) => minMaxDate(moment.max, dateStr1, dateStr2, formatStr);

export const minDate = (dateStr1, dateStr2, formatStr) => minMaxDate(moment.min, dateStr1, dateStr2, formatStr);

export const getDateRange = (fromDate, untilDate, dateType) => moment.range(fromDate, untilDate).by(dateType);

export const getMonthlyDateRange = (fromDate, untilDate) => getDateRange(fromDate, untilDate, 'month');

export const getDatesArray = (fromDate, untilDate, dateType, dateFormat) => {
  const range = getDateRange(fromDate, untilDate, dateType);
  return Array.from(range).map((m) => m.format(dateFormat));
};

export const createBaseDateData = (fromDate, untilDate, dateType, dateFormat = 'YYYY-MM-DD') =>
  getDatesArray(fromDate, untilDate, dateType, dateFormat).map((date) => ({ date }));

export const getBookingExportsMonthRangeFromBusiness = (business) => business?.bookingExportsMonthRange;

export const getLastExportedMonth = (business, defaultDate) => {
  const bookingExportsMonthRange = getBookingExportsMonthRangeFromBusiness(business);

  if (!_.isEmpty(bookingExportsMonthRange) && bookingExportsMonthRange.length >= 1) return bookingExportsMonthRange[1];
  return defaultDate;
};

export const getFirstExportedMonth = (business, defaultDate) => {
  const bookingExportsMonthRange = getBookingExportsMonthRangeFromBusiness(business);

  if (!_.isEmpty(bookingExportsMonthRange) && bookingExportsMonthRange.length >= 1) {
    return getMonthFirstDay(bookingExportsMonthRange[0]);
  }
  return defaultDate;
};

export const getMaxDateFromFirstBusinessBookkeepingExport = (business, defaultDate) => {
  const firstDayOfFirstExportedMonth = getMonthFirstDay(getFirstExportedMonth(business, defaultDate));
  return isAfter(firstDayOfFirstExportedMonth, defaultDate) ? firstDayOfFirstExportedMonth : defaultDate;
};

export const getMaxDateFromBusinessBookkeepingExports = (business, defaultDate) => {
  if (!business || !business.businessAccounting) return defaultDate;
  const { accountingOnboardingDate } = business.businessAccounting;
  const lastDayOfLastExport = getMonthLastDay(getLastExportedMonth(business, defaultDate));

  return isAfter(accountingOnboardingDate, lastDayOfLastExport) ? accountingOnboardingDate : lastDayOfLastExport;
};

export const getMinDateFromBusinessAccountingOnboardingDate = (business, defaultDate) => {
  if (!business || !business.businessAccounting) return defaultDate;
  const { accountingOnboardingDate } = business.businessAccounting;

  return isAfter(accountingOnboardingDate, defaultDate) ? getMonthFirstDay(accountingOnboardingDate) : defaultDate;
};

export const formatMomentToYearMonthDayString = (momentDate) => momentDate.format('YYYY-MM-DD');

export const dateStrToHumanYearMonthString = (dateStr) =>
  dateStr ? momentFromString(dateStr).format('MMMM YYYY') : null;

export const dateStrToHumanDayMonthString = (dateStr) => (dateStr ? momentFromString(dateStr).format('DD.MM') : null);

export const dateStrToHumanFullMonthString = (dateStr) => (dateStr ? momentFromString(dateStr).format('MMMM') : null);

export const dateStrToHumanMonthAndYearString = (dateStr) =>
  dateStr ? momentFromString(dateStr).format('MMM YYYY') : null;

export const daysBetween = (dateStr1, dateStr2) => momentFromString(dateStr1).diff(momentFromString(dateStr2), 'days');

//  We can now just use dateStrToHumanFullMonthString
export const getTranslatedMonthString = (momentDate) => {
  const selectedLanguage = ls.get('dash_lan') ? ls.get('dash_lan') : 'de';
  return momentDate.locale(selectedLanguage).format('MMMM');
};

export const getTranslatedDateString = (momentDate) => {
  const selectedLanguage = ls.get('dash_lan') ? ls.get('dash_lan') : 'de';
  return momentDate.locale(selectedLanguage).format('LL');
};

export const formatMomentToDayMonthYearWithUnderscore = (momentDate) =>
  momentDate.format(MomentFormatEnum.day_month_year_underscore);

export const formatMomentToYearMonthWithUnderscore = (momentDate) =>
  momentDate.format(MomentFormatEnum.year_month_underscore);

export const formatMomentToDayMonthYearWithDots = (momentDate) =>
  momentDate.format(MomentFormatEnum.day_month_year_dots);

export const isFromFutureByDay = (dateStr, day) => momentFromString(dateStr).set('date', day).isAfter();

export const getDay = (dateStr) => momentFromString(dateStr).get('date');

export const getDaysInMonth = (dateStr) => momentFromString(dateStr).daysInMonth();

export const getFirstDayOfMonth = (dateStr) => momentFromString(dateStr).set('date', 1).day();

export const isSameMonth = (date1, date2) => moment(date1).isSame(date2, 'month');

export function localTimeAgo(dateTime) {
  const selectedLanguage = ls.get('dash_lan') ? ls.get('dash_lan') : 'de';

  return format(dateTime, selectedLanguage);
}
