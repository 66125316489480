export const ExperienceGroupEnum = {
  factory_experience: 'factory_experience',
  ned_experience: 'ned_experience',
};

export const CustomerSuccessEnum = {
  read_only_agent: 'true',
  full_access_agent: 'full',
};

export const UserTypeEnum = {
  ops: 'Ops',
  tan: 'Tan',
  user: 'User',
};

export const COMMON_STATE = {
  EMPTY: 'EMPTY',
  LOCKED: 'LOCKED',
  DEFAULT: 'DEFAULT',
};

export const MerchantTimelineItemTypeEnum = {
  subscription_start: 'SUBSCRIPTION_START',
  start_accounting_month: 'START_ACCOUNTING_MONTH',
  master_data_handover: 'MASTER_DATA_HANDOVER',
  end_accounting_month: 'END_ACCOUNTING_MONTH',
  document_submit_deadline: 'DOCUMENT_SUBMIT_DEADLINE',
  postponed_document_submit_deadline: 'POSTPONED_DOCUMENT_SUBMIT_DEADLINE',
  urgent_todo_deadline: 'URGENT_TODO_DEADLINE',
  vat_payment: 'VAT_PAYMENT',
};

export const PaymentAccountTypeEnum = {
  bank_account: 'bank_account',
  credit_card: 'credit_card',
  payment_provider: 'payment_provider',
  cash_register: 'cash_register',
};

export const DocumentPackageItemTypeEnum = {
  z_reports: 'Z_REPORTS',
  incoming_payables: 'INCOMING_PAYABLES',
  outgoing_payables: 'OUTGOING_PAYABLES',
  other_documents: 'OTHER_DOCUMENTS',
};

export const MomentFormatEnum = {
  day_month_year_underscore: 'DD_MM_YYYY',
  day_month_year_dots: 'DD.MM.YYYY',
  year_month_underscore: 'YYYY_MM',
};

export const DocumentInputChannelNames = {
  email: 'EMAIL',
  box: 'BOX',
  app: 'APP',
  web: 'WEB',
  zeitgold: 'ZEITGOLD',
};

export const VatDeclarationPeriodsEnum = {
  yearly: 'YEARLY',
  quarterly: 'QUARTERLY',
  monthly: 'MONTHLY',
};

export const DirectionEnum = {
  incoming: 'incoming',
  outgoing: 'outgoing',
};
