import React from 'react';
import PropTypes from 'prop-types';

import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../relay/createRelayEnvironment';
import { handleError } from '../utils/errors';
import BusinessRoot from './BusinessRoot';

function ExperimentsRootQuery({ children, businessId }) {
  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query ExperimentsRootQuery($id: ID!) {
          business(id: $id) {
            id
            activeExperiments
          }
        }
      `}
      variables={{
        id: businessId,
      }}
      render={({ error, props }) => {
        if (error) {
          handleError(error, { publish: true });
        }
        return <BusinessRoot props={props || {}}>{children}</BusinessRoot>;
      }}
    />
  );
}

ExperimentsRootQuery.defaultProps = {
  business: null,
  businessId: '',
};

ExperimentsRootQuery.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.array]).isRequired,
  business: PropTypes.shape({
    __fragments: PropTypes.object,
  }),
  businessId: PropTypes.string,
};

const Container = ({ children, businessId }) => (
  <ExperimentsRootQuery businessId={businessId}>{children}</ExperimentsRootQuery>
);

Container.defaultProps = {
  businessId: '',
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.array]).isRequired,
  businessId: PropTypes.string,
};

export default Container;
